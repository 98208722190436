import React, { useEffect, useState } from "react";
import HeaderSettings from "../Layouts/HeaderSettings";
import { useTranslation } from "react-i18next";
import Documents from "../Components/Documents";
import Spinner from "../../../components/Spinner";
import "../Style/AccountDetails.css";
import Swal from "sweetalert2";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import errorExpiredSession from "../../../components/Alerts/errorExpiredSession";
import ErrorSwal from "../../../components/Alerts/errorAlert";
import DatePickerProfile from "../../../components/reusables/DatePickerProfile";
import { initialDateFormat } from "../../../constants/Utils";
import SpinnerSecondary from "../../../components/SpinnerSecondary";
import Countries from "../../../Data/Countries.json";
import InternalErrorSwal from "../../../components/Alerts/internalErrorAlert";
import { successSwall } from "../../../components/Alerts/successAlert";
import SpinnerDiv from "../../../Layouts/SpinnerDiv";

const AccountDetails = ({ userType, handleType }) => {
  const { t } = useTranslation();

  const [docType, setDocType] = useState(0);

  useEffect(() => {
    localStorage.setItem("active", 10);
    document.dispatchEvent(new CustomEvent("activePage"));
  }, []);

  const activities = [
    { id: 1, name: t("activities.ads") },
    { id: 2, name: t("activities.online_marketplace") },
    { id: 3, name: t("activities.telecom") },
    { id: 4, name: t("activities.call_center") },
    { id: 5, name: t("activities.transport_and_warehousing") },
    { id: 6, name: t("activities.education") },
    { id: 7, name: t("activities.construction") },
    { id: 8, name: t("activities.agriculture") },
    { id: 9, name: t("activities.real_estate") },
    { id: 10, name: t("activities.admin") },
    { id: 11, name: t("activities.business_acts") },
    { id: 12, name: t("activities.food") },
    { id: 13, name: t("activities.health_care") },
    { id: 14, name: t("activities.information") },
    { id: 15, name: t("activities.insurance") },
    { id: 16, name: t("activities.media_industry") },
    { id: 17, name: t("activities.trade") },
    { id: 18, name: t("activities.technology") },
    { id: 19, name: t("activities.travel") },
    { id: 20, name: t("activities.utilities") },
    { id: 21, name: t("activities.manufacturing") },
    { id: 22, name: t("activities.consulting") },
  ].sort();

  const roles = [
    { id: 1, name: t("director") },
    { id: 2, name: t("hr_manager") },
    { id: 3, name: t("manager") },
    { id: 4, name: t("ceo") },
    { id: 5, name: t("cod") },
    { id: 6, name: t("cfo") },
    { id: 7, name: t("cmo") },
    { id: 8, name: t("cto") },
    { id: 9, name: t("sales_manager") },
    { id: 10, name: t("csr") },
    { id: 11, name: t("accountant") },
    { id: 12, name: t("business_analyst") },
    { id: 13, name: t("others") },
  ];

  const [profileReqBody, setProfileReqBody] = useState({
    customer: {
      id: null,
      reference: null,
      module_id: null,
      class: null,
      language: null,
      currency: null,
      origin: null,
      parent_id: null,
      status: null,
      date_creation: null,
      date_starting: null,
      date_cessation: null,
    },
    company: {
      id: null,
      reference: null,
      name: null,
      legal_type: null,
      registered_number: null,
      vat_number: null,
      logo: null,
      director: null,
      industry: null,
      status: null,
    },
    person: {
      id: null,
      reference: null,
      gender: null,
      lastname: null,
      firstname: null,
      birthdate: null,
      job: null,
      nationality: null,
      identity_card_number: null,
      passport_number: null,
      passport_number_validity: null,
      photo: null,
      status: null,
      email_billing: null,
      identity_card_number: null,
    },
    contact_data: [
      {
        id: null,
        type: 1,
        value: "",
        status: null,
      },
      {
        id: null,
        type: 14,
        value: "",
        status: null,
      },
      {
        id: null,
        type: 2,
        value: "",
        status: null,
      },
      {
        id: null,
        type: 4,
        value: "",
        status: null,
      },
      {
        id: null,
        type: 3,
        value: "",
        status: null,
      },
    ],
  });

  const [loading, setLoading] = useState({
    details: true,
    business: true,
    address: true,
    docs: true,
  });

  const [reconnection, setReconnection] = useState(true);

  const reconnectionSwal = () => {
    let timerInterval;

    Swal.fire({
      title: "Trying to reconnect!",
      html: "Next try in <b>0%</b>.",
      timer: 1000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        const timer = Swal.getPopup().querySelector("b");
        timerInterval = setInterval(() => {
          const percentage = (
            ((1000 - Swal.getTimerLeft()) / 1000) *
            100
          ).toFixed(0);
          timer.textContent = `${percentage}%`;
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        setError(false);
        if (reconnection) {
          handleReconnection();
        }
      }
      setReconnection(false);
    });
  };

  // SWALLS

  useEffect(() => {
    getAccountDetails();
  }, []);

  const handleProfileInputChange = (e, objectKey) => {
    const { name, value } = e.target;

    if (name != "industry") {
      setProfileReqBody((prevProfile) => {
        return {
          ...prevProfile,
          [objectKey]: {
            ...prevProfile[objectKey],
            [name]: value,
          },
        };
      });
    } else {
      setProfileReqBody((prevProfile) => {
        return {
          ...prevProfile,
          [objectKey]: {
            ...prevProfile[objectKey],
            [name]: parseInt(value),
          },
        };
      });
    }
  };

  const handleRoleChange = (value) => {
    setProfileReqBody((prevData) => ({
      ...prevData,
      person: { ...prevData.person, job: value },
    }));
  };

  const handleAddrInputChange = (e, type, contactType) => {
    const { value } = e.target;
    setProfileReqBody((prevAddress) => ({
      ...prevAddress,
      contact_data: prevAddress.contact_data.map((contact) =>
        contact.type === type ? { ...contact, value } : contact
      ),
    }));
  };

  const handleChangeCountry = (e, type) => {
    const value = e.code;
    setProfileReqBody((prevAddress) => ({
      ...prevAddress,
      contact_data: prevAddress.contact_data.map((contact) =>
        contact.type === type ? { ...contact, value } : contact
      ),
    }));
  };

  const handleChangeActivity = (value) => {
    setProfileReqBody((prevProfile) => ({
      ...prevProfile,
      company: {
        ...prevProfile.company,
        industry: value,
      },
    }));
  };

  const [errorInput, setErrorInput] = useState({
    firstname: false,
    lastname: false,
    email: false,
  });

  const [error, setError] = useState({
    firstname: false,
    lastname: false,
    email: false,
  });

  // FETCH DATA

  const getAccountDetails = async () => {
    try {
      setLoading({ details: true, business: true, address: true, docs: true });
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/interface/customers`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setLoading({
        details: false,
        business: false,
        address: false,
        docs: false,
      });
      if (!response.ok) {
        throw new Error(response.status);
      }
      const fetchedData = await response.json();
      setProfileReqBody((prevData) => {
        const updatedContactData = [...prevData.contact_data];
        fetchedData.data.contact_data.forEach((responseItem) => {
          const existingItemIndex = updatedContactData.findIndex(
            (item) => item.type === responseItem.type
          );
          if (existingItemIndex !== -1) {
            updatedContactData[existingItemIndex] = responseItem;
          } else {
            updatedContactData.push(responseItem);
          }
        });

        return {
          ...prevData,
          customer: fetchedData.data.customer,
          company: fetchedData.data.company,
          person: fetchedData.data.person,
          contact_data: updatedContactData,
        };
      });

      localStorage.setItem("userData", JSON.stringify(fetchedData.data));
      if (fetchedData) {
        handleType(fetchedData.data.customer.class);
      }
      if (fetchedData.data.person.passport_number) {
        setDocType(0);
      } else if (fetchedData.data.person.identity_card_number) {
        setDocType(1);
      }
    } catch (error) {
      if (error.message == 401) {
        errorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else {
        reconnectionSwal();
      }
    }
  };

  const handleReconnection = async () => {
    try {
      setLoading({ details: true, business: true, address: true, docs: true });
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/interface/customers`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setLoading({
        details: false,
        business: false,
        address: false,
        docs: false,
      });
      if (!response.ok) {
        throw new Error(response.status);
      }

      const fetchedData = await response.json();

      setProfileReqBody((prevData) => {
        const updatedContactData = [...prevData.contact_data];
        fetchedData.data.contact_data.forEach((responseItem) => {
          const existingItemIndex = updatedContactData.findIndex(
            (item) => item.type === responseItem.type
          );
          if (existingItemIndex !== -1) {
            updatedContactData[existingItemIndex] = responseItem;
          } else {
            updatedContactData.push(responseItem);
          }
        });

        return {
          ...prevData,
          customer: fetchedData.data.customer,
          company: fetchedData.data.company,
          person: fetchedData.data.person,
          contact_data: updatedContactData,
        };
      });

      localStorage.setItem("userData", JSON.stringify(fetchedData.data));
      if (fetchedData) {
        handleType(fetchedData.data.customer.class);
      }
      if (fetchedData.data.person.passport_number) {
        setDocType(0);
      } else if (fetchedData.data.person.identity_card_number) {
        setDocType(1);
      }
    } catch (error) {
      InternalErrorSwal();
    }
  };

  // UPDATE DATA
  const changeName = (e, item) => {
    if (/[^a-zA-Z]/.test(e.target.value)) {
      setError((prevErrors) => ({ ...prevErrors, [item]: true }));
    } else {
      setError((prevErrors) => ({ ...prevErrors, [item]: false }));
      if (errorInput[item]) {
        setErrorInput((prevErrors) => ({ ...prevErrors, [item]: false }));
      }
    }
  };

  const changeEmail = (e) => {
    const emailPattern = /\S+@\S+\.\S+/;
    if (e.target.value.length > 0) {
      if (emailPattern.test(e.target.value)) {
        setError((prevData) => ({ ...prevData, email: false }));
        if (errorInput.email) {
          setErrorInput((prevData) => ({ ...prevData, email: false }));
        }
      } else {
        setError((prevData) => ({ ...prevData, email: true }));
      }
    }
  };

  const handleUpdateProfile = async (e) => {
    if (error.firstname == true) {
      setErrorInput((prevData) => ({ ...prevData, firstname: true }));
    }

    if (error.lastname == true) {
      setErrorInput((prevData) => ({ ...prevData, lastname: true }));
    }

    if (error.email == true) {
      setErrorInput((prevData) => ({ ...prevData, email: true }));
    }

    try {
      if (!error.firstname && !error.lastname && !error.email) {
        setLoading((prevData) => ({ ...prevData, details: true }));
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/interface/customers/update`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              customer_id: profileReqBody.customer.id,
              person: {
                id: profileReqBody.person.id,
                reference: profileReqBody.person.reference,
                gender: profileReqBody.person.gender,
                lastname: profileReqBody.person.lastname,
                firstname: profileReqBody.person.firstname,
                birthdate: profileReqBody.person.birthdate,
                job: profileReqBody.person.job,
                nationality: profileReqBody.person.nationality,
                identity_card_number:
                  profileReqBody.person.identity_card_number,
                passport_number:
                  docType == 0 ? profileReqBody.person.passport_number : "",
                passport_number_validity:
                  docType == 0
                    ? profileReqBody.person.passport_number_validity
                    : "",
                photo: profileReqBody.person.photo,
                status: profileReqBody.person.status,
                email_billing: profileReqBody.person.email_billing,
                identity_card_number:
                  docType == 1
                    ? profileReqBody.person.identity_card_number
                    : "",
              },
            }),
          }
        );
        setLoading((prevData) => ({ ...prevData, details: false }));
        if (!response.ok) {
          if (response.status === 401) {
            errorExpiredSession(
              t("session_expired"),
              t("errors.401"),
              t("login")
            );
          } else if (response.status === 403) {
            ErrorSwal(t("errors.403"));
          } else if (response.status === 404) {
            ErrorSwal(t("errors.404"));
          } else if (response.status === 405) {
            ErrorSwal(t("errors.405"));
          } else if (response.status === 422) {
            ErrorSwal(t("errors.422"));
          } else if (response.status === 423) {
            ErrorSwal(t("errors.423"));
          } else if (response.status === 406) {
            ErrorSwal(t("errors.406"));
          } else {
            InternalErrorSwal();
          }
        } else {
          const fetchedData = await response.json();

          if (fetchedData) {
            successSwall(t("profile_updated_successefully"));
            localStorage.setItem(
              "lastname",
              `${profileReqBody.person.lastname}`
            );
            localStorage.setItem(
              "firstname",
              `${profileReqBody.person.firstname}`
            );
            document.dispatchEvent(new CustomEvent("profileDetailsChange"));
            document.dispatchEvent(
              new CustomEvent("profileAccountDetailsChange")
            );
          }
        }
      }
    } catch (error) {
      InternalErrorSwal();
      console.log(error);
    }
  };

  // Update addr
  const handleUpdateAddress = async (e) => {
    e.preventDefault();

    try {
      setLoading((prevData) => ({ ...prevData, address: true }));
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/interface/customers/update`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            customer_id: profileReqBody.customer.id,
            contact_data: profileReqBody.contact_data,
          }),
        }
      );
      setLoading((prevData) => ({ ...prevData, address: false }));

      if (!response.ok) {
        if (response.status === 401) {
          errorExpiredSession(
            t("session_expired"),
            t("errors.401"),
            t("login")
          );
        } else if (response.status === 403) {
          ErrorSwal(t("errors.403"));
        } else if (response.status === 404) {
          ErrorSwal(t("errors.404"));
        } else if (response.status === 405) {
          ErrorSwal(t("errors.405"));
        } else if (response.status === 422) {
          ErrorSwal(t("errors.422"));
        } else if (response.status === 423) {
          ErrorSwal(t("errors.423"));
        } else if (response.status === 406) {
          ErrorSwal(t("errors.406"));
        } else {
          InternalErrorSwal();
        }
      } else {
        const fetchedData = await response.json();

        setUserCountry(
          profileReqBody.contact_data.find((contact) => contact.type === 5)
            .value
        );

        localStorage.setItem(
          "country",
          profileReqBody.contact_data.find((contact) => contact.type === 5)
            .value
        );

        if (fetchedData) {
          successSwall(t("address_updated_successefully"));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Update company
  const handleUpdateCompany = async (e) => {
    e.preventDefault();

    try {
      setLoading((prevData)=>({...prevData, business: true}))
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/interface/customers/update`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            customer_id: profileReqBody.customer.id,
            company: profileReqBody.company,
          }),
        }
      );
setLoading((prevData)=>({...prevData, business: false}))
      if (!response.ok) {
        if (response.status === 401) {
          errorExpiredSession(
            t("session_expired"),
            t("errors.401"),
            t("login")
          );
        } else if (response.status === 403) {
          ErrorSwal(t("errors.403"));
        } else if (response.status === 404) {
          ErrorSwal(t("errors.404"));
        } else if (response.status === 405) {
          ErrorSwal(t("errors.405"));
        } else if (response.status === 422) {
          ErrorSwal(t("errors.422"));
        } else if (response.status === 423) {
          ErrorSwal(t("errors.423"));
        } else if (response.status === 406) {
          ErrorSwal(t("errors.406"));
        } else {
          InternalErrorSwal();
        }
      } else {
        const fetchedData = await response.json();

        if (fetchedData) {
          successSwall(t("business_details_updated_successfully"));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const resetAccountDetails = (item) => {
    const data = JSON.parse(localStorage.getItem("userData"));
    setProfileReqBody((prevData) => ({ ...prevData, [item]: data[item] }));
  };

  const [menuOpen, setMenuOpen] = useState({
    countries: false,
    activities: false,
    roles: false,
    doc_type: false,
  });

  const [filterValue, setFilterValue] = useState("");

  const handleInputChange = (e) => {
    setFilterValue(e.target.value.toLowerCase());
  };

  const filteredCountries = Countries.filter((item) =>
    item.name.toLowerCase().includes(filterValue)
  );

  const [userCountry, setUserCountry] = useState(
    localStorage.getItem("country")
  );

  return (
    <div>
      <div
        className="app-wrapper flex-column flex-row-fluid"
        id="kt_app_wrapper"
      >
        <div className="app-container container-fluid d-flex flex-row flex-column-fluid">
          <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
            <HeaderSettings active="1" type={userType} />
            <div className="d-flex flex-column flex-column-fluid">
              <div
                id="kt_app_content"
                className="app-content flex-column-fluid"
              >
                <div className="card mb-5 mb-xl-10">
                  <div
                    className="card-header border-0 cursor-pointer"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_account_profile_details"
                    aria-expanded="true"
                    aria-controls="kt_account_profile_details"
                  >
                    <div className="card-title m-0 title-flex">
                      <h3 className="fw-bold m-0">{t("profile_details")}</h3>
                    </div>
                  </div>
                  <div
                    id="kt_account_settings_profile_details"
                    className="collapse show card-body p-9 "
                  >
                    {loading.details && <SpinnerDiv />}
                    <form
                      id="kt_ecommerce_settings_general_form"
                      className={`form fv-plugins-bootstrap5 fv-plugins-framework ${
                        loading.details && "disabled-event"
                      }`}
                      action="#"
                    >
                      <div className="row">
                        <div className="col-md-10 width-100">
                          <div className="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
                            <div className="col">
                              <div className="fv-row mb-6 fv-plugins-icon-container">
                                <label className="fs-6 fw-semibold form-label mt-3 pb-4">
                                  <span>{t("first_name")}</span>
                                </label>
                                <div>
                                  <input
                                    type="text"
                                    className={`form-control form-control-sm form-control-solid ${
                                      errorInput.firstname && "invalid-values"
                                    }`}
                                    name="firstname"
                                    value={profileReqBody.person.firstname}
                                    onChange={(e) => {
                                      changeName(e, "firstname");
                                      handleProfileInputChange(e, "person");
                                    }}
                                  />
                                  {errorInput.firstname ? (
                                    <svg
                                      className="svg-error"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g
                                        id="SVGRepo_bgCarrier"
                                        stroke-width="0"
                                      ></g>
                                      <g
                                        id="SVGRepo_tracerCarrier"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></g>
                                      <g id="SVGRepo_iconCarrier">
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm-1.5-5.009c0-.867.659-1.491 1.491-1.491.85 0 1.509.624 1.509 1.491 0 .867-.659 1.509-1.509 1.509-.832 0-1.491-.642-1.491-1.509zM11.172 6a.5.5 0 0 0-.499.522l.306 7a.5.5 0 0 0 .5.478h1.043a.5.5 0 0 0 .5-.478l.305-7a.5.5 0 0 0-.5-.522h-1.655z"
                                          fill="red"
                                        ></path>
                                      </g>
                                    </svg>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <div className="fv-plugins-message-container invalid-feedback" />
                              </div>
                            </div>
                            <div className="col">
                              <div className="fv-row mb-6 fv-plugins-icon-container">
                                <label className="fs-6 fw-semibold form-label mt-3 pb-4">
                                  <span>{t("last_name")}</span>
                                </label>
                                <div>
                                  <input
                                    type="text"
                                    className={`form-control form-control-sm form-control-solid ${
                                      errorInput.lastname && "invalid-values"
                                    }`}
                                    name="lastname"
                                    value={profileReqBody.person.lastname}
                                    onChange={(e) => {
                                      changeName(e, "lastname");
                                      handleProfileInputChange(e, "person");
                                    }}
                                  />{" "}
                                  {errorInput.lastname ? (
                                    <svg
                                      className="svg-error"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g
                                        id="SVGRepo_bgCarrier"
                                        stroke-width="0"
                                      ></g>
                                      <g
                                        id="SVGRepo_tracerCarrier"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></g>
                                      <g id="SVGRepo_iconCarrier">
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm-1.5-5.009c0-.867.659-1.491 1.491-1.491.85 0 1.509.624 1.509 1.491 0 .867-.659 1.509-1.509 1.509-.832 0-1.491-.642-1.491-1.509zM11.172 6a.5.5 0 0 0-.499.522l.306 7a.5.5 0 0 0 .5.478h1.043a.5.5 0 0 0 .5-.478l.305-7a.5.5 0 0 0-.5-.522h-1.655z"
                                          fill="red"
                                        ></path>
                                      </g>
                                    </svg>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="fv-plugins-message-container invalid-feedback" />
                              </div>
                            </div>
                          </div>
                          <div className="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
                            <div className="col">
                              <div className="fv-row mb-6">
                                <label className="fs-6 fw-semibold form-label mt-3 pb-4">
                                  <span> {t("role")}</span>
                                </label>
                                <div className="w-100 countries-container">
                                  <div className="fv-row height-100">
                                    <div className="row height-100">
                                      <div className="d-flex gap-2 height-100">
                                        <div
                                          className="country-menu-div position-relative"
                                          onClick={() =>
                                            setMenuOpen((prevData) => ({
                                              ...prevData,
                                              roles: !prevData.roles,
                                            }))
                                          }
                                          onMouseLeave={() =>
                                            setMenuOpen((prevData) => ({
                                              ...prevData,
                                              roles: false,
                                            }))
                                          }
                                        >
                                          <span>
                                            {
                                              roles.find(
                                                (item) =>
                                                  item.id ===
                                                  profileReqBody.person.job
                                              )?.name
                                            }
                                          </span>
                                          <svg
                                            viewBox="0 0 1024 1024"
                                            version="1.1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="#000000"
                                          >
                                            <g
                                              id="SVGRepo_bgCarrier"
                                              strokeWidth={0}
                                            />
                                            <g
                                              id="SVGRepo_tracerCarrier"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                            <g id="SVGRepo_iconCarrier">
                                              <path
                                                d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                                                fill="#5e6278"
                                              />
                                            </g>
                                          </svg>

                                          <div
                                            className={
                                              !menuOpen.roles && "display-none"
                                            }
                                          >
                                            {roles.map((item, key) => (
                                              <div
                                                key={key}
                                                onClick={() =>
                                                  handleRoleChange(item.id)
                                                }
                                              >
                                                <span> {item.name}</span>
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* <select
                                    className=" form-control-sm form-control-solid countries-dropdown"
                                    name="job"
                                    value={profileReqBody.person.job}
                                    onChange={(e) =>
                                      handleProfileInputChange(e, "person")
                                    }
                                  >
                                    <option value="0">
                                      {t("select_role")}
                                    </option>
                                    <option value="1">{t("director")}</option>
                                    <option value="2">{t("hr_manager")}</option>
                                    <option value="3">{t("manager")}</option>
                                    <option value="4">{t("ceo")}</option>
                                    <option value="5">{t("cod")}</option>
                                    <option value="6">{t("cfo")}</option>
                                    <option value="7">{t("cmo")}</option>
                                    <option value="8">{t("cto")}</option>
                                    <option value="9">
                                      {t("sales_manager")}
                                    </option>
                                    <option value="10">{t("csr")}</option>
                                    <option value="11">
                                      {t("accountant")}
                                    </option>
                                    <option value="12">
                                      {t("business_analyst")}
                                    </option>
                                    <option value="other">{t("others")}</option>
                                  </select> */}
                              </div>
                            </div>

                            <div className="col">
                              <div className="fv-row mb-6 fv-plugins-icon-container">
                                <label className="fs-6 fw-semibold form-label mt-3 pb-4">
                                  <span>{t("email")}</span>
                                </label>
                                <div>
                                  <input
                                    type="email"
                                    className={`form-control form-control-sm form-control-solid ${
                                      errorInput.email && "invalid-values"
                                    }`}
                                    name="email_billing"
                                    value={profileReqBody.person.email_billing}
                                    onChange={(e) => {
                                      changeEmail(e);
                                      handleProfileInputChange(e, "person");
                                    }}
                                    // value={
                                    //   profileReqBody.person
                                    //     .passport_number_validity
                                    //     ? new Date(
                                    //         profileReqBody.person.passport_number_validity
                                    //       )
                                    //         .toISOString()
                                    //         .split("T")[0]
                                    //     : "" // if passport_number_validity is null, set value to an empty string
                                    // }
                                    // onChange={(e) =>
                                    //   handleProfileInputChange(e, "person")
                                    // }
                                  />

                                  {errorInput.email == true ? (
                                    <svg
                                      className="svg-error"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g
                                        id="SVGRepo_bgCarrier"
                                        stroke-width="0"
                                      ></g>
                                      <g
                                        id="SVGRepo_tracerCarrier"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></g>
                                      <g id="SVGRepo_iconCarrier">
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm-1.5-5.009c0-.867.659-1.491 1.491-1.491.85 0 1.509.624 1.509 1.491 0 .867-.659 1.509-1.509 1.509-.832 0-1.491-.642-1.491-1.509zM11.172 6a.5.5 0 0 0-.499.522l.306 7a.5.5 0 0 0 .5.478h1.043a.5.5 0 0 0 .5-.478l.305-7a.5.5 0 0 0-.5-.522h-1.655z"
                                          fill="red"
                                        ></path>
                                      </g>
                                    </svg>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="fv-plugins-message-container invalid-feedback" />
                              </div>
                            </div>
                          </div>
                          {(userCountry && userCountry) == "TN" ? (
                            <div className="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
                              <div className="col">
                                <div className="fv-row mb-6">
                                  <label className="fs-6 fw-semibold form-label mt-3 pb-4">
                                    {t("id_doc_type")}
                                  </label>

                                  <div className="w-100 countries-container">
                                    <div className="fv-row height-100">
                                      <div className="row height-100">
                                        <div className="d-flex gap-2 height-100">
                                          <div
                                            className="country-menu-div position-relative"
                                            onClick={() =>
                                              setMenuOpen((prevData) => ({
                                                ...prevData,
                                                doc_type: !prevData.doc_type,
                                              }))
                                            }
                                            onMouseLeave={() =>
                                              setMenuOpen((prevData) => ({
                                                ...prevData,
                                                doc_type: false,
                                              }))
                                            }
                                          >
                                            <span>
                                              {docType === 0
                                                ? t("passport_id")
                                                : t("id_card")}
                                            </span>
                                            <svg
                                              viewBox="0 0 1024 1024"
                                              version="1.1"
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="#000000"
                                            >
                                              <g
                                                id="SVGRepo_bgCarrier"
                                                strokeWidth={0}
                                              />
                                              <g
                                                id="SVGRepo_tracerCarrier"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <g id="SVGRepo_iconCarrier">
                                                <path
                                                  d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                                                  fill="#5e6278"
                                                />
                                              </g>
                                            </svg>

                                            <div
                                              className={
                                                !menuOpen.doc_type &&
                                                "display-none"
                                              }
                                            >
                                              <div
                                                onClick={() => {
                                                  setDocType(0);
                                                }}
                                              >
                                                {t("passport_id")}
                                              </div>
                                              <div
                                                onClick={() => {
                                                  setDocType(1);
                                                }}
                                              >
                                                {t("id_card")}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={
                                  docType == 0 && "col docs-passport-flex"
                                }
                              >
                                {docType == 0 ? (
                                  <div
                                    className={`fv-row mb-6 fv-plugins-icon-container ${
                                      docType == 0 ? "width-49" : ""
                                    }`}
                                  >
                                    <label className="fs-6 fw-semibold form-label mt-3 pb-4">
                                      <span>{t("doc_id")}</span>
                                    </label>

                                    <input
                                      type="text"
                                      className={`form-control form-control-sm form-control-solid`}
                                      name={
                                        docType == 0
                                          ? "passport_number"
                                          : docType == 1
                                          ? "identity_card_number"
                                          : ""
                                      }
                                      value={
                                        docType == 0
                                          ? profileReqBody.person
                                              .passport_number
                                          : docType == 1
                                          ? profileReqBody.person
                                              .identity_card_number
                                          : ""
                                      }
                                      onChange={(e) =>
                                        handleProfileInputChange(e, "person")
                                      }
                                    />
                                  </div>
                                ) : docType == 1 ? (
                                  <div
                                    className={`fv-row mb-6 fv-plugins-icon-container ${
                                      docType == 0 ? "width-49" : ""
                                    }`}
                                  >
                                    <label className="fs-6 fw-semibold form-label mt-3 pb-4">
                                      <span>{t("doc_id")}</span>
                                    </label>

                                    <input
                                      type="text"
                                      className={`form-control form-control-sm form-control-solid`}
                                      name={
                                        docType == 0
                                          ? "passport_number"
                                          : docType == 1
                                          ? "identity_card_number"
                                          : ""
                                      }
                                      value={
                                        docType == 0
                                          ? profileReqBody.person
                                              .passport_number
                                          : docType == 1
                                          ? profileReqBody.person
                                              .identity_card_number
                                          : ""
                                      }
                                      onChange={(e) =>
                                        handleProfileInputChange(e, "person")
                                      }
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}

                                {docType == 0 && (
                                  <div className="fv-row mb-6 fv-plugins-icon-container width-49">
                                    <label className="fs-6 fw-semibold form-label mt-3 pb-4">
                                      <span>{t("pass_validity")}</span>
                                    </label>
                                    <div>
                                      <div className="position-relative">
                                        <svg
                                          className="pass-date-svg"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g
                                            id="SVGRepo_bgCarrier"
                                            stroke-width="0"
                                          ></g>
                                          <g
                                            id="SVGRepo_tracerCarrier"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          ></g>
                                          <g id="SVGRepo_iconCarrier">
                                            {" "}
                                            <path
                                              d="M20 10V7C20 5.89543 19.1046 5 18 5H6C4.89543 5 4 5.89543 4 7V10M20 10V19C20 20.1046 19.1046 21 18 21H6C4.89543 21 4 20.1046 4 19V10M20 10H4M8 3V7M16 3V7"
                                              stroke="#5e6278"
                                              stroke-width="2"
                                              stroke-linecap="round"
                                            ></path>{" "}
                                            <rect
                                              x="6"
                                              y="12"
                                              width="3"
                                              height="3"
                                              rx="0.5"
                                              fill="#5e6278"
                                            ></rect>{" "}
                                            <rect
                                              x="10.5"
                                              y="12"
                                              width="3"
                                              height="3"
                                              rx="0.5"
                                              fill="#5e6278"
                                            ></rect>{" "}
                                            <rect
                                              x="15"
                                              y="12"
                                              width="3"
                                              height="3"
                                              rx="0.5"
                                              fill="#5e6278"
                                            ></rect>{" "}
                                          </g>
                                        </svg>

                                        <input
                                          type="text"
                                          className={`form-control form-control-sm form-control-solid`}
                                          name="passport_number_validity"
                                          value={
                                            profileReqBody.person
                                              .passport_number_validity
                                              ? new Date(
                                                  profileReqBody.person.passport_number_validity
                                                )
                                                  .toISOString()
                                                  .split("T")[0]
                                              : ""
                                          }
                                          onChange={(e) =>
                                            handleProfileInputChange(
                                              e,
                                              "person"
                                            )
                                          }
                                        />
                                        <DatePickerProfile
                                          onChange={(e) => {
                                            setProfileReqBody((prevData) => ({
                                              ...prevData,
                                              person: {
                                                ...prevData.person,
                                                passport_number_validity:
                                                  initialDateFormat(
                                                    new Date(e)
                                                  ),
                                              },
                                            }));
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="fv-plugins-message-container invalid-feedback" />
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div className="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
                              <div className="fv-row mb-6">
                                <label className="fs-6 fw-semibold form-label mt-3 pb-4">
                                  <span>{t("passport_id")}</span>
                                </label>

                                <input
                                  type="text"
                                  className={`form-control form-control-sm form-control-solid`}
                                  name="passport_number"
                                  value={profileReqBody.person.passport_number}
                                  onChange={(e) =>
                                    handleProfileInputChange(e, "person")
                                  }
                                />
                              </div>
                              <div className="col">
                                <div className="fv-row mb-6 fv-plugins-icon-container">
                                  <label className="fs-6 fw-semibold form-label mt-3 pb-4">
                                    <span>{t("pass_validity")}</span>
                                  </label>
                                  <div>
                                    <div className="position-relative">
                                      <svg
                                        className="pass-date-svg"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <g
                                          id="SVGRepo_bgCarrier"
                                          stroke-width="0"
                                        ></g>
                                        <g
                                          id="SVGRepo_tracerCarrier"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        ></g>
                                        <g id="SVGRepo_iconCarrier">
                                          {" "}
                                          <path
                                            d="M20 10V7C20 5.89543 19.1046 5 18 5H6C4.89543 5 4 5.89543 4 7V10M20 10V19C20 20.1046 19.1046 21 18 21H6C4.89543 21 4 20.1046 4 19V10M20 10H4M8 3V7M16 3V7"
                                            stroke="#5e6278"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                          ></path>{" "}
                                          <rect
                                            x="6"
                                            y="12"
                                            width="3"
                                            height="3"
                                            rx="0.5"
                                            fill="#5e6278"
                                          ></rect>{" "}
                                          <rect
                                            x="10.5"
                                            y="12"
                                            width="3"
                                            height="3"
                                            rx="0.5"
                                            fill="#5e6278"
                                          ></rect>{" "}
                                          <rect
                                            x="15"
                                            y="12"
                                            width="3"
                                            height="3"
                                            rx="0.5"
                                            fill="#5e6278"
                                          ></rect>{" "}
                                        </g>
                                      </svg>

                                      <input
                                        type="text"
                                        className={`form-control form-control-sm form-control-solid`}
                                        name="passport_number_validity"
                                        value={
                                          profileReqBody.person
                                            .passport_number_validity
                                            ? new Date(
                                                profileReqBody.person.passport_number_validity
                                              )
                                                .toISOString()
                                                .split("T")[0]
                                            : ""
                                        }
                                        onChange={(e) =>
                                          handleProfileInputChange(e, "person")
                                        }
                                      />
                                      <DatePickerProfile
                                        onChange={(e) => {
                                          setProfileReqBody((prevData) => ({
                                            ...prevData,
                                            person: {
                                              ...prevData.person,
                                              passport_number_validity:
                                                initialDateFormat(new Date(e)),
                                            },
                                          }));
                                        }}
                                      />
                                    </div>

                                    {errorInput.passport_date == "true" ? (
                                      <svg
                                        className="svg-error"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <g
                                          id="SVGRepo_bgCarrier"
                                          stroke-width="0"
                                        ></g>
                                        <g
                                          id="SVGRepo_tracerCarrier"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        ></g>
                                        <g id="SVGRepo_iconCarrier">
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm-1.5-5.009c0-.867.659-1.491 1.491-1.491.85 0 1.509.624 1.509 1.491 0 .867-.659 1.509-1.509 1.509-.832 0-1.491-.642-1.491-1.509zM11.172 6a.5.5 0 0 0-.499.522l.306 7a.5.5 0 0 0 .5.478h1.043a.5.5 0 0 0 .5-.478l.305-7a.5.5 0 0 0-.5-.522h-1.655z"
                                            fill="red"
                                          ></path>
                                        </g>
                                      </svg>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="fv-plugins-message-container invalid-feedback" />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="separator mb-6" />
                      <div className="d-flex justify-content-end">
                        <SecondaryButton
                          title={t("cancel")}
                          onClick={() => resetAccountDetails("person")}
                        />
                        <span
                          className="btn btn-primary-active btn-sm"
                          onClick={(e) => {
                            handleUpdateProfile(e);
                          }}
                        >
                          <span> {t("update")}</span>
                          <span className="indicator-progress">
                            {t("please_wait")}
                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                          </span>
                        </span>
                      </div>
                    </form>
                  </div>
                </div>
                {profileReqBody.customer.class == 2 && (
                  <div className="card mb-5 mb-xl-10">
                    <div
                      className="card-header border-0 cursor-pointer"
                      role="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#kt_account_profile_details"
                      aria-expanded="true"
                      aria-controls="kt_account_profile_details"
                    >
                      <div className="card-title m-0 title-flex">
                        <h3 className="fw-bold m-0">{t("business_details")}</h3>
                      </div>
                    </div>
                    <div
                      id="kt_account_settings_profile_details"
                      className="collapse show card-body p-9 "
                    >
                      {loading.business && <SpinnerDiv />}
                      <form
                        id="kt_ecommerce_settings_general_form"
                        className={`form fv-plugins-bootstrap5 fv-plugins-framework ${
                          loading.business && "disabled-event"
                        }`}
                        action="#"
                      >
                        <div className="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
                          <div className="col">
                            <div className="fv-row mb-6 fv-plugins-icon-container">
                              <label className="fs-6 fw-semibold form-label mt-3 pb-4">
                                <span>{t("business_name")}</span>
                              </label>

                              <input
                                type="text"
                                className="form-control form-control-sm form-control-solid"
                                name="name"
                                value={profileReqBody.company.name}
                                onChange={(e) =>
                                  handleProfileInputChange(e, "company")
                                }
                              />

                              <div className="fv-plugins-message-container invalid-feedback" />
                            </div>
                          </div>
                          <div className="col">
                            <div className="fv-row mb-6">
                              <label className="fs-6 fw-semibold form-label mt-3 pb-4">
                                {t("activity")}
                              </label>

                              <div className="w-100 countries-container">
                                <div className="fv-row height-100">
                                  <div className="row height-100">
                                    <div className="d-flex gap-2 height-100">
                                      <div
                                        className="country-menu-div position-relative"
                                        onClick={() =>
                                          setMenuOpen((prevData) => ({
                                            ...prevData,
                                            activities: !prevData.activities,
                                          }))
                                        }
                                        onMouseLeave={() =>
                                          setMenuOpen((prevData) => ({
                                            ...prevData,
                                            activities: false,
                                          }))
                                        }
                                      >
                                        <span>
                                          {
                                            activities.find(
                                              (item) =>
                                                item.id ===
                                                profileReqBody.company.industry
                                            ).name
                                          }
                                        </span>
                                        <svg
                                          viewBox="0 0 1024 1024"
                                          version="1.1"
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="#000000"
                                        >
                                          <g
                                            id="SVGRepo_bgCarrier"
                                            strokeWidth={0}
                                          />
                                          <g
                                            id="SVGRepo_tracerCarrier"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <g id="SVGRepo_iconCarrier">
                                            <path
                                              d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                                              fill="#5e6278"
                                            />
                                          </g>
                                        </svg>

                                        <div
                                          className={
                                            !menuOpen.activities &&
                                            "display-none"
                                          }
                                        >
                                          {activities.map((item, key) => (
                                            <div
                                              key={key}
                                              onClick={() =>
                                                handleChangeActivity(item.id)
                                              }
                                            >
                                              <span> {item.name}</span>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="fv-row mb-6">
                            <label className="fs-6 fw-semibold form-label mt-3 pb-4">
                              <span>{t("company_number")}</span>
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm form-control-solid"
                              name="registered_number"
                              value={profileReqBody.company.registered_number}
                              onChange={(e) =>
                                handleProfileInputChange(e, "company")
                              }
                            />
                          </div>

                          <div className="col">
                            <div className="fv-row mb-6">
                              <label className="fs-6 fw-semibold form-label mt-3 pb-4">
                                <span>{t("tax_id")}</span>
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-sm form-control-solid"
                                name="vat_number"
                                value={profileReqBody.company.vat_number}
                                onChange={(e) =>
                                  handleProfileInputChange(e, "company")
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="separator mb-6" />
                        <div className="d-flex justify-content-end">
                          <SecondaryButton
                            title={t("cancel")}
                            onClick={() => resetAccountDetails("company")}
                          />
                          <button
                            type="submit"
                            data-kt-contacts-type="submit"
                            className="btn btn-primary-active btn-sm"
                            onClick={(e) => {
                              handleUpdateCompany(e);
                            }}
                          >
                            <span className="indicator-label">
                              {t("update")}
                            </span>
                            <span className="indicator-progress">
                              {t("please_wait")}
                              <span className="spinner-border spinner-border-sm align-middle ms-2" />
                            </span>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
                <div className="card mb-5 mb-xl-10">
                  <div
                    className="card-header border-0 cursor-pointer"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_account_profile_details"
                    aria-expanded="true"
                    aria-controls="kt_account_profile_details"
                  >
                    <div className="card-title m-0 title-flex">
                      <h3 className="fw-bold m-0"> {t("addr_details")}</h3>
                    </div>
                  </div>
                  <div
                    id="kt_account_settings_profile_details"
                    className="collapse show card-body p-9 "
                  >
                    {loading.address && <SpinnerDiv />}
                    <form
                      id="kt_ecommerce_settings_general_form"
                      className={`form fv-plugins-bootstrap5 fv-plugins-framework ${
                        loading.address && "disabled-event"
                      }`}
                      action="#"
                    >
                      <div className="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
                        <div className="col">
                          <div className="fv-row mb-6 fv-plugins-icon-container">
                            <label className="fs-6 fw-semibold form-label mt-3 pb-4">
                              <span> {t("address")}</span>
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm form-control-solid"
                              name="address"
                              value={
                                profileReqBody.contact_data.find(
                                  (contact) => contact.type === 1
                                )?.value || ""
                              }
                              onChange={(e) =>
                                handleAddrInputChange(e, 1, "contact_data")
                              }
                            />
                            <div className="fv-plugins-message-container invalid-feedback" />
                          </div>
                        </div>
                        <div className="col">
                          <div className="fv-row mb-6 fv-plugins-icon-container">
                            <label className="fs-6 fw-semibold form-label mt-3 pb-4">
                              <span> {t("ext_addr")}</span>
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm form-control-solid"
                              name="extended_address"
                              value={
                                profileReqBody.contact_data.find(
                                  (contact) => contact.type === 14
                                )?.value || ""
                              }
                              onChange={(e) =>
                                handleAddrInputChange(e, 14, "contact_data")
                              }
                            />
                            <div className="fv-plugins-message-container invalid-feedback" />
                          </div>
                        </div>
                      </div>
                      <div className="row row-cols-1 row-cols-sm-3 rol-cols-md-1 row-cols-lg-3">
                        <div className="col">
                          <div className="fv-row mb-6 fv-plugins-icon-container">
                            <label className="fs-6 fw-semibold form-label mt-3 pb-4">
                              <span className="">{t("city")}</span>
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm form-control-solid"
                              value={
                                profileReqBody.contact_data.find(
                                  (contact) => contact.type === 2
                                )?.value || ""
                              }
                              onChange={(e) =>
                                handleAddrInputChange(e, 2, "contact_data")
                              }
                            />
                            <div className="fv-plugins-message-container invalid-feedback" />
                          </div>
                        </div>
                        <div className="col">
                          <div className="fv-row mb-6">
                            <label className="fs-6 fw-semibold form-label mt-3 pb-4">
                              <span>{t("state")}</span>
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm form-control-solid"
                              name="state"
                              value={
                                profileReqBody.contact_data.find(
                                  (contact) => contact.type === 4
                                )?.value || ""
                              }
                              onChange={(e) =>
                                handleAddrInputChange(e, 4, "contact_data")
                              }
                            />
                          </div>
                        </div>
                        <div className="col">
                          <div className="fv-row mb-6">
                            <label className="fs-6 fw-semibold form-label mt-3 pb-4">
                              <span>{t("postal_code")}</span>
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm form-control-solid"
                              name="postal_code"
                              value={
                                profileReqBody.contact_data.find(
                                  (contact) => contact.type === 3
                                )?.value || ""
                              }
                              onChange={(e) =>
                                handleAddrInputChange(e, 3, "contact_data")
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
                        <div className="col">
                          <div className="fv-row mb-6 fv-plugins-icon-container">
                            <label className="fs-6 fw-semibold form-label mt-3 pb-4">
                              <span>{t("country")}</span>
                            </label>
                            <div className="w-100 countries-container">
                              <div className="fv-row height-100">
                                <div className="row height-100">
                                  <div className="d-flex gap-2 height-100">
                                    <div
                                      className="country-menu-div position-relative"
                                      onClick={() =>
                                        !menuOpen.countries &&
                                        setMenuOpen((prevData) => ({
                                          ...prevData,
                                          countries: true,
                                        }))
                                      }
                                      onMouseLeave={() =>
                                        setMenuOpen((prevData) => ({
                                          ...prevData,
                                          countries: false,
                                        }))
                                      }
                                    >
                                      <span>
                                        <img
                                          className="height-1-2 width-1-6 obj-fit-cover me-4 flag-style"
                                          src={`/assets/media/flags/${
                                            profileReqBody.contact_data
                                              .find(
                                                (contact) => contact.type === 5
                                              )
                                              ?.value.toLowerCase() || ""
                                          }.svg`}
                                          alt={
                                            profileReqBody.contact_data.find(
                                              (contact) => contact.type === 5
                                            )?.value || ""
                                          }
                                        />
                                        {t(
                                          `countries.${
                                            profileReqBody.contact_data.find(
                                              (contact) => contact.type === 5
                                            )?.value || ""
                                          }`
                                        )}
                                      </span>
                                      <svg
                                        viewBox="0 0 1024 1024"
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="#000000"
                                      >
                                        <g
                                          id="SVGRepo_bgCarrier"
                                          strokeWidth={0}
                                        />
                                        <g
                                          id="SVGRepo_tracerCarrier"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <g id="SVGRepo_iconCarrier">
                                          <path
                                            d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                                            fill="#5e6278"
                                          />
                                        </g>
                                      </svg>

                                      <div
                                        className={`position-relative    ${
                                          !menuOpen.countries && "display-none"
                                        }`}
                                      >
                                        <input
                                          type="text"
                                          placeholder=""
                                          value={filterValue}
                                          onChange={handleInputChange}
                                          className="search-country-input"
                                          autoComplete="new-password"
                                        />
                                        {filteredCountries.map((item, key) => (
                                          <div
                                            key={key}
                                            onClick={() => {
                                              handleChangeCountry(
                                                item,
                                                5,
                                                "contact_data"
                                              );
                                              setMenuOpen((prevData) => ({
                                                ...prevData,
                                                countries: false,
                                              }));
                                            }}
                                          >
                                            <img
                                              className="height-1-2 width-1-6 obj-fit-cover me-4 flag-style"
                                              src={`/assets/media/flags/${item.code.toLowerCase()}.svg`}
                                              alt={item.code}
                                            />
                                            <span>
                                              {" "}
                                              {t(`countries.${item.code}`)}
                                            </span>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="fv-plugins-message-container invalid-feedback" />
                          </div>
                        </div>
                      </div>
                      <div className="separator mb-6" />
                      <div className="d-flex justify-content-end">
                        <SecondaryButton
                          title={t("cancel")}
                          onClick={() => resetAccountDetails("contact_data")}
                        />
                        <button
                          type="submit"
                          data-kt-contacts-type="submit"
                          className="btn btn-primary-active btn-sm"
                          onClick={(e) => {
                            handleUpdateAddress(e);
                          }}
                        >
                          <span className="indicator-label">{t("update")}</span>
                          <span className="indicator-progress">
                            {t("please_wait")}
                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                          </span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <Documents type={profileReqBody.customer.class} />
                <div className="card mb-5 mb-xl-10">
                <div className="card-header card-header-stretch pb-0">
                  <div className="card-title">
                    <h3 className="m-0">{t("desactivate_account")}</h3>
                  </div>
                </div>
                <div
                  id="kt_billing_payment_tab_content"
                  className="card-body tab-content"
                >
                  <div
                    id="kt_billing_creditcard"
                    className="tab-pane fade show active"
                    role="tabpanel"
                  >
                    <div className="row gx-9 gy-6">
                      <div className="col-xl-6 width-three">
                        <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed h-lg-100 p-6">
                          <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                            <div className="mb-3 mb-md-0 fw-semibold">
                              <h4 className="text-gray-900 fw-bold">
                                {t("important_note")}!
                              </h4>
                              <div className="fs-6 text-gray-700 pe-7">
                                {t("desactivation_text")}
                              </div>
                            </div>
                            <span
                              className="btn btn-disable btn-sm fw-semibold"
                              data-bs-toggle="modal"
                              data-bs-target="#kt_modal_new_card"
                            >
                              {t("desactivate_account")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
             
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountDetails;
