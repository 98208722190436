import React, { useEffect } from "react";
import BillingsDatatable from "./Components/BillingDataTable";

import { useTranslation } from "react-i18next";
import "./Style/Invoices.css"
const Invoices = () => {


  useEffect(() => {
    localStorage.setItem("active", 13);
    document.dispatchEvent(new CustomEvent("activePage"))
  }, []);

  const {t}=useTranslation()
  return (
    <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
      <div className="app-container container-fluid d-flex flex-row flex-column-fluid">
        <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
        
          <div className="d-flex flex-column flex-column-fluid">
            <div id="kt_app_content" className="app-content flex-column-fluid">
             
              <BillingsDatatable />
             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoices;
