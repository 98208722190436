export const fetchData = async (body) => {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/interface/export/calls`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body,
    });
  };
  
  export const getLogs = async(body)=>{
    return await fetch(`${process.env.REACT_APP_BASE_URL}/Internal/logs?orderBy=id&with_terms=enabled&limit=5&sort=desc`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body,
    });
  }

  