import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BillingService from "../Services/fetchInvoices";
import {
  convertCurrency,
  defaultOnlyDateFormat,
  getLocale,
} from "../../../constants/Utils";
import DatatableHeader from "../../../components/reusables/DataTableHeader";
import DataTableTextFilter from "../../../components/reusables/DataTableTextFilter";
import DataTableDateFilter from "../../../components/reusables/DataTableDateFilter";
import DataTableSelectFilter from "../../../components/reusables/DataTableSelectFilter";
import DataTableComponent from "../../../components/reusables/DataTable";
import DataTableCustomSelectFilter from "../../../components/reusables/DataTableCustomSelectFilter";
import useSweetAlert from "../../../hooks/useSweetAlert";

import ErrorExpiredSession from "../../../components/Alerts/errorExpiredSession";
import ErrorSwal from "../../../components/Alerts/errorAlert";
import getInvoices from "../Services/fetchInvoices";

const BillingsDatatable = () => {
  const { t } = useTranslation();
  const dateFormat = JSON.parse(localStorage.getItem("data")).date_format;
  const languagePref = localStorage.getItem("i18nextLng");

  useEffect(() => {
    Promise.all([import("../Style/datatable.css")]).then(() => {
      setLoad(true);
    });
  }, []);

  const locale = getLocale(languagePref);

  const PaymentTypes = {
    "": t("all"),
    1: t("rental_did"),
    2: t("outbound_calling_credit"),
  };

  const Status = {
    all: t("all"),
    paid: t("paid"),
  };

  const Currency = {
    USD: "USD",
    EUR: "EUR",
  };

  const openFileInNewWindow = (filePath) => {
    window.open(filePath, "_blank");
  };

  const PaymentMethods = {
    "": t("all"),
    1: t("bank_transfer"),
    2: t("cash"),
    3: t("check"),
    4: t("cryptocurrency"),
    5: t("debit_credit"),
    6: t("credit_card"),
    7: t("wallet"),
    8: "PayPal",
    9: "E-Dinar",
  };

  const getBillingsData = async () => {
    try {
      setLoad(false);

      const response = await getInvoices(
        JSON.stringify({
          id: billingsResponseData.id ?? null,
          amount: billingsResponseData.amount ?? null,
          currency: billingsResponseData.currency ?? null,
          payment_method: billingsResponseData.payment_method ?? null,
          payment_type: billingsResponseData.payment_type ?? null,
          date_creation: billingsResponseData.date_creation ?? null,
          date_start: billingsResponseData.date_start ?? null,
          date_end: billingsResponseData.date_end ?? null,
        }),
        new URLSearchParams({
          with_terms: true,
          sort: billingsResponseData.direction,
          orderBy: billingsResponseData.item,
          limit: billingsResponseData.limit,
          offset: billingsResponseData.skip,
        })
      );
      setLoad(true);
      if (!response.ok) {
        if (response.status === 401) {
          ErrorExpiredSession(
            t("session_expired"),
            t("errors.401"),
            t("login")
          );
        } else if (response.status === 403) {
          ErrorSwal(t("errors.403"));
        } else if (response.status === 404) {
          ErrorSwal(t("errors.404"));
        } else if (response.status === 405) {
          ErrorSwal(t("errors.405"));
        } else if (response.status === 422) {
          ErrorSwal(t("errors.422"));
        } else if (response.status === 423) {
          ErrorSwal(t("errors.423"));
        } else if (response.status === 406) {
          ErrorSwal(t("errors.406"));
        }
        setBillingsData([
          {
            id: null,
            amount: null,
            currency: null,
            date_creation: null,
            pdf: null,
            type: null,
            method: null,
            status: null,
          },
        ]);
        return;
      }

      setLoad(true);
      const fetchedData = await response.json();

      setBillingsResponseData((prevData) => ({
        ...prevData,
        total: fetchedData.total,
      }));

      if (fetchedData.total > 0) {
        const mappedData = fetchedData.data.map((data) => ({
          id: data.id,
          currency: data.currency,
          amount: data.amount,
          date_creation: data.date_creation,
          pdf: data.pdf,
          type: data.payment_type,
          method: data.payment_method,
          status: data.status,
        }));
        setBillingsData(mappedData);
      } else {
        setBillingsData([
          {
            id: null,
            amount: null,
            currency: null,
            date_creation: null,
            pdf: null,
            method: null,
            type: null,
            status: null,
          },
        ]);
      }
    } catch (error) {
      await getBillingsDataSecondTry();
    } finally {
      setLoad(true);
    }
  };

  const getBillingsDataSecondTry = async () => {
    try {
      const response = await getInvoices(
        JSON.stringify({
          id: billingsResponseData.id ?? null,
          currency: billingsResponseData.currency ?? null,
          amount: billingsResponseData.amount ?? null,
          payment_method: billingsResponseData.payment_method ?? null,
          payment_type: billingsResponseData.payment_type ?? null,
          date_creation: billingsResponseData.date_creation ?? null,
          date_start: billingsResponseData.date_start ?? null,
          date_end: billingsResponseData.date_end ?? null,
        }),
        new URLSearchParams({
          with_terms: true,
          sort: billingsResponseData.direction,
          orderBy: billingsResponseData.item,
          limit: billingsResponseData.limit,
          offset: billingsResponseData.skip,
        })
      );

      if (!response.ok) {
        ErrorSwal(t("errors.500"));
        setBillingsData([
          {
            id: null,
            amount: null,
            currency: null,
            date_creation: null,
            pdf: null,
            type: null,
            method: null,
          },
        ]);
        throw new Error(`${response.status}: ${response.statusText}`);
      }

      const fetchedData = await response.json();

      setBillingsResponseData((prevData) => ({
        ...prevData,
        total: fetchedData.total,
      }));
      if (fetchedData.total > 0) {
        const mappedData = fetchedData.data.map((data) => ({
          id: data.id,
          currency: data.currency,
          amount: data.amount,
          date_creation: data.date_creation,
          pdf: data.pdf,
          status: data.status,
        }));
        setBillingsData(mappedData);
      } else {
        setBillingsData([
          {
            id: null,
            amount: null,
            currency: null,
            date_creation: null,
            pdf: null,
            type: null,
            method: null,
            status: null,
          },
        ]);
      }
    } catch (error) {
      ErrorSwal(t("errors.500"));
      setBillingsData([
        {
          id: null,
          amount: null,
          currency: null,
          date_creation: null,
          pdf: null,
          type: null,
          method: null,
          status: null,
        },
      ]);
    } finally {
      setLoad(true);
    }
  };

  const [load, setLoad] = useState(false);

  const [billingsResponseData, setBillingsResponseData] = useState({
    skip: 0,
    limit: 5,
    total: null,
    item: "date_creation",
    direction: "desc",
    id: "",
    amount: "",
    currency: "",
    date_creation: "",
    date_start: "",
    payment_method: "",
    payment_type: "",
    date_end: "",
  });

  const fetchDataDirectionFilter = (item, direction) => {
    setBillingsResponseData((prevData) => ({
      ...prevData,
      item: item,
      direction: direction,
    }));
  };

  const handleInputChange = (item, value) => {
    setBillingsResponseData((prevData) => ({
      ...prevData,
      [item]: value,
      skip: 0,
    }));
  };

  const checkIfSelected = (item, direction) => {
    if (
      billingsResponseData.item == item &&
      billingsResponseData.direction == direction
    )
      return "#919191";
    return "#d9d9d9";
  };

  useEffect(() => {
    getBillingsData();
  }, [
    billingsResponseData.skip,
    billingsResponseData.limit,
    billingsResponseData.item,
    billingsResponseData.direction,
    billingsResponseData.id,
    billingsResponseData.amount,
    billingsResponseData.currency,
    billingsResponseData.payment_method,
    billingsResponseData.payment_type,
    billingsResponseData.date_creation,
    billingsResponseData.date_start,
    billingsResponseData.date_end,
  ]);

  const billingsColumns = [
    {
      name: (
        <div className="header-dt-2">
          <DatatableHeader
            column="id"
            fetchDataDirectionFilter={fetchDataDirectionFilter}
            header={t("billing.columns.id")}
            checkIfSelected={checkIfSelected}
          />
          <DataTableTextFilter
            column="id"
            handleInputChange={handleInputChange}
            placeholder={t("billing.placeholders.id")}
          />
        </div>
      ),
      cell: (row) =>
        load ? <span>{row.id}</span> : <div className="skeleton-dt"></div>,
    },
    {
      name: (
        <div className="header-dt-2">
          <DatatableHeader
            column="amount"
            fetchDataDirectionFilter={fetchDataDirectionFilter}
            header={t("billing.columns.amount")}
            checkIfSelected={checkIfSelected}
          />
          <DataTableTextFilter
            column="amount"
            handleInputChange={handleInputChange}
            placeholder={t("billing.placeholders.amount")}
          />
        </div>
      ),
      cell: (row) =>
        load ? (
          <span>
            {/* {formatCurrency(row.currency ?? "")} */}
            {row.amount}
          </span>
        ) : (
          <div className="skeleton-dt"></div>
        ),
    },
    {
      name: (
        <div className="header-dt-2">
          <DatatableHeader
            column="currency"
            fetchDataDirectionFilter={fetchDataDirectionFilter}
            header={t("billing.columns.currency")}
            checkIfSelected={checkIfSelected}
          />
          <DataTableSelectFilter
            column="currency"
            handleInputChange={handleInputChange}
            placeholder={t("billing.placeholders.currency")}
            options={Currency}
            withAllOption
          />
        </div>
      ),
      cell: (row) =>
        load ? (
          <span>{row.currency && convertCurrency(row.currency)}</span>
        ) : (
          <div className="skeleton-dt"></div>
        ),
    },
    {
      name: (
        <div className="header-dt-2">
          <DatatableHeader
            column="payment_method"
            fetchDataDirectionFilter={fetchDataDirectionFilter}
            header={t("billing.columns.payment_method")}
            checkIfSelected={checkIfSelected}
          />
          <DataTableCustomSelectFilter
            column="payment_method"
            handleInputChange={handleInputChange}
            placeholder={t("billing.placeholders.payment_method")}
            options={PaymentMethods}
            classValue="search-input-dt search-input-dt-direction-method max-w-min-content"
          />
        </div>
      ),
      cell: (row) =>
        load ? (
          <span>
            {row.method == 1
              ? t("bank_transfer")
              : row.method == 2
              ? t("cash")
              : row.method == 3
              ? t("check")
              : row.method == 4
              ? t("cryptocurrency")
              : row.method == 5
              ? t("debit_credit")
              : row.method == 6
              ? t("credit_card")
              : row.method == 7
              ? t("wallet")
              : row.method == 8
              ? "PayPal"
              : row.method == 9
              ? "E-Dinar"
              : ""}
          </span>
        ) : (
          <div className="skeleton-dt"></div>
        ),
    },
    {
      name: (
        <div className="header-dt-2">
          <DatatableHeader
            column="payment_type"
            fetchDataDirectionFilter={fetchDataDirectionFilter}
            header={t("billing.columns.payment_type")}
            checkIfSelected={checkIfSelected}
          />
          <DataTableCustomSelectFilter
            column="payment_type"
            handleInputChange={handleInputChange}
            placeholder={t("billing.placeholders.payment_type")}
            options={PaymentTypes}
            withAllOption={false}
            classValue="search-input-dt search-input-dt-direction-method max-w-min-content"
          />
        </div>
      ),
      cell: (row) =>
        load ? (
          <span>{row.type == 1 ? t("rental_did") : ""}</span>
        ) : (
          <div className="skeleton-dt"></div>
        ),
    },
    {
      name: (
        <div className="header-dt-2">
          <DatatableHeader
            column="date_creation"
            fetchDataDirectionFilter={fetchDataDirectionFilter}
            checkIfSelected={checkIfSelected}
            header={t("billing.columns.created_at")}
          />
          <DataTableDateFilter
            placeholder={t("billing.placeholders.created_at")}
            isRange={true}
            onChange={(e) => {
              if (e.length >= 2) {
                handleInputChange(
                  "date_start",
                  e[0]?.toLocaleDateString("en-CA")
                );
                handleInputChange(
                  "date_end",
                  e[1]?.toLocaleDateString("en-CA")
                );
              }
            }}
            onClearClick={() =>
              setBillingsResponseData((prevState) => ({
                ...prevState,
                date_start: null,
                date_end: null,
              }))
            }
            date={[
              billingsResponseData.date_start,
              billingsResponseData.date_end,
            ]}
          />
        </div>
      ),
      cell: (row) =>
        load ? (
          <span>
            {row.date_creation && defaultOnlyDateFormat(row.date_creation)}
          </span>
        ) : (
          <div className="skeleton-dt"></div>
        ),
    },

    {
      name: (
        <div className="header-dt-2">
          <DatatableHeader
            header={t("billing.columns.status")}
            column="status"
            fetchDataDirectionFilter={fetchDataDirectionFilter}
            checkIfSelected={checkIfSelected}
          />
          <DataTableSelectFilter
            column="status"
            handleInputChange={handleInputChange}
            placeholder={t("billing.placeholders.status")}
            options={Status}
          />
        </div>
      ),
      cell: (row) =>
        load ? (
          <span className={`badge badge-light-success margin-dim`}>
            {row.status === 1 ? t("paid") : ""}
          </span>
        ) : (
          <div className="skeleton-dt"></div>
        ),
    },
    {
      name: (
        <div className="header-dt-2">
          <DatatableHeader
            header={t("billing.columns.action")}
            isSortable={false}
          />
        </div>
      ),
      cell: (row) =>
        load ? (
          row.id &&
          row.pdf && (
            <div className="buttons-bil">
              <span
                title={t("open_doc")}
                className="grey-button"
                onClick={() => openFileInNewWindow(row.pdf)}
              >
                <svg
                  className="docs-svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      d="M15.0007 12C15.0007 13.6569 13.6576 15 12.0007 15C10.3439 15 9.00073 13.6569 9.00073 12C9.00073 10.3431 10.3439 9 12.0007 9C13.6576 9 15.0007 10.3431 15.0007 12Z"
                      stroke="#5c5e6e"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>{" "}
                    <path
                      d="M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z"
                      stroke="#5c5e6e"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>{" "}
                  </g>
                </svg>
              </span>
              <span title={t("download_doc")} className=" grey-button">
                <svg
                  className="docs-svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9.163 2.819C9 3.139 9 3.559 9 4.4V11H7.803c-.883 0-1.325 0-1.534.176a.75.75 0 0 0-.266.62c.017.274.322.593.931 1.232l4.198 4.401c.302.318.453.476.63.535a.749.749 0 0 0 .476 0c.177-.059.328-.217.63-.535l4.198-4.4c.61-.64.914-.96.93-1.233a.75.75 0 0 0-.265-.62C17.522 11 17.081 11 16.197 11H15V4.4c0-.84 0-1.26-.164-1.581a1.5 1.5 0 0 0-.655-.656C13.861 2 13.441 2 12.6 2h-1.2c-.84 0-1.26 0-1.581.163a1.5 1.5 0 0 0-.656.656zM5 21a1 1 0 0 0 1 1h12a1 1 0 1 0 0-2H6a1 1 0 0 0-1 1z"
                      fill="currentColor"
                    ></path>
                  </g>
                </svg>
              </span>
            </div>
          )
        ) : (
          <div className="skeleton-dt"></div>
        ),
    },
  ];
  const [billingsData, setBillingsData] = useState([
    {
      id: null,
      amount: null,
      currency: null,
      date_creation: null,
      pdf: null,
      method: null,
      type: null,
      status: null,
    },
  ]);

  return (
    <DataTableComponent
      columns={billingsColumns}
      data={billingsData}
      paramsData={billingsResponseData}
      setParamsData={setBillingsResponseData}
      load={load}
      header={t("billing.datatable.header")}
    />
  );
};

export default BillingsDatatable;
