import React, { useEffect } from "react";
import ListNumbers from "./Views/ListNumbers";


const MainNumbers = () => {

  useEffect(() => {
    localStorage.setItem("active", 2);
    document.dispatchEvent(new CustomEvent("activePage"))
  }, []);

  return (
    <div>
      <ListNumbers />
    </div>
  );
};

export default MainNumbers;
