import React from "react";

const SecondaryButton = ({ title, onClick, marg_none, extra_class }) => {
  return (
    <a
      className={`btn btn-light secondary btn-sm ${marg_none ? "" : "me-3"}${
        extra_class ? extra_class : ""
      }`}
      onClick={onClick}
    >
      {title}
    </a>
  );
};

export default SecondaryButton;
