import React, { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "../modules/Dashboard/Dashboard";
import MainNumbers from "../modules/Numbers/MainNumbers";
// import AddNumber from '../modules/Numbers/Views/AddNumber'
import EmptyNumbers from "../modules/Numbers/Views/EmptyNumbers";
import MainUsers from "../modules/Users/MainUsers";
// import EmptyUsers from '../modules/Users/Views/EmptyUsers'
// import EmptyListing from '../modules/Listing/Views/EmptyListing'
import ReportGeneral from "../modules/Rportings/Views/ReportGeneral";
import ReportGeo from "../modules/Rportings/Views/ReportGeo";
import ReportNumbers from "../modules/Rportings/Views/ReportNumbers";
import ReportUsers from "../modules/Rportings/Views/ReportUsers";
import Logs from "../modules/Settings/Views/Logs/Logs";
import Preferences from "../modules/Settings/Views/Preferences";
import Security from "../modules/Settings/Views/Security";
import Users from "../modules/Settings/Views/Users";
import Billing from "../modules/Settings/Views/billing/Billing";
import Pricing from "../modules/Pricing/Views/Pricing";

import SoonInterface from "../Layouts/SoonInterface";
import MainListing from "../modules/Listing/MainListing";
import AccountDetails from "../modules/Settings/Views/AccountDetails";
import ListCalls from "../modules/Listing/Views/ListCalls";
import ListSms from "../modules/Listing/Views/ListSMS";
import AddNumber from "../modules/Numbers/Views/AddNumber";

import AddUser from "../modules/Users/Views/AddUser";
import AddNumberSecond from "../modules/Numbers/Views/AddNumberSecond";
import Tutorials from "../modules/Support/Pages/Tutorials";
import Tickets from "../modules/Support/Pages/Tickets";
import Faq from "../modules/Support/Pages/Faq";
import Contact from "../modules/Support/Pages/Contact";
import Overview from "../modules/Support/Pages/Overview";
import SmsConversations from "../modules/SMS_Conversations/Pages/SmsConversations";
import Invoices from "../modules/Invoices/Invoices";

const AllRoutes = () => {
  const [userType, setUserType] = useState();
  const handleType = (uType) => {
    setUserType(uType);
  };

  return (
    <Routes>
      {/* Dashboard */}
      <Route
        path={`${process.env.REACT_APP_BASENAME}dashboard`}
        element={<Dashboard />}
      />

      {/* Numbers */}
      <Route
        path={`${process.env.REACT_APP_BASENAME}numbers_list`}
        element={<MainNumbers />}
      />
      <Route
        path={`${process.env.REACT_APP_BASENAME}numbers_buy`}
        // element={<SoonInterface />}
        element={<AddNumber/>}
      />
      {/* <Route path={`${process.env.REACT_APP_BASENAME}numbers_buy2`}element={<AddNumberSecond />} /> */}

      {/* Users */}
      <Route
        path={`${process.env.REACT_APP_BASENAME}users_list`}
        element={<MainUsers />}
      />
      <Route
        path={`${process.env.REACT_APP_BASENAME}users_create`}
        element={<AddUser />}
      />

      {/* Listing */}
      <Route
        path={`${process.env.REACT_APP_BASENAME}listing/calls`}
        element={<ListCalls />}
      />
      <Route
        path={`${process.env.REACT_APP_BASENAME}listing/sms`}
        element={<ListSms />}
      />

      {/* Invoices */}
      <Route
        path={`${process.env.REACT_APP_BASENAME}invoices`}
        element={<Invoices />}
      />

      {/* Reportings */}
      <Route
        path={`${process.env.REACT_APP_BASENAME}report_general`}
        element={<ReportGeneral />}
      />
      <Route
        path={`${process.env.REACT_APP_BASENAME}report_geo`}
        element={<ReportGeo />}
      />
      <Route
        path={`${process.env.REACT_APP_BASENAME}report_numbers`}
        element={<ReportNumbers />}
      />
      <Route
        path={`${process.env.REACT_APP_BASENAME}report_users`}
        element={<SoonInterface />}
      />

      {/* Settings */}
      <Route
        path={`${process.env.REACT_APP_BASENAME}settings_account`}
        element={<AccountDetails userType={userType} handleType={handleType} />}
      />
      <Route
        path={`${process.env.REACT_APP_BASENAME}settings_security`}
        element={<Security userType={userType} />}
      />
      <Route
        path={`${process.env.REACT_APP_BASENAME}settings_billing`}
        element={<Billing userType={userType} />}
      />
      <Route
        path={`${process.env.REACT_APP_BASENAME}settings_preferences`}
        element={<Preferences userType={userType} />}
      />
      <Route
        path={`${process.env.REACT_APP_BASENAME}settings_users`}
        element={<Users userType={userType} />}
      />
      <Route
        path={`${process.env.REACT_APP_BASENAME}settings_logs`}
        element={<Logs userType={userType} />}
      />

      {/* Pricing */}
      <Route
        path={`${process.env.REACT_APP_BASENAME}pricing`}
        element={<Pricing />}
      />

      {/* Support */}
      <Route
        path={`${process.env.REACT_APP_BASENAME}support`}
        element={<Overview />}
      />
      <Route
        path={`${process.env.REACT_APP_BASENAME}support_contact`}
        element={<Contact />}
      />
      <Route
        path={`${process.env.REACT_APP_BASENAME}support_faq`}
        element={<Faq />}
      />
      <Route
        path={`${process.env.REACT_APP_BASENAME}support_tutorials`}
        element={<Tutorials />}
      />
      <Route
        path={`${process.env.REACT_APP_BASENAME}support_tickets`}
        element={<Tickets />}
      />

      {/* SMS Conversations */}
      <Route path={`${process.env.REACT_APP_BASENAME}SMS_conversations`} element={<SoonInterface />} />

      <Route path="*" element={<Navigate to={`${process.env.REACT_APP_BASENAME}dashboard`} />} />
    </Routes>
  );
};

export default AllRoutes;
