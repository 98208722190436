import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../Style/HeaderSettings.css"
import ErrorExpiredSession from "../../../components/Alerts/errorExpiredSession";
import ErrorSwal from "../../../components/Alerts/errorAlert";
import InternalErrorSwal from "../../../components/Alerts/internalErrorAlert";
import SpinnerDiv from "../../../Layouts/SpinnerDiv";

const HeaderSettings = ({ active, type }) => {


  const [avatar, setAvatar] = useState(localStorage.getItem("avatar"));

  const [avatarLoading, setAvatarLoading] = useState(false);

  const [firstname, setFirstname] = useState(localStorage.getItem("firstname"));

  const [lastname, setLastname] = useState(localStorage.getItem("lastname"));
  

  document.addEventListener("profileAccountDetailsChange", () => {
    setFirstname(localStorage.getItem("firstname"));
    setLastname(localStorage.getItem("lastname"));
  });



  const { t } = useTranslation();

  const avatarRef = useRef();





  const handleNewAvatar = async (e) => {
   
    try {

      const fileInput = avatarRef.current;

      const file = fileInput.files[0];

      if (!file) {

        console.error("No file selected");

        return;

      }
      setAvatarLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/resources/users/upload/avatar`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error(response.status);
      }

      const fetchedData = await response.json();

      if (fetchedData && fetchedData.avatar) {
        setAvatarLoading(false);
        setAvatar(fetchedData.avatar);
        localStorage.setItem("avatar", fetchedData.avatar);
        document.dispatchEvent(new CustomEvent("avatarChange"));
      }
    } catch (error) {
     
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else {
        InternalErrorSwal();
      }
    }
  };

  return (
 
    
        <div className="card-body pt-9 pb-0 header_settings">
          <div className="d-flex flex-wrap flex-sm-nowrap mb-0">
            <div className="me-7 mb-0 position-relative">{avatarLoading && <SpinnerDiv/>}
              <div className={`symbol symbol-100px symbol-lg-100px symbol-fixed ${avatarLoading&&"disabled-event"}`}>  
                <img
                  src={
                    !avatar || avatar == "null"
                      ? "assets/media/avatars/blank.png"
                      : avatar
                  }
                  alt="image"
                />

              

                <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow update-image">
                  <svg
                    className="docs-svg"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#5c5e6e"
                  >
                    <path d="M21 22H3c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h18c.41 0 .75.34.75.75s-.34.75-.75.75zM19.02 3.482c-1.94-1.94-3.84-1.99-5.83 0l-1.21 1.21c-.1.1-.14.26-.1.4a8.129 8.129 0 005.53 5.53.4.4 0 00.41-.1l1.2-1.21c.99-.98 1.47-1.93 1.47-2.89.01-.99-.47-1.95-1.47-2.94zM15.61 11.53c-.29-.14-.57-.28-.84-.44a8.8 8.8 0 01-.64-.42c-.17-.11-.37-.27-.56-.43a1.22 1.22 0 01-.17-.15c-.33-.28-.7-.64-1.03-1.04-.03-.02-.08-.09-.15-.18-.1-.12-.27-.32-.42-.55a5.49 5.49 0 01-.39-.59c-.16-.27-.3-.54-.44-.82a6.88 6.88 0 01-.061-.135c-.148-.333-.583-.43-.84-.173L4.34 12.331c-.13.13-.25.38-.28.55l-.54 3.83c-.1.68.09 1.32.51 1.75.36.35.86.54 1.4.54.12 0 .24-.01.36-.03l3.84-.54c.18-.03.43-.15.55-.28l5.722-5.721c.26-.26.161-.705-.176-.85a26.852 26.852 0 01-.116-.05z" />
                  </svg>
                  <input
                    ref={avatarRef}
                    className="invisible-input"
                    type="file"
                    name="avatar"
                    accept=".png, .jpg, .jpeg"
                    onChange={handleNewAvatar}
                  />
                </label>
              </div>
            </div>
            <div className="flex-grow-1">
              <div className="d-flex justify-content-between align-items-start flex-wrap mb-0">
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-2">
                    <span
                      className="text-gray-900 fs-2 fw-bold me-1"
                    >
                      {firstname} {lastname}
                    </span>
                    <span >
                      <span className="svg-icon svg-icon-1 svg-icon-primary">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z"
                            fill="currentColor"
                          />
                          <path
                            d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                    </span>
                    <span
                      className="btn btn-sm btn-light-success fw-bold ms-2 fs-8 py-1 px-3"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_upgrade_plan"
                    >
                      {type == 2
                        ? `${t("business_account")}`
                        : `${t("personal_account")}`}
                    </span>
                  </div>
                </div>
                {/* <div className="d-flex mb-0">
                  <button
                    id="kt_account_deactivate_account_submit"
                    type="submit"
                    className="btn btn-disable btn-sm fw-semibold "
                  >
                    {t("desactivate_account")}
                  </button>
                </div> */}
              </div>
              <div className="d-flex flex-wrap flex-stack">
                <div className="d-flex flex-column flex-grow-1 pe-8">
                  <div className="d-flex flex-wrap">
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div className="d-flex align-items-center">
                        <div
                          className="fs-4 fw-bold counted"
                          data-kt-countup="true"
                          data-kt-countup-value={0}
                          data-kt-countup-prefix="$"
                          data-kt-initialized={1}
                        >
                          {localStorage.getItem("total_numbers")}
                        </div>
                      </div>
                      <div className="fw-semibold fs-7 text-gray-400">
                        {t("numbers")}
                      </div>
                    </div>
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div className="d-flex align-items-center">
                        <div
                          className="fs-4 fw-bold counted"
                          data-kt-countup="true"
                          data-kt-countup-value={75}
                          data-kt-initialized={1}
                        >
                          {localStorage.getItem("total_users")}
                        </div>
                      </div>
                      <div className="fw-semibold fs-7 text-gray-400">
                        {t("users")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
            <Link to={`${process.env.REACT_APP_BASENAME}settings_account`} className="nav-item mt-2 ">
              <span
                className={` ${
                  active == 1 ? "clicked-item active " : ""
                } nav-link text-active-primary ms-0 me-10 py-5 `}
                
              >
                {t("account_details")}
              </span>
            </Link>
            <Link to={`${process.env.REACT_APP_BASENAME}settings_security`}>
              <li className="nav-item mt-2">
                <span
                  className={`nav-link text-active-primary ms-0 me-10 py-5 ${
                    active == 2 ? "active" : ""
                  }`}
                  
                >
                  {t("security")}
                </span>
              </li>
            </Link>
            {/* <Link to={`${process.env.REACT_APP_BASENAME}settings_billing`}>
              <li className="nav-item mt-2">
                <span
                  className={`nav-link text-active-primary ms-0 me-10 py-5 ${
                    active == 3 ? "active" : ""
                  }`}
                
                >
                  {t("setting.header.billing")}
                </span>
              </li>
            </Link> */}
            <Link to={`${process.env.REACT_APP_BASENAME}settings_preferences`}>
              <li className="nav-item mt-2">
                <span
                  className={`nav-link text-active-primary ms-0 me-10 py-5 ${
                    active == 4 ? "active" : ""
                  }`}
                  
                >
                  {t("preferences")}
                </span>
              </li>
            </Link>
            <Link to={`${process.env.REACT_APP_BASENAME}settings_users`}>
              <li className="nav-item mt-2">
                <span
                  className={`nav-link text-active-primary ms-0 me-10 py-5 ${
                    active == 5 ? "active" : ""
                  }`}
                  
                >
                  {t("users")}
                </span>
              </li>
            </Link>
            <Link to={`${process.env.REACT_APP_BASENAME}settings_logs`}>
              <li className="nav-item mt-2">
                <span
                  className={`nav-link text-active-primary ms-0 me-10 py-5 ${
                    active == 6 ? "active" : ""
                  }`}
                >
                  {t("setting.header.logs")}
                </span>
              </li>
            </Link>
          </ul>
        </div>
      

  );
};

export default HeaderSettings;
