import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "../../Style/Modals.css";
import DataTable from "react-data-table-component";
import countriesData from "../../../../Data/Countries.json";
import {
  convertedDate,
  defaultDateFormat,
  defaultOnlyDateFormat,
  formatDate,
  initialDateTimeFormat,
} from "../../../../constants/Utils";
import ErrorExpiredSession from "../../../../components/Alerts/errorExpiredSession";
import ErrorSwal from "../../../../components/Alerts/errorAlert";
import { fetchCalls } from "../../../Listing/Services/calls";
import DateTimePicker from "../../../../components/Form/DateTimePicker";
import TimerPicker from "../../../../components/Form/TimerPicker";
import DatePicker from "../../../../components/reusables/DatePicker";
import InternalErrorSwal from "../../../../components/Alerts/internalErrorAlert";

const CallsAndSmsModal = ({ closeModal, number, country, sms, name }) => {
  const { t } = useTranslation();

  const [inputs, setInputs] = useState({
    id: "",
    caller: "",
    did: "",
    duration: "",
    extension: "",
    cost: "",
    startTime: "",
    endTime: "",
  });

  const [filtersValues, setFiltersValues] = useState({
    countries: [""],
    status: [""],
  });

  const resetFilters = () => {
    setFiltersCount(0);
    setFilters({
      id: false,
      feature: false,
      date: false,
      status: false,
      did: false,
      country: false,
      caller: false,
      duration: false,
      extension: false,
      cost: false,
    });
    setInputs({
      id: "",
      caller: "",
      did: "",
      duration: "",
      extension: "",
      cost: "",
      startTime: "",
      endTime: "",
    });
  };

  const handleReconnection = async () => {
    let filters = {
      in: {
        start_date: callsReqBody.date_start,
        end_date: callsReqBody.date_end,
        company_id: parseInt(localStorage.getItem("customer_id")),
        id: callsReqBody.id ? `${callsReqBody.id}` : undefined,

        status: callsReqBody.status ? `%${callsReqBody.status}%` : undefined,
        did: callsReqBody.did ? `%${callsReqBody.did}%` : undefined,
        country: callsReqBody.country ? `%${callsReqBody.country}%` : undefined,
        call_date: callsReqBody.call_date
          ? `${callsReqBody.call_date}%`
          : undefined,
        caller: callsReqBody.caller ? `${callsReqBody.caller}` : undefined,
        duration: callsReqBody.duration
          ? `${callsReqBody.duration}`
          : undefined,
        extension: callsReqBody.extension
          ? `${callsReqBody.extension}`
          : undefined,
        cost: callsReqBody.cost ? `%${callsReqBody.cost}%` : undefined,
      },
      out: {
        start_date: callsReqBody.date_start,
        end_date: callsReqBody.date_end,
        company_id: parseInt(localStorage.getItem("customer_id")),
        id: callsReqBody.id ? `${callsReqBody.id}` : undefined,

        status: callsReqBody.status ? `%${callsReqBody.status}%` : undefined,
        did: callsReqBody.did ? `%${callsReqBody.did}%` : undefined,
        country: callsReqBody.country ? `%${callsReqBody.country}%` : undefined,
        call_date: callsReqBody.call_date
          ? `%${callsReqBody.call_date}%`
          : undefined,
        caller: callsReqBody.caller ? `${callsReqBody.caller}` : undefined,
        duration: callsReqBody.duration
          ? `${callsReqBody.duration}`
          : undefined,
        extension: callsReqBody.extension
          ? `${callsReqBody.extension}`
          : undefined,
        cost: callsReqBody.cost ? `%${callsReqBody.cost}%` : undefined,
      },
    };
    if (callsReqBody.feature) {
      if (callsReqBody.feature === "Inbound") {
        delete filters.out;
      } else {
        delete filters.in;
      }
    }
    try {
      const response = await fetchCalls(
        JSON.stringify({
          filters,
          pagination: {
            skip: callsReqBody.skip,
            limit: callsReqBody.limit,
            order_by: [
              {
                column: callsReqBody.item,
                direction: callsReqBody.direction,
              },
            ],
          },
          with_last_outbound_call: false,
          with_indicators: false,
          with_filters: true,
        })
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      const fetchedData = await response.json();

      setFiltersValues((prevData) => ({
        ...prevData,
        countries: fetchedData.filters.countries,
        status: fetchedData.filters.statuses,
      }));

      setCallsReqBody((prevData) => ({
        ...prevData,
        total: fetchedData.total,
      }));

      if (fetchedData.total) {
        const mappedData = fetchedData.data.map((data) => ({
          id: data.id,
          direction: "Inbound",
          date: data.call_date,
          status: data.status,
          did: data.did,
          country: data.country,
          caller: data.caller,
          duration: data.duration,
          user: data.extension,
          cost: data.enduser_cost,
        }));

        setCallsData(mappedData);
      } else
        setCallsData([
          {
            id: null,
            direction: null,
            date: null,
            status: null,
            did: null,
            country: null,
            caller: null,
            duration: null,
            user: null,
            cost: null,
          },
        ]);
    } catch (error) {
      InternalErrorSwal();
    }
  };

  const getCallsData = async () => {
    let filters = {
      in: {
        start_date: callsReqBody.date_start,
        end_date: callsReqBody.date_end,
        company_id: parseInt(localStorage.getItem("customer_id")),
        id: callsReqBody.id ? `${callsReqBody.id}` : undefined,
        status: callsReqBody.status ? `%${callsReqBody.status}%` : undefined,
        did: number,
        country: callsReqBody.country ? `%${callsReqBody.country}%` : undefined,
        call_date: callsReqBody.call_date
          ? `${callsReqBody.call_date}%`
          : undefined,
        caller: callsReqBody.caller ? `${callsReqBody.caller}` : undefined,
        duration: callsReqBody.duration
          ? `${callsReqBody.duration}`
          : undefined,
        extension: callsReqBody.extension
          ? `${callsReqBody.extension}`
          : undefined,
        cost: callsReqBody.cost ? `%${callsReqBody.cost}%` : undefined,
      },

      out: {
        start_date: callsReqBody.date_start,
        end_date: callsReqBody.date_end,
        company_id: parseInt(localStorage.getItem("customer_id")),
        id: callsReqBody.id ? `${callsReqBody.id}` : undefined,

        status: callsReqBody.status ? `%${callsReqBody.status}%` : undefined,
        did: number,
        country: callsReqBody.country ? `%${callsReqBody.country}%` : undefined,
        call_date: callsReqBody.call_date
          ? `%${callsReqBody.call_date}%`
          : undefined,
        caller: callsReqBody.caller ? `${callsReqBody.caller}` : undefined,
        duration: callsReqBody.duration
          ? `${callsReqBody.duration}`
          : undefined,
        extension: callsReqBody.extension
          ? `${callsReqBody.extension}`
          : undefined,
        cost: callsReqBody.cost ? `%${callsReqBody.cost}%` : undefined,
      },
    };
    if (callsReqBody.feature) {
      if (callsReqBody.feature === "Inbound") {
        delete filters.out;
      } else {
        delete filters.in;
      }
    }
    try {
      const response = await fetchCalls(
        JSON.stringify({
          filters,
          pagination: {
            skip: callsReqBody.skip,
            limit: callsReqBody.limit,
            order_by: [
              {
                column: callsReqBody.item,
                direction: callsReqBody.direction,
              },
            ],
          },
          with_last_outbound_call: false,
          with_indicators: false,
          with_filters: true,
        })
      );

      setLoad(true);
      if (!response.ok) {
        throw new Error(response.status);
      }

      const fetchedData = await response.json();

      setFiltersValues((prevData) => ({
        ...prevData,
        countries: fetchedData.filters.countries,
        status: fetchedData.filters.statuses,
      }));

      setCallsReqBody((prevData) => ({
        ...prevData,
        total: fetchedData.total,
      }));

      if (!totalState.initialized) {
        setTotalState({ total: fetchedData.total, initialized: true });
      }

      if (fetchedData.total) {
        const mappedData = fetchedData.data.map((data) => ({
          id: data.id,
          direction: "Inbound",
          date: data.call_date,
          status: data.status,
          did: data.did,
          country: data.country,
          caller: data.caller,
          duration: data.duration,
          user: data.extension,
          cost: data.enduser_cost,
        }));

        setCallsData(mappedData);
      } else
        setCallsData([
          {
            id: null,
            direction: null,
            date: null,
            status: null,
            did: null,
            country: null,
            caller: null,
            duration: null,
            user: null,
            cost: null,
          },
        ]);
    } catch (error) {
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else {
        handleReconnection();
      }
    }
  };

  const [filters, setFilters] = useState({
    id: false,
    feature: false,
    date: false,
    status: false,
    did: false,
    caller: false,
    country: false,
    caller: false,
    duration: false,
    extension: false,
    cost: false,
  });

  const [load, setLoad] = useState(false);

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  const endOfDay = new Date();

  endOfDay.setHours(23, 59, 59, 999);

  const [initialReqBody, setInitialReqBody] = useState({
    skip: 0,
    limit: 5,
    total: null,
    date_start: formatDate(currentDate),
    call_date: "",
    date_end: formatDate(currentDate),
    item: "call_date",
    direction: "desc",
    id: "",
    status: "",
    did: "",
    country: "",
    caller: "",
    duration: "",
    extension: "",
    cost: "",
    feature: "",
  });

  const [callsReqBody, setCallsReqBody] = useState({
    skip: 0,
    limit: 5,
    total: null,
    date_start: formatDate(currentDate),
    call_date: "",
    date_end: formatDate(currentDate),
    item: "call_date",
    direction: "desc",
    id: "",
    status: "",
    did: "",
    country: "",
    caller: "",
    duration: "",
    extension: "",
    cost: "",
    feature: "",
  });

  const handleTimeChange = (startDate, endDate) => {
    if (startDate) {
      if (!callsReqBody.date_start.includes(" ")) {
        setCallsReqBody((prevData) => ({
          ...prevData,
          date_start: `${callsReqBody.date_start} ${startDate}`,
        }));
      } else {
        const [date, time] = callsReqBody.date_start.split(" ");
        setCallsReqBody((prevData) => ({
          ...prevData,
          date_start: `${date} ${startDate}`,
        }));
      }
    }

    if (endDate) {
      if (!callsReqBody.date_end.includes(" ")) {
        setCallsReqBody((prevData) => ({
          ...prevData,
          date_end: `${callsReqBody.date_end} ${endDate}`,
        }));
      } else {
        const [date, time] = callsReqBody.date_end.split(" ");
        setCallsReqBody((prevData) => ({
          ...prevData,
          date_end: `${date} ${endDate}`,
        }));
      }
    }

    if (!filters.date) {
      setFiltersCount(filtersCount + 1);
    }

    setFilters((prevData) => ({ ...prevData, date: true }));
  };

  const handleDateTimeFilterChange = (startDate, endDate) => {
    if (startDate) {
      setCallsReqBody((prevData) => ({
        ...prevData,
        date_start: initialDateTimeFormat(new Date(startDate)),
      }));
    }
    if (endDate) {
      setCallsReqBody((prevData) => ({
        ...prevData,
        date_end: initialDateTimeFormat(new Date(endDate)),
      }));
    }
    if (!filters.date) {
      setFiltersCount(filtersCount + 1);
    }

    setFilters((prevData) => ({ ...prevData, date: true }));
  };

  const fetchDataDirectionFilter = (item, direction, click) => {
    if (sortByClicked != click) {
      setLoad(false);
      setSortByClicked(click);
      setCallsReqBody((prevData) => ({
        ...prevData,
        item: item,
        direction: direction,
      }));
    }
  };

  const handleInputChange = (item, value) => {
    if (callsReqBody[item] != value) {
      setLoad(false);
      setCallsReqBody((prevData) => ({ ...prevData, [item]: inputs[item] }));

      if (value) {
        setFilters((prevData) => ({ ...prevData, [item]: true }));
        if (!filters[item]) {
          setFiltersCount(filtersCount + 1);
        }
      } else {
        setFilters((prevData) => ({ ...prevData, [item]: false }));
        setFiltersCount(filtersCount - 1);
      }
    }
  };

  const handleSelectChange = (item, value) => {
    if (callsReqBody[item] != value) {
      setLoad(false);
      setCallsReqBody((prevData) => ({ ...prevData, [item]: value }));
      if (value) {
        setFilters((prevData) => ({ ...prevData, [item]: true }));
        if (!filters[item]) {
          setFiltersCount(filtersCount + 1);
        }
      } else {
        setFilters((prevData) => ({ ...prevData, [item]: false }));
        setFiltersCount(filtersCount - 1);
      }
    }
  };

  const handleDeleteFilter = (item, value) => {
    setLoad(false);
    setCallsReqBody((prevData) => ({ ...prevData, [item]: value }));
    setInputs((prevData) => ({ ...prevData, [item]: value }));
    setFilters((prevData) => ({ ...prevData, [item]: false }));
    setFiltersCount(filtersCount - 1);
  };

  const handleDeleteTimeFilter = () => {
    setLoad(false);
    setCallsReqBody((prevData) => ({
      ...prevData,
      date_start: initialReqBody.date_start,
      date_end: initialReqBody.date_end,
    }));

    setInputs((prevData) => ({ ...prevData, startTime: "", endTime: "" }));
    setFilters((prevData) => ({ ...prevData, date: false }));
    setFiltersCount(filtersCount - 1);
  };

  const [period, setPeriod] = useState({
    date_start: formatDate(currentDate),
    date_end: formatDate(currentDate),
  });

  const [totalState, setTotalState] = useState({
    total: 0,
    initialized: false,
  });

  useEffect(() => {
    setLoad(false);
    getCallsData();
  }, [
    callsReqBody.skip,
    callsReqBody.limit,
    callsReqBody.date_start,
    callsReqBody.call_date,
    callsReqBody.date_end,
    callsReqBody.id,
    callsReqBody.status,
    callsReqBody.did,
    callsReqBody.country,
    callsReqBody.caller,
    callsReqBody.duration,
    callsReqBody.extension,
    callsReqBody.cost,
    callsReqBody.item,
    callsReqBody.direction,
    callsReqBody.feature,
  ]);

  const [sortByClicked, setSortByClicked] = useState(6);

  const handleInputonChange = (value, item) => {
    setInputs((prevData) => ({ ...prevData, [item]: value }));
  };

  const callsColumns = [
    {
      name: (
        <div className="header-dt">
          <div className="title-dt">
            <span>{t("datatable.call_id")}</span>
            <div className="sort-by-filter">
              <span>
                <svg
                  onClick={() => {
                    fetchDataDirectionFilter("id", "asc", 1);
                  }}
                  className="svg-filters-up-down"
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                  fill={`${sortByClicked === 1 ? "#919191" : "#d9d9d9"}`}
                  transform="rotate(180)"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    <path d="m192 384 320 384 320-384z" />
                  </g>
                </svg>
              </span>
              <svg
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                fill={`${sortByClicked === 2 ? "#919191" : "#d9d9d9"}`}
                className="svg-filters-up-down filter-down"
                onClick={() => {
                  fetchDataDirectionFilter("id", "desc", 2);
                }}
              >
                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="SVGRepo_iconCarrier">
                  <path d="m192 384 320 384 320-384z" />
                </g>
              </svg>
            </div>
          </div>
          <input
            placeholder={t("call_id")}
            className={`listing-modal-search-input-dt ${
              callsReqBody.total < 2 ? " disabled-event" : ""
            }`}
            value={inputs.id}
            onChange={(e) => handleInputonChange(e.target.value, "id")}
            onBlur={(e) => {
              handleInputChange("id", e.target.value);
            }}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/\D/g, "");
            }}
          />
        </div>
      ),
      cell: (row) =>
        load ? <span>{row.id}</span> : <div className="skeleton-dt"></div>,
    },
    {
      name: (
        <div className="header-dt">
          <div className="title-dt">
            <span>{t("datatable.direction")}</span>
            <div className="sort-by-filter">
              <span>
                <svg
                  // onClick={() => {
                  //   fetchDataDirectionFilter("direction", "asc", 3);
                  // }}
                  className="svg-filters-up-down"
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                  fill={`${sortByClicked === 3 ? "#919191" : "#d9d9d9"}`}
                  transform="rotate(180)"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    <path d="m192 384 320 384 320-384z" />
                  </g>
                </svg>
              </span>
              <svg
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                fill={`${sortByClicked === 4 ? "#919191" : "#d9d9d9"}`}
                className="svg-filters-up-down filter-down"
                // onClick={() => {
                //   fetchDataDirectionFilter("direction", "desc", 4);
                // }}
              >
                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="SVGRepo_iconCarrier">
                  <path d="m192 384 320 384 320-384z" />
                </g>
              </svg>
            </div>
          </div>
          
          <select
            className={`listing-modal-search-input-dt ${
              callsReqBody.total < 2 && "disabled-event"
            }`}
            value={callsReqBody.feature}
            onChange={(e) => {
              handleSelectChange("feature", e.target.value);
            }}
          >

            <option selected value="">
              {t("all")}
            </option>
            <option value="Inbound">{t("inbound")}</option>
            <option value="Outbound">{t("outbound")}</option>

          </select>
        </div>
      ),
      cell: (row) =>
        load ? (
          row.direction && (
            <div className="call-direction">
              <img
                src={`/assets/media/icons/callin.svg`}
                alt=""
                className="call-direction-icon"
              />{" "}
              <span>{t(`${row.direction.toLowerCase()}`)}</span>
            </div>
          )
        ) : (
          <div className="skeleton-dt"></div>
        ),
    },
    {
      name: (
        <div className="header-dt">
          <div className="title-dt">
            <span>{t("datatable.date")}</span>
            <div className="sort-by-filter">
              <span>
                <svg
                  onClick={() => {
                    fetchDataDirectionFilter("call_date", "asc", 5);
                  }}
                  className="svg-filters-up-down"
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                  fill={`${sortByClicked === 5 ? "#919191" : "#d9d9d9"}`}
                  transform="rotate(180)"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    <path d="m192 384 320 384 320-384z" />
                  </g>
                </svg>
              </span>
              <svg
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                fill={`${sortByClicked === 6 ? "#919191" : "#d9d9d9"}`}
                className="svg-filters-up-down filter-down"
                onClick={() => {
                  fetchDataDirectionFilter("call_date", "desc", 6);
                }}
              >
                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="SVGRepo_iconCarrier">
                  <path d="m192 384 320 384 320-384z" />
                </g>
              </svg>
            </div>
          </div>

          {period.date_start != period.date_end ? (
            <div className={callsReqBody.total < 2 && "disabled-event"}>
              <DateTimePicker
                handleTimePicker={handleDateTimeFilterChange}
                start_time={inputs.startTime}
                end_time={inputs.endTime}
                changeStartTime={(value) =>
                  setInputs((prevData) => ({ ...prevData, startTime: value }))
                }
                changeEndTime={(value) =>
                  setInputs((prevData) => ({ ...prevData, endTime: value }))
                }
                minDate={period.date_start}
                maxDate={period.date_end}
              />
            </div>
          ) : (
            <div className={callsReqBody.total < 2 && "disabled-event"}>
              <TimerPicker
                handleTimePicker={handleTimeChange}
                start_time={inputs.startTime}
                end_time={inputs.endTime}
                changeStartTime={(value) =>
                  setInputs((prevData) => ({ ...prevData, startTime: value }))
                }
                changeEndTime={(value) =>
                  setInputs((prevData) => ({ ...prevData, endTime: value }))
                }
              />
            </div>
          )}
        </div>
      ),
      cell: (row) =>
        load ? (
          <span> {row.date && defaultDateFormat(row.date)}</span>
        ) : (
          <div className="skeleton-dt"></div>
        ),
    },
    {
      name: (
        <div className="header-dt">
          <div className="title-dt">
            <span>{t("datatable.status")}</span>
            <div className="sort-by-filter">
              <span>
                <svg
                  onClick={() => {
                    fetchDataDirectionFilter("status", "asc", 7);
                  }}
                  className="svg-filters-up-down"
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                  fill={`${sortByClicked === 7 ? "#919191" : "#d9d9d9"}`}
                  transform="rotate(180)"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    <path d="m192 384 320 384 320-384z" />
                  </g>
                </svg>
              </span>
              <svg
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                fill={`${sortByClicked === 8 ? "#919191" : "#d9d9d9"}`}
                className="svg-filters-up-down filter-down"
                onClick={() => {
                  fetchDataDirectionFilter("status", "desc", 8);
                }}
              >
                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="SVGRepo_iconCarrier">
                  <path d="m192 384 320 384 320-384z" />
                </g>
              </svg>
            </div>
          </div>
          <select
            className={`listing-modal-search-input-dt ${
              callsReqBody.total < 2 && "disabled-event"
            }`}
            value={callsReqBody.status}
            onChange={(e) => {
              handleSelectChange("status", e.target.value);
            }}
          >
            <option value="">{t("all")}</option>

            <option value="1">{t("answered")}</option>
            <option value="2">{t("busy")}</option>
            <option value="3">{t("not_answered")}</option>
            {/* <option value="4">{t("canceled")}</option>
            <option value="10">{t("not_confirmed")}</option>
            <option value="11">{t("accepted")}</option>
            <option value="13">{t("rejected")}</option>
            <option value="14">{t("non_billable")}</option> */}
            <option value="7">{t("other")}</option>
          </select>
        </div>
      ),
      cell: (row) =>
        load ? (
          <span
            className={` badge ${
              row.status === 1
                ? "badge-light-succeded"
                : row.status === 3
                ? "badge-light-danger"
                : row.status === 0 || row.status === 2 || row.status === 4
                ? "badge-light-warning"
                : "badge-light-neutral"
            }`}
          >
            {row.status == 0
              ? t("failed")
              : row.status == 1
              ? t("answered")
              : row.status == 2
              ? t("busy")
              : row.status == 3
              ? t("not_answered")
              : row.status == 4
              ? t("canceled")
              : row.status == 7
              ? t("not_confirmed")
              : row.status == 10
              ? t("accepted")
              : row.status == 11
              ? t("rejected")
              : row.status == 13
              ? t("non_billable")
              : row.status == 14
              ? t("other")
              : ""}
          </span>
        ) : (
          <div className="skeleton-dt"></div>
        ),
    },
    {
      name: (
        <div className="header-dt">
          <div className="title-dt">
            <span>{t("datatable.destination")}</span>
            <div className="sort-by-filter">
              <span>
                <svg
                  onClick={() => {
                    fetchDataDirectionFilter("did", "asc", 9);
                  }}
                  className="svg-filters-up-down"
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                  fill={`${sortByClicked === 9 ? "#919191" : "#d9d9d9"}`}
                  transform="rotate(180)"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    <path d="m192 384 320 384 320-384z" />
                  </g>
                </svg>
              </span>
              <svg
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                fill={`${sortByClicked === 10 ? "#919191" : "#d9d9d9"}`}
                className="svg-filters-up-down filter-down"
                onClick={() => {
                  fetchDataDirectionFilter("did", "desc", 10);
                }}
              >
                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="SVGRepo_iconCarrier">
                  <path d="m192 384 320 384 320-384z" />
                </g>
              </svg>
            </div>
          </div>
          <input
            className={`listing-modal-search-input-dt opacity-0 pointer-event-none`}
            placeholder={t("destination")}
            value={inputs.did}
            onChange={(e) => handleInputonChange(e.target.value, "did")}
            onBlur={(e) => {
              handleInputChange("did", e.target.value);
            }}
          ></input>
        </div>
      ),
      cell: (row) =>
        load ? <span>{row.did}</span> : <div className="skeleton-dt"></div>,
    },
    {
      name: (
        <div className="header-dt">
          <div className="title-dt">
            <span>{t("datatable.country")}</span>
            <div className="sort-by-filter">
              <span>
                <svg
                  onClick={() => {
                    fetchDataDirectionFilter("country", "asc", 11);
                  }}
                  className="svg-filters-up-down"
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                  fill={`${sortByClicked === 11 ? "#919191" : "#d9d9d9"}`}
                  transform="rotate(180)"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    <path d="m192 384 320 384 320-384z" />
                  </g>
                </svg>
              </span>
              <svg
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                fill={`${sortByClicked === 12 ? "#919191" : "#d9d9d9"}`}
                className="svg-filters-up-down filter-down"
                onClick={() => {
                  fetchDataDirectionFilter("country", "desc", 12);
                }}
              >
                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="SVGRepo_iconCarrier">
                  <path d="m192 384 320 384 320-384z" />
                </g>
              </svg>
            </div>
          </div>
          <select
            name=""
            value={callsReqBody.country}
            className={`listing-modal-search-input-dt ${
              callsReqBody.total < 2 && "disabled-event"
            }`}
            onChange={(e) => {
              handleSelectChange("country", e.target.value);
            }}
          >
            <option value="">{t("all")}</option>

            {filtersValues.countries.map((countryCode) => {
              const country = countriesData.find((c) => c.code === countryCode);
              return (
                <option key={countryCode} value={countryCode}>
                  {country ? country.name : countryCode}
                </option>
              );
            })}
          </select>
        </div>
      ),
      cell: (row) =>
        load ? (
          row.country ? (
            <span>
              <div className="d-flex align-items-center">
                <div className="me-2 position-relative">
                  <img
                    className="height-1 width-1-6 obj-fit-cover flag-style"
                    alt={row.country}
                    src={`/assets/media/flags/${row.country.toLowerCase()}.svg`}
                  />
                </div>
                <div className="d-flex flex-column justify-content-center">
                  <div className="fw-semibold fs-6 text-gray-400">
                    {t(`countries.${row.country}`)}
                  </div>
                </div>
              </div>
            </span>
          ) : (
            ""
          )
        ) : (
          <div className="skeleton-dt"></div>
        ),
    },
    {
      name: (
        <div className="header-dt">
          <div className="title-dt">
            <span>{t("datatable.caller_id")}</span>
            <div className="sort-by-filter">
              <span>
                <svg
                  onClick={() => {
                    fetchDataDirectionFilter("caller", "asc", 13);
                  }}
                  className="svg-filters-up-down"
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                  fill={`${sortByClicked === 13 ? "#919191" : "#d9d9d9"}`}
                  transform="rotate(180)"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    <path d="m192 384 320 384 320-384z" />
                  </g>
                </svg>
              </span>
              <svg
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                fill={`${sortByClicked === 14 ? "#919191" : "#d9d9d9"}`}
                className="svg-filters-up-down filter-down"
                onClick={() => {
                  fetchDataDirectionFilter("caller", "desc", 14);
                }}
              >
                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="SVGRepo_iconCarrier">
                  <path d="m192 384 320 384 320-384z" />
                </g>
              </svg>
            </div>
          </div>
          <input
            className={`listing-modal-search-input-dt ${
              callsReqBody.total < 2 && "disabled-event"
            }`}
            placeholder={t("caller_id")}
            value={inputs.caller}
            onChange={(e) => handleInputonChange(e.target.value, "caller")}
            onBlur={(e) => {
              handleInputChange("caller", e.target.value);
            }}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/\D/g, "");
            }}
          ></input>
        </div>
      ),
      cell: (row) =>
        load ? <span>{row.caller}</span> : <div className="skeleton-dt"></div>,
    },
    {
      name: (
        <div className="header-dt">
          <div className="title-dt">
            <span>{t("datatable.duration")}</span>
            <div className="sort-by-filter">
              <span>
                <svg
                  onClick={() => {
                    fetchDataDirectionFilter("duration", "asc", 15);
                  }}
                  className="svg-filters-up-down"
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                  fill={`${sortByClicked === 15 ? "#919191" : "#d9d9d9"}`}
                  transform="rotate(180)"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    <path d="m192 384 320 384 320-384z" />
                  </g>
                </svg>
              </span>
              <svg
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                fill={`${sortByClicked === 16 ? "#919191" : "#d9d9d9"}`}
                className="svg-filters-up-down filter-down"
                onClick={() => {
                  fetchDataDirectionFilter("duration", "desc", 16);
                }}
              >
                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="SVGRepo_iconCarrier">
                  <path d="m192 384 320 384 320-384z" />
                </g>
              </svg>
            </div>
          </div>
          <input
            placeholder={t("duration")}
            className={`listing-modal-search-input-dt ${
              callsReqBody.total < 2 && "disabled-event"
            }`}
            value={inputs.duration}
            onChange={(e) => handleInputonChange(e.target.value, "duration")}
            onBlur={(e) => {
              handleInputChange("duration", e.target.value);
            }}
          ></input>
        </div>
      ),
      cell: (row) =>
        load ? (
          <span>
            {row.duration ? formatDuration(row.duration) : row.duration}
          </span>
        ) : (
          <div className="skeleton-dt"></div>
        ),
    },
    {
      name: (
        <div className="header-dt">
          <div className="title-dt">
            <span>{t("datatable.user")}</span>
            <div className="sort-by-filter">
              <span>
                <svg
                  onClick={() => {
                    fetchDataDirectionFilter("extension", "asc", 17);
                  }}
                  className="svg-filters-up-down"
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                  fill={`${sortByClicked === 17 ? "#919191" : "#d9d9d9"}`}
                  transform="rotate(180)"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    <path d="m192 384 320 384 320-384z" />
                  </g>
                </svg>
              </span>
              <svg
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                fill={`${sortByClicked === 18 ? "#919191" : "#d9d9d9"}`}
                className="svg-filters-up-down filter-down"
                onClick={() => {
                  fetchDataDirectionFilter("extension", "desc", 18);
                }}
              >
                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="SVGRepo_iconCarrier">
                  <path d="m192 384 320 384 320-384z" />
                </g>
              </svg>
            </div>
          </div>
          <input
            className={`listing-modal-search-input-dt ${
              callsReqBody.total < 2 && "disabled-event"
            }`}
            placeholder={t("user")}
            value={inputs.extension}
            onChange={(e) => handleInputonChange(e.target.value, "extension")}
            onBlur={(e) => {
              handleInputChange("extension", e.target.value);
            }}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/\D/g, "");
            }}
          ></input>
        </div>
      ),
      cell: (row) =>
        load ? <span>{row.user}</span> : <div className="skeleton-dt"></div>,
    },
    {
      name: (
        <div className="header-dt">
          <div className="title-dt">
            <span>{t("datatable.cost")}</span>
            <div className="sort-by-filter">
              <span>
                <svg
                  onClick={() => {
                    fetchDataDirectionFilter("enduser_cost", "asc", 19);
                  }}
                  className="svg-filters-up-down"
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                  fill={`${sortByClicked === 19 ? "#919191" : "#d9d9d9"}`}
                  transform="rotate(180)"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    <path d="m192 384 320 384 320-384z" />
                  </g>
                </svg>
              </span>
              <svg
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                fill={`${sortByClicked === 20 ? "#919191" : "#d9d9d9"}`}
                className="svg-filters-up-down filter-down"
                onClick={() => {
                  fetchDataDirectionFilter("enduser_cost", "desc", 20);
                }}
              >
                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="SVGRepo_iconCarrier">
                  <path d="m192 384 320 384 320-384z" />
                </g>
              </svg>
            </div>
          </div>
          <input
            placeholder={t("cost")}
            className={`listing-modal-search-input-dt ${
              callsReqBody.total < 2 && "disabled-event"
            }`}
            value={inputs.cost}
            onChange={(e) => handleInputonChange(e.target.value, "cost")}
            onBlur={(e) => {
              handleInputChange("cost", e.target.value);
            }}
          ></input>
        </div>
      ),
      cell: (row) =>
        load ? <span>{row.cost}</span> : <div className="skeleton-dt"></div>,
    },
  ];

  const [callsData, setCallsData] = useState([
    {
      id: null,
      direction: null,
      date: null,
      status: null,
      did: null,
      country: null,
      caller: null,
      duration: null,
      user: null,
      cost: null,
    },
  ]);

  // Period filters
  // -----

  const setDateToToday = () => {
    resetFilters();
    setActiveBtn(1);
    setPeriod({
      date_start: formatDate(new Date()),
      date_end: formatDate(new Date()),
    });
    setInitialReqBody((prevData) => ({
      ...prevData,
      date_start: formatDate(new Date()),
      date_end: formatDate(new Date()),
      skip: 0,
      id: "",
      status: "",
      did: "",
      country: "",
      caller: "",
      duration: "",
      extension: "",
      cost: "",
      feature: "",
    }));

    setCallsReqBody((prevData) => ({
      ...prevData,
      date_start: formatDate(new Date()),
      date_end: formatDate(new Date()),
      skip: 0,
      id: "",
      status: "",
      did: "",
      country: "",
      caller: "",
      duration: "",
      extension: "",
      cost: "",
      feature: "",
    }));
  };

  const setDateToYesterday = () => {
    setActiveBtn(2);

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    setPeriod({
      date_start: formatDate(yesterday),
      date_end: formatDate(yesterday),
    });

    setInitialReqBody((prevData) => ({
      ...prevData,
      date_start: formatDate(yesterday),
      date_end: formatDate(yesterday),
      skip: 0,
      id: "",
      status: "",
      did: "",
      country: "",
      caller: "",
      duration: "",
      extension: "",
      cost: "",
      feature: "",
    }));

    setCallsReqBody((prevData) => ({
      ...prevData,
      date_start: formatDate(yesterday),
      date_end: formatDate(yesterday),
      skip: 0,
      id: "",
      status: "",
      did: "",
      country: "",
      caller: "",
      duration: "",
      extension: "",
      cost: "",
      feature: "",
    }));
    resetFilters();
  };

  const setDateToCurrentMonth = () => {
    setActiveBtn(3);
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    setPeriod({
      date_start: formatDate(startOfMonth),
      date_end: formatDate(today),
    });

    setInitialReqBody((prevData) => ({
      ...prevData,
      date_start: formatDate(startOfMonth),
      date_end: formatDate(today),
      skip: 0,
      id: "",
      status: "",
      did: "",
      country: "",
      caller: "",
      duration: "",
      extension: "",
      cost: "",
      feature: "",
    }));

    setCallsReqBody((prevData) => ({
      ...prevData,
      date_start: formatDate(startOfMonth),
      date_end: formatDate(today),
      skip: 0,
      id: "",
      status: "",
      did: "",
      country: "",
      caller: "",
      duration: "",
      extension: "",
      cost: "",
      feature: "",
    }));

    resetFilters();
  };

  const setDateToPreviousMonth = () => {
    setActiveBtn(4);
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastDayOfPreviousMonth = new Date(
      today.getFullYear(),
      today.getMonth(),
      0
    );
    setPeriod({
      date_start: formatDate(startOfMonth),
      date_end: formatDate(lastDayOfPreviousMonth),
    });

    setInitialReqBody((prevData) => ({
      ...prevData,
      date_start: formatDate(startOfMonth),
      date_end: formatDate(lastDayOfPreviousMonth),
      skip: 0,
      id: "",
      status: "",
      did: "",
      country: "",
      caller: "",
      duration: "",
      extension: "",
      cost: "",
      feature: "",
    }));

    setCallsReqBody((prevData) => ({
      ...prevData,
      date_start: formatDate(startOfMonth),
      date_end: formatDate(lastDayOfPreviousMonth),
      skip: 0,
      id: "",
      status: "",
      did: "",
      country: "",
      caller: "",
      duration: "",
      extension: "",
      cost: "",
      feature: "",
    }));

    resetFilters();
  };

  // -----

  const handleNextPage = () => {
    if (callsReqBody.skip + callsReqBody.limit < callsReqBody.total) {
      setCallsReqBody((prevData) => ({
        ...prevData,
        skip: prevData.skip + prevData.limit,
      }));
    }
  };

  const handlePrevioustPage = () => {
    if (!callsReqBody.skip == 0) {
      setCallsReqBody((prevData) => ({
        ...prevData,
        skip: prevData.skip - prevData.limit,
      }));
    }
  };

  const formatDuration = (durationInSeconds) => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = durationInSeconds % 60;

    const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  const setLimitFunction = (newLimit) => {
    return new Promise((resolve, reject) => {
      try {
        setCallsReqBody((prevData) => ({
          ...prevData,
          limit: newLimit,
          skip: 0,
        }));
        resolve("Limit set successfully");
      } catch (error) {
        reject(`Error setting limit: ${error.message}`);
      }
    });
  };

  const handleRowsPerPageChange = async (newLimit, currentPage) => {
    try {
      await setLimitFunction(newLimit);
    } catch (error) {
      console.error(error);
    }
  };

  const [activeBtn, setActiveBtn] = useState(1);

  const [filtersCount, setFiltersCount] = useState(0);

  const mapCountryCodeToName = (code) => {
    const country = countriesData.find((country) => country.code === code);
    return country ? country.name : code;
  };

  const handleClearFilters = () => {
    setCallsReqBody(initialReqBody);
    setInputs({
      id: "",
      caller: "",
      did: "",
      duration: "",
      extension: "",
      cost: "",
      startTime: "",
      endTime: "",
    });
    setFiltersCount(0);
    resetFilters();
  };

  const [divShow, setDivShow] = useState(1);

  return (
    <div>
      <div className={`modal-container calls-sms-modal fade-in-down`}>
        <div className="modal-show">
          <div className="modal-header modal-header-bg p-6">
            <div className="d-flex flex-column gap-4">
              <h3>{t("calls_sms_list")}</h3>
              <div className="d-flex flex-wrap fw-semibold fs-6 gap-2">
                {name && (
                  <div className="d-flex align-items-center text-gray-900 header-badge">
                    <span className="me-1">
                      <svg
                        height="13px"
                        width="13px"
                        version="1.1"
                        id="_x32_"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 512 512"
                        xmlSpace="preserve"
                        fill="#b9c4d1"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <style
                            type="text/css"
                            dangerouslySetInnerHTML={{
                              __html: " .st0{fill:;} ",
                            }}
                          />{" "}
                          <g>
                            {" "}
                            <path
                              className="st0"
                              d="M239.732,12.188L0,0l12.176,239.742l42.29,42.29l-9.192-2.301c-9.079,37.239-12.986,70.45-12.986,99.069 c0,33.794,5.399,60.91,14.827,80.798c4.713,9.877,10.572,17.924,17.699,23.906c7.015,5.97,15.746,9.652,24.938,9.652 c8.732,0,17.239-3.333,24.826-8.855c7.475-5.511,14.366-13.322,20.572-23.322c10.338-16.667,16.78-38.384,20.922-63.333 c0.684-4.018,1.256-8.037,1.828-12.178l-20.909-20.92c-0.808,10.236-1.952,20.112-3.568,29.427 c-3.794,23.098-10.001,42.402-17.7,54.816c-5.162,8.158-10.224,13.794-14.703,17.014c-4.602,3.334-8.159,4.254-11.268,4.366 c-3.22-0.113-6.319-1.145-10.224-4.254c-5.746-4.714-12.188-14.59-16.78-29.417c-4.602-14.826-7.475-34.253-7.475-57.7 c0-24.366,2.986-53.21,10.461-85.499l71.257,71.246c1.493-15.399,2.065-31.718,2.065-48.384c0-55.274-7.015-114.12-16.318-153.996 c-10.573,0.224-21.257-3.794-29.304-11.841c-15.635-15.746-15.635-41.144,0-56.891c15.746-15.746,41.144-15.746,56.892,0 c15.735,15.747,15.735,41.145,0,56.891c-1.841,1.728-3.682,3.334-5.746,4.714c3.333,13.558,6.206,28.956,8.731,45.623 c5.286,35.286,8.732,76.083,8.732,115.5c0,24.142-1.269,47.7-4.142,69.305L284.446,512L512,284.446L239.732,12.188z"
                            />{" "}
                            <path
                              className="st0"
                              d="M143.996,152.515c-3.558-14.142-7.352-26.094-11.718-35.523l-20.808,9.776 c3.333,7.126,6.903,18.036,10.236,31.258c0.348,1.38,0.685,2.76,1.033,4.141c7.586-0.123,15.285-2.537,21.841-7.127 C144.456,154.232,144.232,153.311,143.996,152.515z"
                            />{" "}
                          </g>{" "}
                        </g>
                      </svg>
                    </span>
                    {name}
                  </div>
                )}
                <div className="d-flex align-items-center text-gray-900 header-badge">
                  <span className="svg-icon svg-icon-4 me-1">
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 21C6 21.6 6.4 22 7 22H17C17.6 22 18 21.6 18 21V20H6V21Z"
                        fill="currentColor"
                      />
                      <path
                        opacity="0.3"
                        d="M17 2H7C6.4 2 6 2.4 6 3V20H18V3C18 2.4 17.6 2 17 2Z"
                        fill="currentColor"
                      />
                      <path
                        d="M12 4C11.4 4 11 3.6 11 3V2H13V3C13 3.6 12.6 4 12 4Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  {number}
                </div>
                <div className="d-flex align-items-center text-gray-900 header-badge">
                  <div className="symbol symbol-20px symbol-circle d-flex align-items-center gap-1">
                    <img
                      alt="Pic"
                      src={`assets/media/flags/${country}.svg`}
                      className="w-15px h-15px"
                    />
                    <span>
                      {country &&
                        country !== "unknown" &&
                        t(`countries.${country.toUpperCase()}`)}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              onClick={() => closeModal()}
            >
              <span className="svg-icon svg-icon-1">
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.5"
                    x={6}
                    y="17.3137"
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  />
                  <rect
                    x="7.41422"
                    y={6}
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
          </div>

          <div className="p-6 listing-dt-h position-relative">
            <div className="d-flex pb-6">
              <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold">
                <li className="nav-item">
                  <span
                    href="#kt_calls"
                    className={`nav-link text-active-primary ${
                      divShow === 1 && "active show"
                    }`}
                    data-bs-toggle="tab"
                    onClick={() => setDivShow(1)}
                  >
                    {t("calls")}
                  </span>
                </li>

                <li className="nav-item">
                  <span
                    className={`nav-link text-active-primary pb-4 ${
                      divShow === 2 && "active show"
                    } ${!sms && "disabled-event"}`}
                    onClick={() => setDivShow(2)}
                    data-kt-countup-tabs="true"
                    data-bs-toggle="tab"
                  >
                    {t("sms")}{" "}
                  </span>
                </li>
              </ul>
              <div className="nav-item ms-auto">
                <div
                  className={`d-flex align-items-center py-2 ${
                    divShow === 2 && "display-none"
                  }`}
                >
                  <span
                    className={`btn btn-sm btn-flex ${
                      activeBtn === 1 ? "btn-primary-active" : "period"
                    } fw-bold me-3`}
                    onClick={() => setDateToToday()}
                  >
                    {t("today")}
                  </span>
                  <span
                    className={`btn btn-sm btn-flex ${
                      activeBtn === 2 ? "btn-primary-active" : "period"
                    } fw-bold me-3`}
                    onClick={() => setDateToYesterday()}
                  >
                    {t("yesterday")}
                  </span>
                  <span
                    className={`btn btn-sm btn-flex ${
                      activeBtn === 3 ? "btn-primary-active" : "period"
                    } fw-bold me-3`}
                    onClick={() => setDateToCurrentMonth()}
                  >
                    {t("current_month")}
                  </span>
                  <span
                    className={`btn btn-sm btn-flex ${
                      activeBtn === 4 ? "btn-primary-active" : "period"
                    } fw-bold me-3`}
                    onClick={() => setDateToPreviousMonth()}
                  >
                    {t("previous_month")}
                  </span>
                  <span
                    className={`btn btn-sm btn-flex position-relative ${
                      activeBtn === 5 ? "btn-primary-active" : "period"
                    } fw-bold`}
                  >
                    {t("custom")}{" "}
                    {activeBtn === 5 ? (
                      <span>
                        {" "}
                        &#160; | &#160; {callsReqBody.date_start} -{" "}
                        {callsReqBody.date_end}
                      </span>
                    ) : (
                      ""
                    )}
                    <DatePicker
                      onChange={(e) => {
                        if (e.length > 1) {
                          setActiveBtn(5);
                          setInitialReqBody((prevData) => ({
                            ...prevData,
                            date_start: formatDate(e[0]),
                            date_end: formatDate(e[1]),
                          }));

                          setPeriod({
                            date_start: formatDate(e[0]),
                            date_end: formatDate(e[1]),
                          });

                          setCallsReqBody((prevData) => ({
                            ...prevData,
                            date_start: formatDate(e[0]),
                            date_end: formatDate(e[1]),
                          }));

                          setLoad(false);
                        }
                      }}
                      date={[]}
                    />
                  </span>
                </div>
              </div>
            </div>

            {filtersCount > 0 && (
              <div className="all-filters mb-3">
                <div className="filters-tag">
                  <img
                    className="filters-icon"
                    src="/assets/media/icons/filters-icon.png"
                  />
                  <span className="filters-title">{t("titles.filters")}:</span>
                </div>
                <div className="filters-container">
                  {filters.id && (
                    <div className="dt-filters-list">
                      <div className="td-filter">
                        <img
                          className="filter-img"
                          src="/assets/media/icons/call-id-icon.png"
                        />
                        <span>
                          {t("datatable.call_id")}: {callsReqBody.id}
                        </span>
                        <span
                          className="delete-filter-btn"
                          onClick={() => handleDeleteFilter("id", "")}
                        >
                          <img
                            className="x-close"
                            src="/assets/media/icons/x-close.png"
                          />
                        </span>
                      </div>
                    </div>
                  )}
                  {filters.feature && (
                    <div className="dt-filters-list">
                      <div className="td-filter">
                        <img
                          src="/assets/media/icons/oposite-arrows.png"
                          className="filter-img"
                        />
                        <span>
                          {t("titles.direction")}:{" "}
                          {callsReqBody.feature === "Inbound"
                            ? t("inbound")
                            : callsReqBody.feature === "Outbound"
                            ? t("outbound")
                            : callsReqBody.feature}
                        </span>
                        <span
                          className={`delete-filter-btn 
                        `}
                          onClick={() => handleDeleteFilter("feature", "")}
                        >
                          <img
                            className="x-close"
                            src="/assets/media/icons/x-close.png"
                          />
                        </span>
                      </div>
                    </div>
                  )}
                  {filters.date && (
                    <div className="dt-filters-list">
                      <div className="td-filter">
                        <img
                          src="/assets/media/icons/date-icon.png"
                          className="filter-img"
                        />
                        <span>
                          {t("titles.date")}:{" "}
                          {callsReqBody.date_start.includes(" ")
                            ? convertedDate(callsReqBody.date_start)
                            : `${defaultOnlyDateFormat(
                                callsReqBody.date_start
                              )} 00:00`}{" "}
                          {t("to")}{" "}
                          {callsReqBody.date_end.includes(" ")
                            ? convertedDate(callsReqBody.date_end)
                            : `${defaultOnlyDateFormat(
                                callsReqBody.date_end
                              )} 23:59`}
                        </span>{" "}
                        <span
                          className={`delete-filter-btn 
                         
                        `}
                          onClick={() => handleDeleteTimeFilter()}
                        >
                          <img
                            className="x-close"
                            src="/assets/media/icons/x-close.png"
                          />
                        </span>
                      </div>
                    </div>
                  )}
                  {filters.status && (
                    <div className="dt-filters-list">
                      <div className="td-filter">
                        <img
                          src="/assets/media/icons/status-icon.png"
                          className="filter-img"
                        />
                        <span>
                          {t("titles.status")}:{" "}
                          {callsReqBody.status == 0
                            ? t("failed")
                            : callsReqBody.status == 1
                            ? t("answered")
                            : callsReqBody.status == 2
                            ? t("busy")
                            : callsReqBody.status == 3
                            ? t("not_answered")
                            : callsReqBody.status == 4
                            ? t("canceled")
                            : callsReqBody.status == 10
                            ? t("not_confirmed")
                            : callsReqBody.status == 11
                            ? t("accepted")
                            : callsReqBody.status == 13
                            ? t("rejected")
                            : callsReqBody.status == 14
                            ? t("non_billable")
                            : callsReqBody.status == 7
                            ? t("other")
                            : callsReqBody.status}
                        </span>{" "}
                        <span
                          className={`delete-filter-btn 
                        `}
                          onClick={() => handleDeleteFilter("status", "")}
                        >
                          <img
                            className="x-close"
                            src="/assets/media/icons/x-close.png"
                          />
                        </span>
                      </div>
                    </div>
                  )}
                  {filters.did && (
                    <div className="dt-filters-list">
                      <div className="td-filter">
                        <img
                          src="/assets/media/icons/call-in-icon.png"
                          className="filter-img"
                        />
                        <span>
                          {" "}
                          {t("datatable.destination")}: {callsReqBody.did}
                        </span>
                        <span
                          className={`delete-filter-btn  `}
                          onClick={() => handleDeleteFilter("did", "")}
                        >
                          <img
                            className="x-close"
                            src="/assets/media/icons/x-close.png"
                          />
                        </span>
                      </div>
                    </div>
                  )}
                  {filters.country && (
                    <div className="dt-filters-list">
                      <div className="td-filter">
                        <img
                          src="/assets/media/icons/country-icon.png"
                          className="filter-img"
                        />
                        <span>
                          {" "}
                          {t("datatable.country")}:{" "}
                          {mapCountryCodeToName(callsReqBody.country)}
                        </span>{" "}
                        <span
                          className="delete-filter-btn"
                          onClick={() => handleDeleteFilter("country", "")}
                        >
                          <img
                            className="x-close"
                            src="/assets/media/icons/x-close.png"
                          />
                        </span>
                      </div>
                    </div>
                  )}
                  {filters.caller && (
                    <div className="dt-filters-list">
                      <div className="td-filter">
                        <img
                          src="/assets/media/icons/caller-id-icon.png"
                          className="filter-img"
                        />
                        <span>
                          {t("datatable.caller_id")}: {callsReqBody.caller}
                        </span>
                        <span
                          className="delete-filter-btn"
                          onClick={() => handleDeleteFilter("caller", "")}
                        >
                          <img
                            className="x-close"
                            src="/assets/media/icons/x-close.png"
                          />
                        </span>
                      </div>
                    </div>
                  )}
                  {filters.duration && (
                    <div className="dt-filters-list">
                      <div className="td-filter">
                        <img
                          src="/assets/media/icons/chrono-icon.png"
                          className="filter-img"
                        />
                        <span>
                          {t("datatable.duration")}: {callsReqBody.duration}
                        </span>
                        <span
                          className="delete-filter-btn"
                          onClick={() => handleDeleteFilter("duration", "")}
                        >
                          <img
                            className="x-close"
                            src="/assets/media/icons/x-close.png"
                          />
                        </span>
                      </div>
                    </div>
                  )}
                  {filters.extension && (
                    <div className="dt-filters-list">
                      <div className="td-filter">
                        <img
                          src="/assets/media/icons/user-icon.png"
                          className="filter-img"
                        />
                        <span>
                          {t("datatable.user")}: {callsReqBody.extension}
                        </span>{" "}
                        <span
                          className="delete-filter-btn"
                          onClick={() => handleDeleteFilter("extension", "")}
                        >
                          <img
                            className="x-close"
                            src="/assets/media/icons/x-close.png"
                          />
                        </span>
                      </div>
                    </div>
                  )}
                  {filters.cost && (
                    <div className="dt-filters-list">
                      <div className="td-filter">
                        <img
                          src="/assets/media/icons/cost-icon.png"
                          className="filter-img"
                        />
                        <span>
                          {" "}
                          {t("datatable.cost")}: {callsReqBody.cost}
                        </span>{" "}
                        <span
                          className="delete-filter-btn"
                          onClick={() => handleDeleteFilter("cost", "")}
                        >
                          <img
                            className="x-close"
                            src="/assets/media/icons/x-close.png"
                          />
                        </span>
                      </div>
                    </div>
                  )}
                  {filtersCount > 1 && (
                    <div
                      className="clear-all"
                      onClick={() => handleClearFilters()}
                    >
                      {t("clear_all")}
                    </div>
                  )}
                </div>
              </div>
            )}
            <div
              className={`card-body mrg-11 pt-0 position-relative ${
                divShow !== 1 && "display-none"
              }`}
            >
              <div className="calls-sms-dt-header-color"></div>
              <div className="calls-sms-header-bar"></div>

              <DataTable
                className="table align-middle table-row-dashed fs-6 fw-bold gy-5 datatable-style position-relative"
                columns={callsColumns}
                data={callsData}
                paginationPerPage={5}
                {...(callsReqBody.total > 5 && {
                  pagination: true,
                  paginationRowsPerPageOptions: [5],
                })}
                fixedHeader
              ></DataTable>
            </div>
            <div
              className={`card-body mrg-11 pt-0 position-relative ${
                divShow !== 2 && "display-none"
              }`}
            >
              {/* <div className="card coming-soon-3">
                <div className="container py-5 px-4 px-lg-5 my-auto">
                  <div
                    className="row py-5 py-sm-4"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div className="col-12 text-center mx-auto">
                      <h1 className="text-9 text-white bg-danger d-inline-block fw-700 rounded px-3 py-2 mb-4">
                        {t("coming_soon")}!
                      </h1>
                      <h2 className="text-15 fw-600 mb-4 margin-top-bottom">
                        {t("feature_soon")}
                      </h2>
                    </div>
                    <div className="mx-auto text-center">
                      <span className="text-5 text-muted">
                        {t("new_start_soon")}
                      </span>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            {callsReqBody.total === 0 && load && (
              <div
                className={`modal-empty-calls${divShow == 2 ? " d-none" : ""}`}
              >
                {t("no_calls_found")}
              </div>
            )}
            {callsReqBody.total > 5 ? (
              <div
                className={`modal-listing-dt-filter${
                  divShow == 2 ? " d-none" : ""
                }`}
              >
                <span className="rows-per-page">
                  {callsReqBody.total == 0
                    ? 0
                    : callsReqBody.skip + 1
                    ? callsReqBody.skip + 1
                    : 1}
                  -
                  {callsReqBody.skip + callsReqBody.limit > callsReqBody.total
                    ? callsReqBody.total
                    : callsReqBody.skip + callsReqBody.limit}{" "}
                  {t("of")} {callsReqBody.total}
                </span>
                <div className="dt-filter-buttons">
                  <div
                    className="datatable-button"
                    onClick={handlePrevioustPage}
                  >
                    <svg
                      className="svg-filters"
                      fill={!callsReqBody.skip == 0 ? "#000000" : "#aaaaaa"}
                      height="200px"
                      width="200px"
                      version="1.1"
                      id="XMLID_54_"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 24 24"
                      xmlSpace="preserve"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <g id="previous">
                          {" "}
                          <g>
                            {" "}
                            <polygon points="17.2,23.7 5.4,12 17.2,0.3 18.5,1.7 8.4,12 18.5,22.3 " />{" "}
                          </g>{" "}
                        </g>{" "}
                      </g>
                    </svg>
                  </div>
                  <div class="datatable-button" onClick={handleNextPage}>
                    <svg
                      className="svg-filters"
                      fill={
                        callsReqBody.skip + callsReqBody.limit <
                        callsReqBody.total
                          ? "#000000"
                          : "#aaaaaa"
                      }
                      height="200px"
                      width="200px"
                      version="1.1"
                      id="XMLID_287_"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 24 24"
                      xmlSpace="preserve"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <g id="next">
                          {" "}
                          <g>
                            {" "}
                            <polygon points="6.8,23.7 5.4,22.3 15.7,12 5.4,1.7 6.8,0.3 18.5,12 " />{" "}
                          </g>{" "}
                        </g>{" "}
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          {/* <div className="d-flex justify-content-end p-6 footer-border-top">
            <SecondaryButton
              title={t("close")}
              onClick={() => {
                closeModal();
              }}
            />
          </div> */}
        </div>
      </div>
      <div
        className="modal-hide-div"
        // onClick={() => {
        //   closeModal();
        // }}
      ></div>
    </div>
  );
};

export default CallsAndSmsModal;
