export const fetchPaymentDetails = async (body) => {
  const token = localStorage.getItem("token");
  const url = `${process.env.REACT_APP_BASE_URL}/interface/payment/details`;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return response;
};
