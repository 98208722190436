const getTickets = async () => {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/support/tickets?with_terms=true&with_connections=true&orderBy=id&sort=desc`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  };
  
  export default getTickets;
  