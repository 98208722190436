const getComments = async (ticket_id) => {
  return await fetch(
    `${process.env.REACT_APP_BASE_URL}/support/tickets/comments?limit=100&with_connections=true`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({ ticket_id }),
    }
  );
};

export default getComments;
