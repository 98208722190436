import React, { useEffect, useRef, useState } from "react";

import { Link } from "react-router-dom";
import Spinner from "../components/Spinner";
import { useTranslation } from "react-i18next";
import SpinnerSecondary from "../components/SpinnerSecondary";

const UpdatePassword = () => {
  const { t } = useTranslation();
  const [load, setLoad] = useState(false);
  const [updateResponse, setUpdatePassword] = useState(0);

  const [reqError, setReqError] = useState(null);

  const [spinnerLoad, setSpinnerLoad] = useState(false);

  useEffect(() => {
    Promise.all([
      import("./Style/Login.css"),
      import("./Style/bootstrap.min.css"),
      import("./Style/all.min.css"),
    ]).then(() => setLoad(true));
  }, []);

  const [data, setData] = useState({ password: "", password_confirmation: "" });
  const handleInputChange = (e) => {

    
    if (reqError) {
      setReqError(null);
    }
    const { name, value } = e.target;
    name==="password_confirmation"&&error&&setError(false)
    setData((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };
  const params = window.Object.fromEntries(
    new URLSearchParams(window.location.search).entries()
  );
  const handleChangePassword = async () => {
     
        try {
          setSpinnerLoad(true);
          const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/resources/users/reset`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${process.env.REACT_APP_PUBLIC_TOKEN}`,
              },
              body: JSON.stringify({
                email: `${params.mail}`,
                new_password: `${data.password}`,
                code: parseInt(params.requestid),
              }),
            }
          );
          setSpinnerLoad(false);
          if (!response.ok) {
            const error = await response.json();
            throw new Error(error.code);
          }
          setUpdatePassword(1);
        } catch (error) {
          if (error.message === "EXPIRED_DATE") {
            setReqError("errors.expired_date");
          } else if (error.message === "IS_SAME_PASSWORD") {
            setReqError("errors.same_pw");
          } else {
            setReqError("errors.500");
          }
        }
     
   
  };

  const [menuLang, setMenuLang] = useState(false);
  const [selectedLang, setSelectedLang] = useState(
    localStorage.getItem("i18nextLng")
  );

  const { i18n } = useTranslation();

  const handleLangChange = (item) => {
    setSelectedLang(item);
    setMenuLang(false);
    localStorage.setItem("i18nextLng", item);
    i18n.changeLanguage(item);
  };

  const menuRef = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuLang(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [menuRef]);

  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    symbol: false,
  });

  const handleValidationState = (item, value) => {
    setPasswordValidation((prevData) => ({ ...prevData, [item]: value }));
  };

  const pwValidation = (e) => {
    const password = e.target.value;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    password.length > 12
      ? handleValidationState("length", true)
      : handleValidationState("length", false);
    hasUpperCase
      ? handleValidationState("uppercase", true)
      : handleValidationState("uppercase", false);
    hasLowerCase
      ? handleValidationState("lowercase", true)
      : handleValidationState("lowercase", false);
    hasNumber
      ? handleValidationState("number", true)
      : handleValidationState("number", false);
    hasSymbol
      ? handleValidationState("symbol", true)
      : handleValidationState("symbol", false);
  };

  const [error, setError]=useState(false)

  return (
    <div>
      {!load ? (
        <Spinner />
      ) : (
        <div id="main-wrapper" className="oxyy-login-register">
          {spinnerLoad && <SpinnerSecondary />}
          <div className="container-fluid px-0">
            <div className="row g-0 min-vh-100">
              <div className="col-md-7 d-flex flex-column order-2 order-md-1">
                <div className="lang-switcher" ref={menuRef}>
                  <span
                    className="login-selected-lang p-1 px-3"
                    onClick={() => setMenuLang(!menuLang)}
                  >
                    {selectedLang.includes("fr")
                      ? t("french")
                      : selectedLang.includes("en")
                      ? t("english")
                      : ""}
                    <img
                      className="login-flags"
                      src={`assets/media/flags/${
                        selectedLang.includes("fr")
                          ? "fr"
                          : selectedLang.includes("en")
                          ? "gb"
                          : ""
                      }.svg`}
                      alt=""
                    />{" "}
                  </span>
                  <div
                    className={`login-lang-container ${
                      menuLang && "container-show"
                    }`}
                  >
                    <span
                      className="login-selected-lang-opt"
                      onClick={() => handleLangChange("fr")}
                    >
                      <span>{t("french")}</span>
                      <span>
                        <img
                          className="login-flags"
                          src="assets/media/flags/fr.svg"
                          alt=""
                        />
                      </span>
                    </span>
                    <span
                      className="login-selected-lang-opt"
                      onClick={() => handleLangChange("en")}
                    >
                      <span> {t("english")}</span>
                      <span>
                        <img
                          className="login-flags"
                          src="assets/media/flags/gb.svg"
                          alt=""
                        />
                      </span>
                    </span>
                  </div>
                </div>
                <div className="container my-auto py-5">
                  <div className="row mx-0">
                    <div className="col-11 col-lg-9 col-xl-8 mx-auto text-center">
                      <div className="logo mb-5 text-center">
                       
                          <img
                            src="/assets/media/myxtel-dark.png"
                            alt="GoCall"
                            width={180}
                          />
                        
                      </div>

                      {updateResponse === 0 ? (
                        <div>
                          {" "}
                          <h4 className="mb-5">
                            {t("auth.update_password.title")}
                          </h4>
                          {reqError && (
                            <div className="alert alert-danger d-flex align-items-center pad-5 mb-10">
                              <span className="svg-icon svg-icon-2hx svg-icon-danger me-3">
                                <span className="svg-icon svg-icon-2hx svg-icon-danger me-4">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      opacity="0.3"
                                      x="2"
                                      y="2"
                                      width="20"
                                      height="20"
                                      rx="5"
                                      fill="currentColor"
                                    />
                                    <rect
                                      x="7"
                                      y="15.3137"
                                      width="12"
                                      height="2"
                                      rx="1"
                                      transform="rotate(-45 7 15.3137)"
                                      fill="currentColor"
                                    />
                                    <rect
                                      x="8.41422"
                                      y="7"
                                      width="12"
                                      height="2"
                                      rx="1"
                                      transform="rotate(45 8.41422 7)"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                              </span>
                              <div className="d-flex flex-column login-error-div">
                                <h5 className="mb-1 text-left">{t("error")}</h5>
                                <span className="text-left">
                                  {t(reqError)}.
                                </span>
                              </div>
                            </div>
                          )}
                          <div className="text-start">
                            <form id="" method="post">
                              <div className="mb-4">
                                <label htmlFor="" className="form-label">
                                  {t("auth.update_password.fields.password")}
                                </label>
                                <input
                                  type="password"
                                  className={`form-control ${
                                    (reqError === "errors.pw_not_match" ||
                                      reqError === "errors.pw_three_char") &&
                                    "border-red"
                                  }`}
                                  id=""
                                  required=""
                                  placeholder={t(
                                    "auth.update_password.placeholders.password"
                                  )}
                                  name="password"
                                  value={data.password}
                                  onChange={(e) => {
                                    pwValidation(e);
                                    handleInputChange(e);
                                  }}
                                />
                                {data.password.length > 0 && (
                                  <div
                                    className={`flex-column mt-3 ${
                                      passwordValidation.length &&
                                      passwordValidation.number &&
                                      passwordValidation.uppercase &&
                                      passwordValidation.lowercase &&
                                      passwordValidation.symbol
                                        ? "display-none"
                                        : "d-flex"
                                    }`}
                                  >
                                    <div className="d-flex align-items-center gap-2">
                                      <svg
                                        height={14}
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="#000000"
                                      >
                                        <g
                                          id="SVGRepo_bgCarrier"
                                          stroke-width="0"
                                        ></g>
                                        <g
                                          id="SVGRepo_tracerCarrier"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        ></g>
                                        <g id="SVGRepo_iconCarrier">
                                          {" "}
                                          <path
                                            fill="none"
                                            stroke={
                                              !passwordValidation.length
                                                ? "#a1a5b7"
                                                : "green"
                                            }
                                            stroke-width="2"
                                            d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M7,12 L11,15 L16,8"
                                          ></path>{" "}
                                        </g>
                                      </svg>

                                      <span
                                        className={`fs-8 fw-semibold ${
                                          passwordValidation.length
                                            ? "color-green"
                                            : "text-gray-600"
                                        }`}
                                      >
                                        {t("min_12_char")}
                                      </span>
                                    </div>

                                    <div className="d-flex align-items-center gap-2">
                                      <svg
                                        height={14}
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="#000000"
                                      >
                                        <g
                                          id="SVGRepo_bgCarrier"
                                          stroke-width="0"
                                        ></g>
                                        <g
                                          id="SVGRepo_tracerCarrier"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        ></g>
                                        <g id="SVGRepo_iconCarrier">
                                          {" "}
                                          <path
                                            fill="none"
                                            stroke={
                                              !passwordValidation.uppercase
                                                ? "#a1a5b7"
                                                : "green"
                                            }
                                            stroke-width="2"
                                            d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M7,12 L11,15 L16,8"
                                          ></path>{" "}
                                        </g>
                                      </svg>

                                      <span
                                        className={`fs-8 fw-semibold ${
                                          passwordValidation.uppercase
                                            ? "color-green"
                                            : "text-gray-600"
                                        }`}
                                      >
                                        {t("uppercase")}
                                      </span>
                                    </div>

                                    <div className="d-flex align-items-center gap-2">
                                      <svg
                                        height={14}
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="#000000"
                                      >
                                        <g
                                          id="SVGRepo_bgCarrier"
                                          stroke-width="0"
                                        ></g>
                                        <g
                                          id="SVGRepo_tracerCarrier"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        ></g>
                                        <g id="SVGRepo_iconCarrier">
                                          {" "}
                                          <path
                                            fill="none"
                                            stroke={
                                              !passwordValidation.lowercase
                                                ? "#a1a5b7"
                                                : "green"
                                            }
                                            stroke-width="2"
                                            d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M7,12 L11,15 L16,8"
                                          ></path>{" "}
                                        </g>
                                      </svg>

                                      <span
                                        className={`fs-8 fw-semibold ${
                                          passwordValidation.lowercase
                                            ? "color-green"
                                            : "text-gray-600"
                                        }`}
                                      >
                                        {t("lowercase")}
                                      </span>
                                    </div>

                                    <div className="d-flex align-items-center gap-2">
                                      <svg
                                        height={14}
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="#000000"
                                      >
                                        <g
                                          id="SVGRepo_bgCarrier"
                                          stroke-width="0"
                                        ></g>
                                        <g
                                          id="SVGRepo_tracerCarrier"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        ></g>
                                        <g id="SVGRepo_iconCarrier">
                                          {" "}
                                          <path
                                            fill="none"
                                            stroke={
                                              !passwordValidation.number
                                                ? "#a1a5b7"
                                                : "green"
                                            }
                                            stroke-width="2"
                                            d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M7,12 L11,15 L16,8"
                                          ></path>{" "}
                                        </g>
                                      </svg>

                                      <span
                                        className={`fs-8 fw-semibold ${
                                          passwordValidation.number
                                            ? "color-green"
                                            : "text-gray-600"
                                        }`}
                                      >
                                        {t("valid_number")}
                                      </span>
                                    </div>

                                    <div className="d-flex align-items-center gap-2">
                                      <svg
                                        height={14}
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="#000000"
                                      >
                                        <g
                                          id="SVGRepo_bgCarrier"
                                          stroke-width="0"
                                        ></g>
                                        <g
                                          id="SVGRepo_tracerCarrier"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        ></g>
                                        <g id="SVGRepo_iconCarrier">
                                          {" "}
                                          <path
                                            fill="none"
                                            stroke={
                                              !passwordValidation.symbol
                                                ? "#a1a5b7"
                                                : "green"
                                            }
                                            stroke-width="2"
                                            d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M7,12 L11,15 L16,8"
                                          ></path>{" "}
                                        </g>
                                      </svg>

                                      <span
                                        className={`fs-8 fw-semibold ${
                                          passwordValidation.symbol
                                            ? "color-green"
                                            : "text-gray-600"
                                        }`}
                                      >
                                        {t("symbol")}
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div
                                className={`mb-4 ${
                                  (!passwordValidation.length ||
                                    !passwordValidation.uppercase ||
                                    !passwordValidation.lowercase ||
                                    !passwordValidation.symbol ||
                                    !passwordValidation.number) &&
                                  "disabled-event"
                                }`}
                              >
                                <label htmlFor="" className="form-label">
                                  {t(
                                    "auth.update_password.fields.confirm_password"
                                  )}
                                </label>
                                <input
                                  type="password"
                                  className={`form-control ${
                                    (reqError === "errors.pw_not_match" ||
                                      reqError === "errors.pw_three_char") &&
                                    "border-red"
                                  }`}
                                  id=""
                                  required=""
                                  placeholder={t(
                                    "auth.update_password.placeholders.confirm_password"
                                  )}
                                  name="password_confirmation"
                                  value={data.password_confirmation}
                                  onChange={handleInputChange}
                                  onBlur={() => {
                                    if (data.password !== data.password_confirmation) {
                                      setError(true);
                                    }
                                  }}
                                />
                                {error && (
                <div className="d-flex mt-6">
                  {" "}
                  <div className="fw-bold danger-drag-and-drop mt-3">
                    <svg
                      height={20}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          opacity="0.5"
                          d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z"
                          fill="#e73e3e"
                        ></path>{" "}
                        <path
                          d="M12 6.25C12.4142 6.25 12.75 6.58579 12.75 7V13C12.75 13.4142 12.4142 13.75 12 13.75C11.5858 13.75 11.25 13.4142 11.25 13V7C11.25 6.58579 11.5858 6.25 12 6.25Z"
                          fill="#e73e3e"
                        ></path>{" "}
                        <path
                          d="M12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
                          fill="#e73e3e"
                        ></path>{" "}
                      </g>
                    </svg>
                    <span className="fs-7 fw-semibold text-gray-800">
                      {t("errors.pw_not_match")}.
                    </span>
                  </div>
                </div>
              )}
                              </div>
                              <div className={`d-grid my-4 ${(!data.password_confirmation || error)&&"disabled-event"}`}>
                                <span
                                  className="btn btn-primary"
                                  onClick={handleChangePassword}
                                >
                                  {t("buttons.update")}
                                </span>
                              </div>
                            </form>
                          </div>{" "}
                        </div>
                      ) : updateResponse === 1 ? (
                        <div>
                          <div className="response-div">
                            <span className="response-div-message">
                              {t("auth.update_password.popup.success")}
                            </span>
                          </div>
                        </div>
                      ) : updateResponse === 2 ? (
                        <div className="response-error-div">
                          <span className="response-error-div-message">
                            {t("auth.update_password.popup.error")}
                          </span>
                        </div>
                      ) : null}

                      <p
                        className={`text-muted text-2 mb-0 ${
                          updateResponse != 0 && "extra-margin-div"
                        }`}
                      >
                        {t("auth.return_to")}
                        <Link to="/login">Log in</Link>
                      </p>
                    </div>
                  </div>
                </div>
                
                <div className="container py-2">
                  <p className="text-2 text-muted text-center mb-0">
                    
                    <a href="#">MYXTEL</a>, 2025. {t("auth.login.footer.copyright")}.
                  </p>
                </div>

              </div>
              <div className="col-md-5 order-1 order-md-2">
                <div className="hero-wrap d-flex align-items-center h-100">
                  <div className="hero-mask opacity-8 bg-secondary" />
                  <div
                    className="hero-bg hero-bg-scroll"
                    style={{ backgroundImage: 'url("/assets/img/bg2.jpg")' }}
                  ></div>
                  <div className="hero-content mx-auto w-100 h-100 d-flex flex-column">
                    <div className="row g-0 my-auto py-5">
                      <div className="col-10 col-lg-9 mx-auto">
                        <p className="text-4 text-white">
                          {t("auth.reset.side.title")}
                        </p>
                        <h1 className="text-10 text-white mb-4">
                          {t("auth.reset.side.description")}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpdatePassword;
