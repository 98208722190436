import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { convertCurrency, defaultOnlyDateFormat } from "../../../constants/Utils";
import { useTranslation } from "react-i18next";
import AllocateNumbersModal from "./Modals/AllocateNumbersModal";
import UnallocateNumbers from "./Modals/UnallocateNumbers";
import ChangePasswordModal from "./Modals/ChangePasswordModal";
import DeleteExtensionModal from "./Modals/DeleteExtensionModal";
import CredentialsModal from "./Modals/CredentialsModal";
import { confirmationSwal } from "../../../components/Alerts/confirmationAlert";
import ErrorExpiredSession from "../../../components/Alerts/errorExpiredSession";
import ErrorSwal from "../../../components/Alerts/errorAlert";
import unallocateNumbers from "../Services/unallocateNumbers";
import { successSwall } from "../../../components/Alerts/successAlert";
import InternalErrorSwal from "../../../components/Alerts/internalErrorAlert";

const DataTableUsers = ({ users, changeData }) => {
  const { t } = useTranslation();

  const [index, setIndex] = useState(null);
  const [dataIndex, setDataIndex] = useState(null);

  const columnsPersons = [
    {
      name: t("titles.user"),
      cell: (row) => (
        <span className="fw-bold">
          <span className="me-2">{row.name ? row.name : row.extension}</span>{" "}
          {row.credentials.length > 1 && (
            <svg
              height={12}
              viewBox="0 0 64 64"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              aria-hidden="true"
              role="img"
              className="iconify iconify--emojione"
              preserveAspectRatio="xMidYMid meet"
              fill="#000000"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth={0} />
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M62 6c0-2-2-4-4-4H6C4 2 2 4 2 6v38h60V6z"
                  fill="#212528"
                >
                  {" "}
                </path>{" "}
                <path d="M2 44v4c0 2 2 4 4 4h52c2 0 4-2 4-4v-4H2z" fill="#ddd">
                  {" "}
                </path>{" "}
                <path
                  d="M24 54c0 4-4 4-8 4h32c-4 0-8 0-8-4v-2H24v2"
                  fill="#bfbebe"
                >
                  {" "}
                </path>{" "}
                <path fill="#3e4347" d="M6 6h52v34H6z">
                  {" "}
                </path>{" "}
                <g fill="#94989b">
                  {" "}
                  <circle cx={32} cy="47.9" r={1}>
                    {" "}
                  </circle>{" "}
                  <path d="M16 58h32v4H16z"> </path>{" "}
                </g>{" "}
              </g>
            </svg>
          )}
        </span>
      ),
      sortable: true,
    },
    {
      name: t("titles.user_type"),
      cell: (row) => (
        <span className={`badge badge-light margin-dim`}>
          {t(row.extension_type)}
        </span>
      ),
    },
    {
      name: t("titles.credentials"),
      cell: (row, rowIndex) => (
        <span
          title={t("open_doc")}
          className={`grey-button ${
            row.credentials.length === 0 && "disabled-event"
          }`}
          onClick={() => {
            setDataIndex(rowIndex);
            handleModalDisplay("credentials", true);
          }}
        >
          <svg
            className="docs-svg"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <path
                d="M15.0007 12C15.0007 13.6569 13.6576 15 12.0007 15C10.3439 15 9.00073 13.6569 9.00073 12C9.00073 10.3431 10.3439 9 12.0007 9C13.6576 9 15.0007 10.3431 15.0007 12Z"
                stroke="#5c5e6e"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>{" "}
              <path
                d="M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z"
                stroke="#5c5e6e"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>{" "}
            </g>
          </svg>
        </span>
      ),
    },
    {
      name: t("titles.numbers"),
      cell: (row) => (
        <div className="dt-user-all-numbers gap-1">
          {row.numbers.map((item) => (
            <div className="dt-user-numbers badge badge-light gap-2">
              <img
                className="number-flag"
                src={`/assets/media/flags/${
                  item.country && item.country.toLowerCase()
                }.svg`}
                alt=""
              />
              <span>{item.number}</span>
            </div>
          ))}
        </div>
      ),
    },
    {
      name: t("titles.outbound_credit"),
      cell: (row) => (
        <span className="badge badge-light margin-dim">
          {row.outbound_credit +
            ` ` +
            convertCurrency(row.outbound_credit_currency)}
        </span>
      ),
      sortable: true,
    },
    {
      name: t("titles.creation_date"),
      cell: (row) => <span>{defaultOnlyDateFormat(row.starting_date)}</span>,
      sortable: true,
    },
    {
      name: t("titles.status"),
      selector: (row) => (
        <span
          className={`badge ${
            row.status === 1
              ? "badge-light-success"
              : row.status === 3
              ? "badge-light-danger"
              : row.status === 0 || row.status === 2
              ? "badge-light-warning"
              : ""
          }`}
        >
          {row.status === 0
            ? t("draft")
            : row.status === 1
            ? t("active")
            : row.status === 2
            ? t("pending")
            : row.status === 3
            ? t("disabled")
            : ""}
        </span>
      ),
      sortable: true,
    },
    {
      name: "ACTIONS",
      cell: (row, rowIndex) => (
        <div className="pe-0 text-end" onMouseLeave={() => setIndex(null)}>
          <span
            className="btn btn-sm btn-light btn-active-light-primary show menu-dropdown"
            onClick={() => {
              setDataIndex(rowIndex);
              setIndex(rowIndex);
            }}
          >
            {t("actions")}
            <span className="svg-icon svg-icon-5 m-0">
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                  fill="currentColor"
                />
              </svg>
            </span>
          </span>

          <div
            className={`menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold py-3 users-actions-btn user-dd-menu ${
              index === rowIndex ? "active show " : ""
            }`}
            data-kt-menu="true"
          >
            <div className="menu-item px-3">
              <span
                className={`menu-link px-3 disabled-event ${
                  (row.status === 3 || row.numbers.length > 0) &&
                  "disabled-event"
                }`}
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_allocate_phone"
                onClick={() => handleModalDisplay("allocate_numbers", true)}
              >
                {t("allocate_phone_numbers")}
              </span>
            </div>
            <div className="menu-item px-3">
              <span
                className={`menu-link px-3 disabled-event ${
                  (row.status !== 1 || row.numbers.length === 0) &&
                  "disabled-event"
                }`}
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_unallocate_phone"
                onClick={() => confirmationSwal(submitNumbersUnallocation)}
              >
                {t("unallocate_phone_numbers")}
              </span>
            </div>
            <div className="menu-item px-3">
              <span
                className={`menu-link px-3 ${
                  row.status !== 1 && "disabled-event"
                }`}
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_change_password"
                onClick={() => handleModalDisplay("change_password", true)}
              >
                {t("change_password")}
              </span>
            </div>
            <div className="menu-item px-3">
              <span
                className={`menu-link px-3 ${
                  row.status === 3 && "disabled-event"
                }`}
                data-kt-customer-table-filter="delete_row"
                onClick={() => handleModalDisplay("delete_extension", true)}
              >
                {t("delete")}
              </span>
            </div>
          </div>
        </div>
      ),
    },
  ];

  const [modals, setModals] = useState({
    allocate_numbers: false,
    unallocate_numbers: false,
    change_password: false,
    delete_extension: false,
    credentials: false,
  });

  const handleModalDisplay = (item, value) => {
    setModals((prevData) => ({ ...prevData, [item]: value }));
  };

  const submitNumbersUnallocation = async () => {
    try {
      const response = await unallocateNumbers({
        extension_id: users[dataIndex].id,
      });
      if (!response.ok) {
        throw new Error(response.status);
      }

      successSwall(t("numbers_allocation_succeeded"));

      changeData("numbers", users[dataIndex].id, []);

      changeData("outbound_credit", users[dataIndex].id, 0);

      changeData("outbound_credit_currency", users[dataIndex].id, "EUR");
    } catch (error) {
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else {
        InternalErrorSwal();
      }
    }
  };

  return (
    <div className="card-body">
      <div className="header-color"></div>
      <DataTable
        columns={columnsPersons}
        data={users}
        fixedHeader
        sortable
      ></DataTable>
      {modals.credentials && (
        <CredentialsModal
          closeModal={() => {
            document.body.classList.remove("modal-open");
            handleModalDisplay("credentials", false);
          }}
          data={users[dataIndex]}
        />
      )}
    
      {modals.change_password && (
        <ChangePasswordModal
          user={
            users[dataIndex].name
              ? users[dataIndex].name
              : users[dataIndex].extension
          }
          user_id={users[dataIndex].id}
          closeModal={() => handleModalDisplay("change_password", false)}
          changeData={changeData}
        />
      )}
      {modals.delete_extension && (
        <DeleteExtensionModal
          user_id={users[dataIndex].id}
          user={
            users[dataIndex].name
              ? users[dataIndex].name
              : users[dataIndex].extension
          }
          changeData={changeData}
          closeModal={() => handleModalDisplay("delete_extension", false)}
        />
      )}
    </div>
  );
};

export default DataTableUsers;
