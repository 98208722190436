const token = await localStorage.getItem("token");
const getInvoices = async (body, params) => {
  return await fetch(
    `${process.env.REACT_APP_BASE_URL}/interface/billing?` + params,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
      body,
    }
  );
};

export default getInvoices;
