import React, { useEffect, useRef, useState } from "react";

import { Link } from "react-router-dom";
import Spinner from "../components/Spinner";
import LoginService from "./login.service";
import { useTranslation } from "react-i18next";
import SpinnerSecondary from "../components/SpinnerSecondary";

const Login = () => {
  const [load, setLoad] = useState(false);
  const [spinnerLoad, setSpinnerLoad] = useState(false);

  useEffect(() => {
    Promise.all([
      import("./Style/Login.css"),
      import("./Style/bootstrap.min.css"),
      import("./Style/all.min.css"),
    ]).then(() => {
      setLoad(true);
    });
  }, []);

  const { t } = useTranslation();

  const [inputErrors, setInputErrors] = useState(null);

  const [data, setData] = useState({ username: "", password: "" });

  const [error, setError] = useState(null);

  const handleInputChange = (e) => {
    if (inputErrors) {
      setInputErrors(null);
    }

    if (error) {
      setError(null);
    }

    const { name, value } = e.target;
    setData((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const checkInput = (e) => {
    setSpinnerLoad(true);
    setError(null);
    setInputErrors(null);
    e.preventDefault();

    let hasError = false;

    if (!data.username) {
      setSpinnerLoad(false);
      setInputErrors((prevState) => ({
        ...prevState,
        username: t("auth.login.error.fields.username.empty"),
      }));
      hasError = true;
    }
    if (!data.password) {
      setSpinnerLoad(false);
      setInputErrors((prevState) => ({
        ...prevState,
        password: t("auth.login.error.fields.password.empty"),
      }));
      hasError = true;
    }

    const fetchPreferences = async () => {
      try {
        const response = await LoginService.getPreferences();
        if (!response.ok) {
          throw new Error(response.status);
        }
        const fetchedData = await response.json();

        localStorage.setItem(
          "data",
          JSON.stringify({
            id: fetchedData.data[0].id,
            language: fetchedData.data[0].language
              ? fetchedData.data[0].language
              : "null",
            timezone: fetchedData.data[0].timezone
              ? fetchedData.data[0].timezone
              : "null",
            date_format: fetchedData.data[0].date_format
              ? fetchedData.data[0].date_format
              : "null",
            time_format: fetchedData.data[0].time_format
              ? fetchedData.data[0].time_format
              : "null",
            currency_format: fetchedData.data[0].currency_format
              ? fetchedData.data[0].currency_format
              : "null",
            country_format: fetchedData.data[0].country_format
              ? fetchedData.data[0].country_format
              : "null",
            default_view: fetchedData.data[0].default_view
              ? fetchedData.data[0].default_view
              : "null",
          })
        );
        if (fetchedData) {
          localStorage.setItem(
            "i18nextLng",
            fetchedData.data[0].language === "EN" ? "en-EN" : "fr-FR"
          );
          handleLangChange(
            fetchedData.data[0].language === "EN" ? "en-EN" : "fr-FR"
          );
        }
      } catch (err) {
        console.log(err.message);
      }
    };

    if (!hasError) {
      (async () => {
        try {
          setLoad(true);
          const response = await LoginService.login(
            JSON.stringify({
              username: data.username,
              password: data.password,
            })
          );
          const fetchedData = await response.json();

          if (!response.ok) {
            setSpinnerLoad(false);

            throw new Error(fetchedData.code);
          }

          const token = await fetchedData.data[0].access_token;
          const username = await fetchedData.data[0].username;
          const firstname = await fetchedData.data[0].firstname;
          const lastname = await fetchedData.data[0].lastname;
          const avatar = await fetchedData.data[0].avatar;
          const email = await fetchedData.data[0].login;
          const role = await fetchedData.data[0].role;
          const customer_id = await fetchedData.data[0].customer_id;
          const total_numbers = await fetchedData.data[0].totale_numbers;
          const total_extensions = await fetchedData.data[0].totale_extensions;
          const country = await fetchedData.data[0].country;
          const have_sms = await fetchedData.data[0].have_sms;
          const email_billing = await fetchedData.data[0].email_billing;

          const authentication = await fetchedData.code;

          const numbers_list = await fetchedData.data[0].numbers_phone;

          localStorage.setItem(
            "authenticated",
            authentication == "2fa_processing" ||
              authentication == "code_already_sent"
              ? false
              : true
          );

          localStorage.setItem("token", token);
          localStorage.setItem("code", authentication);

          if (
            authentication == "2fa_processing" ||
            authentication == "code_already_sent"
          ) {
            window.location.href = `${process.env.REACT_APP_LOCAL}/2fa_auth`;
            localStorage.setItem("active_2fa", true);
            localStorage.setItem("sent_to", fetchedData.data[0].login);
          } else {
            localStorage.setItem("username", username);
            localStorage.setItem("firstname", firstname ? firstname : "");
            localStorage.setItem("lastname", lastname ? lastname : "");
            localStorage.setItem("avatar", avatar);
            localStorage.setItem("email", email ? email : "");
            localStorage.setItem("role", role ? role : "");
            localStorage.setItem("customer_id", customer_id);
            localStorage.setItem("total_numbers", total_numbers);
            localStorage.setItem("total_users", total_extensions);
            localStorage.setItem("active_2fa", false);
            localStorage.setItem("country", country);
            localStorage.setItem("numbers_list", JSON.stringify(numbers_list));
            localStorage.setItem("have_sms", have_sms);
            localStorage.setItem("email_billing", email_billing);
            await fetchPreferences(token);
            window.location.href = `${process.env.REACT_APP_LOCAL}/dashboard`;
          }

          // if(rememberMeChecked){document.cookie = "remember_me=true; expires=Thu, 01 Jan 2099 00:00:00 UTC; path=/";}
          // else{document.cookie = "remember_me=true; path=/";}
        } catch (err) {
          switch (err.message) {
            case "INVALID_CREDENTIALS":
              setError((prevState) => ({
                ...prevState,
                title: t("auth.login.error.code.401.title"),
                message: t("auth.login.error.code.401.message"),
              }));
              break;

            default:
              setError((prevState) => ({
                ...prevState,
                title: t("auth.login.error.code.500.title"),
                message: t("auth.login.error.code.500.message"),
              }));
              break;
          }
        }
      })();
    }
  };

  const [menuLang, setMenuLang] = useState(false);
  const [selectedLang, setSelectedLang] = useState(
    localStorage.getItem("i18nextLng")
  );

  const { i18n } = useTranslation();

  const handleLangChange = (item) => {
    setSelectedLang(item);
    setMenuLang(false);
    localStorage.setItem("i18nextLng", item);
    i18n.changeLanguage(item);
  };

  const menuRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuLang(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [menuRef]);

  const [rememberMeChecked, setRememberMeChecked] = useState(true);

  const [showPassword, setShowPassword] = useState(false);

  return (
    <div>
      {spinnerLoad && <SpinnerSecondary />}
      {load ? (
        <div className="oxyy-login-register">
          <div className="container-fluid px-0">
            <div className="row g-0 min-vh-100">
              <div className="col-md-7 d-flex flex-column order-2 order-md-1">
                <div className="lang-switcher" ref={menuRef}>
                  <span
                    className="login-selected-lang p-1 px-3"
                    onClick={() => setMenuLang(!menuLang)}
                  >
                    {selectedLang.includes("fr")
                      ? t("french")
                      : selectedLang.includes("en")
                      ? t("english")
                      : ""}
                    <img
                      className="login-flags"
                      src={`assets/media/flags/${
                        selectedLang.includes("fr")
                          ? "fr"
                          : selectedLang.includes("en")
                          ? "gb"
                          : ""
                      }.svg`}
                      alt=""
                    />{" "}
                  </span>
                  <div
                    className={`login-lang-container ${
                      menuLang && "container-show"
                    }`}
                  >
                    <span
                      className="login-selected-lang-opt"
                      onClick={() => handleLangChange("fr")}
                    >
                      <span>{t("french")}</span>
                      <span>
                        <img
                          className="login-flags"
                          src="assets/media/flags/fr.svg"
                          alt=""
                        />
                      </span>
                    </span>
                    <span
                      className="login-selected-lang-opt"
                      onClick={() => handleLangChange("en")}
                    >
                      <span> {t("english")}</span>
                      <span>
                        <img
                          className="login-flags"
                          src="assets/media/flags/gb.svg"
                          alt=""
                        />
                      </span>
                    </span>
                  </div>
                </div>
                <div className="container my-auto py-5">
                  <div className="row mx-0">
                    <div className="col-11 col-lg-9 col-xl-6 mx-auto">
                      <div className="logo mb-5 text-center">
                        {" "}
                        <img
                          src="/assets/media/myxtel-dark.png"
                          alt={process.env.REACT_APP_TITLE}
                          width={180}
                        />
                      </div>
                      {error && (
                        <div className="alert alert-danger d-flex align-items-center pad-5 mb-10">
                          <span className="svg-icon svg-icon-2hx svg-icon-danger me-3">
                            <span className="svg-icon svg-icon-2hx svg-icon-danger me-4">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  opacity="0.3"
                                  x="2"
                                  y="2"
                                  width="20"
                                  height="20"
                                  rx="5"
                                  fill="currentColor"
                                />
                                <rect
                                  x="7"
                                  y="15.3137"
                                  width="12"
                                  height="2"
                                  rx="1"
                                  transform="rotate(-45 7 15.3137)"
                                  fill="currentColor"
                                />
                                <rect
                                  x="8.41422"
                                  y="7"
                                  width="12"
                                  height="2"
                                  rx="1"
                                  transform="rotate(45 8.41422 7)"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>
                          </span>
                          <div className="d-flex flex-column login-error-div">
                            <h5 className="mb-1">{error.title}</h5>
                            <span>{error.message}</span>
                          </div>
                        </div>
                      )}
                      <form
                        method="post"
                        onKeyDown={(e) => {
                          if (e.code?.includes("Enter")) {
                            checkInput(e);
                          }
                        }}
                      >
                        <div className="mb-3">
                          <label htmlFor="emailAddress" className="form-label">
                            {t("auth.login.fields.username")}
                          </label>
                          <div className="mb-3 icon-group icon-group-end">
                            <input
                              type="text"
                              className={`form-control ${
                                inputErrors?.username && "invalid-value"
                              }`}
                              required=""
                              placeholder="xyz@gmail.com"
                              name="username"
                              value={data.username}
                              onChange={handleInputChange}
                            />
                            {inputErrors?.username && (
                              <p className="invalid-value">
                                {inputErrors?.username}
                              </p>
                            )}
                            <span className="icon-inside text-muted">
                              <svg
                                viewBox="0 -3.5 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                                className="svg-login"
                              >
                                <path
                                  d="M430 275.916l-3.316-2.749-11.569 11.843h29.476l-11.356-11.863-3.235 2.769zm4.89-4.026l11.002 11.439a2.53 2.53 0 00.108-.695v-19.772l-11.11 9.028zM414 262.816v19.818c0 .243.045.473.108.695l11.039-11.402L414 262.816zM445 261h-30l15 12.019L445 261z"
                                  transform="translate(-414 -261)"
                                  fill={
                                    inputErrors?.username
                                      ? "#ff0000"
                                      : "#787878"
                                  }
                                  stroke="none"
                                  strokeWidth={1}
                                  fillRule="evenodd"
                                />
                              </svg>
                            </span>
                          </div>
                        </div>
                        <div className="mb-3">
                          <label htmlFor="loginPassword" className="form-label">
                            {t("auth.login.fields.password")}
                          </label>
                          <div className="mb-3 icon-group icon-group-end">
                            <input
                              type={showPassword ? "text" : "password"}
                              className={`form-control ${
                                inputErrors?.password && "invalid-value"
                              }`}
                              required=""
                              placeholder="*****************"
                              name="password"
                              value={data.password}
                              onChange={handleInputChange}
                            />
                            {inputErrors?.password && (
                              <p className="invalid-value">
                                {inputErrors?.password}
                              </p>
                            )}
                            {data.password ? (
                              <span
                                className="icon-inside text-muted"
                                onClick={() =>  setShowPassword((prev) => !prev)}
                              >
                                <svg
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="svg-login cursor-pointer"
                                >
                                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                  <g
                                    id="SVGRepo_tracerCarrier"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <g id="SVGRepo_iconCarrier">
                                    {" "}
                                    <path
                                      opacity="0.5"
                                      d="M2 12C2 13.6394 2.42496 14.1915 3.27489 15.2957C4.97196 17.5004 7.81811 20 12 20C16.1819 20 19.028 17.5004 20.7251 15.2957C21.575 14.1915 22 13.6394 22 12C22 10.3606 21.575 9.80853 20.7251 8.70433C19.028 6.49956 16.1819 4 12 4C7.81811 4 4.97196 6.49956 3.27489 8.70433C2.42496 9.80853 2 10.3606 2 12Z"
                                      fill="#1C274C"
                                    />{" "}
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M8.25 12C8.25 9.92893 9.92893 8.25 12 8.25C14.0711 8.25 15.75 9.92893 15.75 12C15.75 14.0711 14.0711 15.75 12 15.75C9.92893 15.75 8.25 14.0711 8.25 12ZM9.75 12C9.75 10.7574 10.7574 9.75 12 9.75C13.2426 9.75 14.25 10.7574 14.25 12C14.25 13.2426 13.2426 14.25 12 14.25C10.7574 14.25 9.75 13.2426 9.75 12Z"
                                      fill="#1C274C"
                                    />{" "}
                                  </g>
                                </svg>
                              </span>
                            ) : (
                              <span className="icon-inside text-muted">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 64 64"
                                  xmlSpace="preserve"
                                  fill="#000"
                                  className="svg-login"
                                >
                                  <path
                                    fill={
                                      inputErrors?.password
                                        ? "#ff0000"
                                        : "#787878"
                                    }
                                    d="M52 24h-4v-8c0-8.836-7.164-16-16-16S16 7.164 16 16v8h-4c-2.211 0-4 1.789-4 4v32c0 2.211 1.789 4 4 4h40c2.211 0 4-1.789 4-4V28c0-2.211-1.789-4-4-4zM32 48c-2.211 0-4-1.789-4-4s1.789-4 4-4 4 1.789 4 4-1.789 4-4 4zm8-24H24v-8a8 8 0 0116 0v8z"
                                  />
                                </svg>
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col">
                            <div className="form-check">
                              <input
                                id="remember-me"
                                name="remember"
                                className="form-check-input"
                                type="checkbox"
                                checked={rememberMeChecked}
                                onChange={() => {
                                  setRememberMeChecked(!rememberMeChecked);
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="remember-me"
                              >
                                {t("auth.login.remember_me")}
                              </label>
                            </div>
                          </div>
                          <div className="col text-end">
                            <Link to="/recover_password">
                              {t("auth.login.forgot_password")}
                            </Link>
                          </div>
                        </div>
                        <div className="d-grid my-4">
                          <a
                            className="btn btn-primary"
                            href={`${process.env.REACT_APP_LOCAL}/dashboard`}
                            onClick={(e) => {
                              checkInput(e);
                            }}
                          >
                            {t("auth.login.login_button")}
                          </a>
                        </div>
                      </form>
                      <div className="d-flex align-items-center my-2">
                        <hr className="flex-grow-1" />
                        <hr className="flex-grow-1" />
                      </div>
                      <p className="text-center text-muted mb-0 ">
                        {t("auth.login.no_account")}
                        <Link to="/register" className="link-primary">
                          {t("auth.login.signup")}
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="container py-2">
                  <p className="text-2 text-muted text-center mb-0">
                    <a href="#">MYXTEL</a>, 2025.{" "}
                    {t("auth.login.footer.copyright")}.
                  </p>
                </div>
              </div>
              <div className="col-md-5 order-1 order-md-2">
                <div className="hero-wrap d-flex align-items-center h-100">
                  <div className="hero-mask opacity-8 bg-secondary" />
                  <div
                    className="hero-bg hero-bg-scroll"
                    style={{ backgroundImage: 'url("/assets/img/bg2.jpg")' }}
                  />
                  <div className="hero-content mx-auto w-100 h-100 d-flex flex-column">
                    <div className="row g-0 my-auto py-5">
                      <div className="col-10 col-lg-10 mx-auto">
                        <p className="text-4 text-white">
                          {t("auth.login.side.title")}
                        </p>
                        <h1 className="text-10 text-white">
                          {t("auth.login.side.description")}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default Login;
