import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import { getUsers } from "../../Services/getUsers";
import ErrorExpiredSession from "../../../../components/Alerts/errorExpiredSession";
import ErrorSwal from "../../../../components/Alerts/errorAlert";
import { Link } from "react-router-dom";
import SpinnerDiv from "../../../../Layouts/SpinnerDiv";
import { allocateExtensions } from "../../Services/allocateExtensions";
import { confirmationSwal } from "../../../../components/Alerts/confirmationAlert";
import { successSwall } from "../../../../components/Alerts/successAlert";
import InternalErrorSwal from "../../../../components/Alerts/internalErrorAlert";

const AllocateUserModal = ({
  closeModal,
  changeData,
  number,
  number_id,
  extensions,
  country,
}) => {

  const [showDiv, setShowDiv] = useState(0);

  const { t } = useTranslation();

  const [reqBody, setReqBody] = useState({
    number_id: number_id,
    users: [],
  });

  const [users, setUsers] = useState({ data: [], display: 0 });

  const [servers, setServers] = useState({ data: [], display: 0 });

  const [load, setLoad] = useState(false);

  // Get users
  async function fetchUsersData() {
    try {
      setLoad(true);

      const response = await getUsers({ type: 1, status: 1, no_allocated: true });

      setLoad(false);

      if (!response.ok) {
        throw new Error(response.status);
      }

      const results = await response.json();

      if (results.total > 0) {
        let updatedUsers = { display: 1, data: [] };

        results.data.forEach((element) => {
          updatedUsers.data.push({
            id: element.id,
            extension: element.name ? element.name : element.extension,
          });
        });

        setUsers(updatedUsers);
      } else {
        setUsers({ display: 2, data: [] });
      }
    } catch (error) {
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else {
        reFetchUsersData();
      }
    }
  }

  const reFetchUsersData = async () => {
    try {
      setLoad(true);
      const response = await getUsers({ type: 1, stauts: 1, no_allocated: true });

      setLoad(false);

      if (!response.ok) {
        throw new Error(response.status);
      }
      const results = await response.json();
      if (results.total > 0) {
        let updatedUsers = { display: 1, data: [] };

        results.data.forEach((element) => {
          updatedUsers.data.push({
            id: element.id,
            extension: element.name ? element.name : element.extension,
          });
        });

        setUsers(updatedUsers);
      } else {
        setUsers({ display: 2, data: [] });
      }
    } catch (error) {
      InternalErrorSwal();
    }
  };
  // Get servers
  const fetchServersData = async () => {
    try {
      setLoad(true);
      const response = await getUsers({ type: 2, status: 1, no_allocated: true });

      setLoad(false);
      if (!response.ok) {
        throw new Error(response.status);
      }
      const results = await response.json();
      if (results.total > 0) {
        let updatedServers = { display: 1, data: [] };

        results.data.forEach((element) => {
          updatedServers.data.push({
            id: element.id,
            extension: element.name ? element.name : element.extension,
          });
        });

        setServers(updatedServers);
      } else if(extensions.length>0 && extensions[0].type === 9){
        setServers({ display: 1, data: extensions });
      }else{
        setServers({ display: 2, data: [] });

      }
    } catch (error) {
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else {
        reFetchServersData();
      }
    }
  };

  const reFetchServersData = async () => {
    try {
      setLoad(true);
      const response = await getUsers({ type: 2, status: 1, no_allocated: true });

      setLoad(false);
      if (!response.ok) {
        throw new Error(response.status);
      }
      const results = await response.json();
      if (results.total > 0) {
        let updatedServers = { display: 1, data: [] };

        results.data.forEach((element) => {
          updatedServers.data.push({
            id: element.id,
            extension: element.name ? element.name : element.extension,
          });
        });

        setServers(updatedServers);
      } else {
        setServers({ display: 2, data: [] });
      }
    } catch (error) {
      InternalErrorSwal();
    }
  };

  useEffect(() => {
    if (extensions.length > 0) {
      let newExtensions = [];
      if (extensions[0].type === 9) {
        fetchServersData();
        setShowDiv(2);
      } else {
        fetchUsersData();
        setShowDiv(1);
      }
      extensions.forEach((element) => {
        newExtensions.push(element.id);
      });
      setReqBody((prevData) => ({ ...prevData, users: newExtensions }));
    }
  }, []);

  const [menuOpen, setMenuOpen] = useState(false);

  const handleSelectUser = (item) => {

    if (!reqBody.users.find((user) => user === item)) {

      const newData = []

      if (users.data.find((element) => element.id === item)) {

        const usersID = users.data.map((item)=>item.id)
        
        reqBody.users.forEach((element)=>!usersID.includes(element)&&newData.push(element) )


       
        
        setReqBody((prevData) => ({
          ...prevData,
          users: [...newData, item],
        }));

      } else if (servers.data.find((element) => element.id === item)){
        const serversID = servers.data.map((item)=>item.id)
        
        reqBody.users.forEach((element)=>!serversID.includes(element)&&newData.push(element) )


       
        
        setReqBody((prevData) => ({
          ...prevData,
          users: [...newData, item],
        }));
      }

      
    } else {
      setReqBody((prevData) => ({
        ...prevData,
        users: prevData.users.filter((user) => user !== item),
      }));
    }
  };

  const updateExtentions = async () => {
    try {
      let filteredUsers = [];

      if (showDiv === 1) {
        filteredUsers = reqBody.users.filter((user) =>
          users.data.some((item) => item.id === user)
        );
      } else if (showDiv === 2) {
        filteredUsers = reqBody.users.filter((user) =>
          servers.data.some((item) => item.id === user)
        );
      }
      setLoad(true);

      const response = await allocateExtensions(
        JSON.stringify({
          number_id: reqBody.number_id,
          users: filteredUsers,
        })
      );
      setLoad(false);

      if (!response.ok) {
        throw new Error(response.status);
      }

      let newExtensions = [];
      successSwall(t("user_allocation_succeeded"));
      if (showDiv === 1) {
        reqBody.users.forEach((element) => {
          const user = users.data.find((item) => item.id === element);
          if (user) {
            user.type = 8;
            newExtensions.push(user);
          }
        });
      } else if (showDiv === 2) {
        reqBody.users.forEach((element) => {
          const server = servers.data.find((item) => item.id === element);
          if (server) {
            server.type = 9;
            newExtensions.push(server);
          }
        });
      }
      changeData("extensions", number_id, newExtensions);
      closeModal();
    } catch (error) {
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else {
        InternalErrorSwal();
      }
    }
  };

  return (
    <div>
      <div className={`modal-container allocate-user-modal fade-in-down`}>
        {load && <SpinnerDiv />}
        <div className={`modal-show ${load && "disabled-event"}`}>
          <div className="modal-header p-6">
            <div className="d-flex flex-column gap-4">
              <h3>{t("allocate_users")}</h3>
              <div className="d-flex flex-wrap fw-semibold fs-6 gap-2">
                <div className="d-flex align-items-center text-gray-900 header-badge">
                  <span className="svg-icon svg-icon-4 me-1">
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 21C6 21.6 6.4 22 7 22H17C17.6 22 18 21.6 18 21V20H6V21Z"
                        fill="currentColor"
                      />
                      <path
                        opacity="0.3"
                        d="M17 2H7C6.4 2 6 2.4 6 3V20H18V3C18 2.4 17.6 2 17 2Z"
                        fill="currentColor"
                      />
                      <path
                        d="M12 4C11.4 4 11 3.6 11 3V2H13V3C13 3.6 12.6 4 12 4Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  {number}
                </div>
                <div className="d-flex align-items-center text-gray-900 header-badge">
                  <div className="symbol symbol-20px symbol-circle d-flex align-items-center gap-1">
                    <img
                      alt="Pic"
                      src={`assets/media/flags/${country}.svg`}
                      className="w-15px h-15px"
                    />
                    <span>
                      {country &&
                        country !== "unknown" &&
                        t(`countries.${country.toUpperCase()}`)}
                    </span>
                  </div>
                </div>
                {extensions.length > 0 ? (
                  <div className="d-flex align-items-center text-gray-900 header-badge">
                    <span className="svg-icon svg-icon-4 me-1">
                      {" "}
                      <svg
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12 21C16.9706 21 21 16.9706 21 12C21 10.1666 20.4518 8.46124 19.5103 7.03891L12.355 14.9893C11.6624 15.7589 10.4968 15.8726 9.66844 15.2513L6.4 12.8C5.95817 12.4686 5.86863 11.8418 6.2 11.4C6.53137 10.9582 7.15817 10.8686 7.6 11.2L10.8684 13.6513L18.214 5.48955C16.5986 3.94717 14.4099 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                            fill="#3fa21a"
                          ></path>{" "}
                        </g>
                      </svg>
                    </span>{" "}
                    {t("allocated")}
                  </div>
                ) : (
                  <div className="d-flex align-items-center text-gray-900 header-badge">
                    <span className="svg-icon svg-icon-4 me-1">
                      <svg
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM8.96963 8.96965C9.26252 8.67676 9.73739 8.67676 10.0303 8.96965L12 10.9393L13.9696 8.96967C14.2625 8.67678 14.7374 8.67678 15.0303 8.96967C15.3232 9.26256 15.3232 9.73744 15.0303 10.0303L13.0606 12L15.0303 13.9696C15.3232 14.2625 15.3232 14.7374 15.0303 15.0303C14.7374 15.3232 14.2625 15.3232 13.9696 15.0303L12 13.0607L10.0303 15.0303C9.73742 15.3232 9.26254 15.3232 8.96965 15.0303C8.67676 14.7374 8.67676 14.2625 8.96965 13.9697L10.9393 12L8.96963 10.0303C8.67673 9.73742 8.67673 9.26254 8.96963 8.96965Z"
                            fill="#d74242"
                          ></path>{" "}
                        </g>
                      </svg>
                    </span>
                    {t("unallocated")}
                  </div>
                )}
              </div>
            </div>
            <div
              className="btn btn-sm btn-icon btn-active-color-primary justify-content-end"
              onClick={() => closeModal()}
            >
              <span className="svg-icon svg-icon-1">
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.5"
                    x={6}
                    y="17.3137"
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  />
                  <rect
                    x="7.41422"
                    y={6}
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div className="modal-body p-6">
            <div className="w-100">
              <div className="pb-1">
                <label className="form-label mb-2">
                  <span>{t("chose_allocation_type")}</span>
                </label>
              </div>
              <div className="fv-row">
                <div className="row">
                  <div className="col-lg-6">
                    <input
                      type="checkbox"
                      className="btn-check"
                      name="allocation_type"
                      defaultValue="users"
                      checked
                      id="allocate-number-form-allocation_type-users"
                      onClick={() => {
                        setShowDiv(1);
                        users.display === 0 && fetchUsersData();
                      }}
                    />
                    <label
                      className={`btn btn-outline height-100 ${
                        showDiv === 1
                          ? "btn-outline-dashed btn-active-light-primary"
                          : ""
                      } p-3 d-flex align-items-center`}
                      htmlFor="allocate-number-form-allocation_type-users"
                    >
                      <span className="svg-icon svg-icon-3x me-5">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z"
                            fill="currentColor"
                          />
                          <rect
                            opacity="0.3"
                            x={8}
                            y={3}
                            width={8}
                            height={8}
                            rx={4}
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                      <span className="d-block fw-semibold text-start">
                        <span className="text-dark fw-bold d-block fs-4 mb-0">
                          {t("persons")}
                        </span>
                        <span className="text-muted fw-semibold fs-6">
                          {t("allocate_to_team")}.
                        </span>
                      </span>
                    </label>
                  </div>
                  <div className="col-lg-6">
                    <input
                      type="checkbox"
                      className="btn-check"
                      name="allocation_type"
                      checked
                      id="allocate-number-form-allocation_type-servers"
                      onClick={() => {
                        setShowDiv(2);
                        servers.display === 0 && fetchServersData();
                      }}
                    />
                    <label
                      className={`btn btn-outline p-3 d-flex align-items-center height-100 ${
                        showDiv === 2
                          ? "btn-outline-dashed btn-active-light-primary"
                          : ""
                      }`}
                      htmlFor="allocate-number-form-allocation_type-servers"
                    >
                      <span className="svg-icon svg-icon-3x me-5">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.3"
                            d="M19 18C20.7 18 22 16.7 22 15C22 13.3 20.7 12 19 12C18.9 12 18.9 12 18.8 12C18.9 11.7 19 11.3 19 11C19 9.3 17.7 8 16 8C15.4 8 14.8 8.2 14.3 8.5C13.4 7 11.8 6 10 6C7.2 6 5 8.2 5 11C5 11.3 5.00001 11.7 5.10001 12H5C3.3 12 2 13.3 2 15C2 16.7 3.3 18 5 18H19Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                      <span className="d-block fw-semibold text-start">
                        <span className="text-dark fw-bold d-block fs-4 mb-0">
                          {t("servers")}
                        </span>
                        <span className="text-muted fw-semibold fs-6">
                          {t("link_to_server")}.
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className={`w-100 pt-5 ${showDiv === 1 ? "" : "div_hide"}`}>
              <div className="pb-1">
                <label
                  className="form-label mb-2"
                  htmlFor="allocate-number-form-users"
                >
                  <span>{t("users")}</span>
                </label>
              </div>
              {users.display === 2 ? (
                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">
                  <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="0.3"
                        x={2}
                        y={2}
                        width={20}
                        height={20}
                        rx={10}
                        fill="currentColor"
                      />
                      <rect
                        x={11}
                        y={14}
                        width={7}
                        height={2}
                        rx={1}
                        transform="rotate(-90 11 14)"
                        fill="currentColor"
                      />
                      <rect
                        x={11}
                        y={17}
                        width={2}
                        height={2}
                        rx={1}
                        transform="rotate(-90 11 17)"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  <div className="d-flex flex-stack flex-grow-1">
                    <div className="fw-semibold">
                      <h4 className="text-gray-900 fw-bold">
                        {t("there_is_no_users_yet")}.
                      </h4>
                      <div className="fs-6 text-gray-700">
                        {t("users_list_is_empty")}, {t("create_user_from_here")}{" "}
                        <Link to={`${process.env.REACT_APP_BASENAME}users_create`} className="fw-bold ">
                          {t("link")}.
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ) : users.display === 1 ? (
                <div className="fv-row h-44px">
                  <div
                    className="form-select form-select-transparent cursor-pointer position-relative users-inp selected-numbers h-100"
                    onClick={() => setMenuOpen(!menuOpen)}
                    onMouseLeave={() => setMenuOpen(false)}
                  >
                    {reqBody.users.map((item) => {
                      return users.data
                        .filter((element) => element.id === item)
                        .map((element) => (
                          <span key={element.id}>
                            <span>{element.extension}</span>
                          </span>
                        ));
                    })}

                    <div
                      className={`menu-flag-drop-down left-0 top-35 ${
                        !menuOpen && "display-none"
                      }`}
                    >
                      {users.data.map((item) => (
                        <div
                          onClick={() => handleSelectUser(item.id)}
                          className={`${
                            reqBody.users.find((user) => user === item.id) &&
                            "number-selected"
                          }`}
                        >
                          <div>{item.extension}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className={`w-100 mt-5 ${showDiv === 2 ? "" : "div_hide"}`}>
              <div className="pb-1">
                <label
                  className="form-label mb-2"
                  htmlFor="allocate-number-form-server_ip"
                >
                  <span>{t("server_ip")}</span>
                </label>
              </div>
              {servers.display === 2 ? (
                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">
                  <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="0.3"
                        x={2}
                        y={2}
                        width={20}
                        height={20}
                        rx={10}
                        fill="currentColor"
                      />
                      <rect
                        x={11}
                        y={14}
                        width={7}
                        height={2}
                        rx={1}
                        transform="rotate(-90 11 14)"
                        fill="currentColor"
                      />
                      <rect
                        x={11}
                        y={17}
                        width={2}
                        height={2}
                        rx={1}
                        transform="rotate(-90 11 17)"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  <div className="d-flex flex-stack flex-grow-1">
                    <div className="fw-semibold">
                      <h4 className="text-gray-900 fw-bold">
                        {t("there_is_no_users_yet")}.
                      </h4>
                      <div className="fs-6 text-gray-700">
                        {t("users_list_is_empty")}, {t("create_user_from_here")}{" "}
                        <Link to={`${process.env.REACT_APP_BASENAME}users_create`} className="fw-bold ">
                          {t("link")}.
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ) : servers.display === 1 ? (
                <div className="fv-row h-44px">
                  <div
                    className="form-select form-select-transparent cursor-pointer position-relative users-inp selected-numbers h-100"
                    onClick={() => setMenuOpen(!menuOpen)}
                    onMouseLeave={() => setMenuOpen(false)}
                  >
                    {reqBody.users.map((item) => {
                      return servers.data
                        .filter((element) => element.id === item)
                        .map((element) => (
                          <span key={element.id}>
                            <span>{element.extension}</span>
                          </span>
                        ));
                    })}

                    <div
                      className={`menu-flag-drop-down left-0 top-35 ${
                        !menuOpen && "display-none"
                      }`}
                    >
                      {servers.data.map((item) => (
                        <div
                          onClick={() => handleSelectUser(item.id)}
                          className={`${
                            reqBody.users.find((user) => user === item.id) &&
                            "number-selected"
                          }`}
                        >
                          <div>{item.extension}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="d-flex justify-content-end p-6 footer-border-top">
            <SecondaryButton title={t("cancel")} onClick={() => closeModal()} />
            <div
              onClick={() => confirmationSwal(updateExtentions)}
              className={`btn btn-primary-active btn-sm ${
                showDiv === 0 && "disabled-event"
              }`}
            >
              <span> {t("submit")}</span>
              <span className="indicator-progress">
                {t("please_wait")}
                <span className="spinner-border spinner-border-sm align-middle ms-2" />
              </span>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal-hide-div"
        // onClick={() => {
        //   closeModal();
        // }}
      ></div>
    </div>
  );
};

export default AllocateUserModal;
