import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import { confirmationSwal } from "../../../../components/Alerts/confirmationAlert";
import fetchCurrencyExchange from "../../../../services/currencyExchange";
import ErrorSwal from "../../../../components/Alerts/errorAlert";
import SpinnerDiv from "../../../../Layouts/SpinnerDiv";
import { addPayment } from "../../Services/addPayment";
import ErrorExpiredSession from "../../../../components/Alerts/errorExpiredSession";
import InternalErrorSwal from "../../../../components/Alerts/internalErrorAlert";
import { successSwall } from "../../../../components/Alerts/successAlert";

const PaymentFormModal = ({
  closeModal,
  numberDesc,
  price,
  setSpinner,
  resourceId,
  numberType,
  features,
  addons,
  selectedScopeCountries,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const [exchanges, setExchanges] = useState({
    TND: null,
    USD: null,
    EUR: null,
  });

  const getExchange = async () => {
    try {
      setLoading(true);
      const response = await fetchCurrencyExchange("USD");
      setLoading(false);

      if (!response.ok) {
        throw new Error(response.status);
      }
      const result = await response.json();
      setExchanges({
        USD: result.rates.USD,
        TND: result.rates.TND,
        EUR: result.rates.EUR,
      });
    } catch (error) {
      ErrorSwal(t("errors.500"));
    }
  };

  useEffect(() => {
    getExchange();
  }, []);

  const credit_currencies = ["USD"];

  useEffect(() => {
    setReqBody((prevData) => ({ ...prevData, resource_id: resourceId }));
  }, [resourceId]);

  const [reqBody, setReqBody] = useState({
    method: null,
    amount: price,
    currency: credit_currencies[0],
    paid_amount: null,
    paid_currency: null,
    reference: "",
    resource_id: null,
    "files['PAYMENTS_DOCUMENTS']": null,
  });

  const paymentMethods = [
    { id: 2, name: t("cards.tn-post") },
    { id: 3, name: t("cards.credit-card") },
    { id: 5, name: "PayPal" },
    { id: 7, name: t("cards.check") },
    { id: 8, name: t("cards.bank-transfer") },
  ];

  const paid_currencies = ["USD", "EUR", "TND"];

  const convertCurrency = () => {
    let rate;

    if (reqBody.method !== 2 && reqBody.method !== 8) {
      if (reqBody.paid_currency === "EUR") {
        rate = exchanges.EUR;
      } else if (reqBody.paid_currency === "TND") {
        rate = exchanges.TND;
      } else if (reqBody.paid_currency === "USD") {
        rate = exchanges.USD;
      }
      const converted = parseFloat(reqBody.amount) * rate;
      changeReqBody(
        "paid_amount",
        !reqBody.paid_currency ? "" : parseFloat(converted.toFixed(3))
      );
    } else {
      rate = exchanges.TND;
      const converted = parseFloat(reqBody.amount) * rate;
      changeReqBody("paid_amount", parseFloat(converted.toFixed(3)));
    }
  };

  useEffect(() => {
    convertCurrency();
  }, [reqBody.paid_currency, reqBody.amount]);

  const [menuOpen, setMenuOpen] = useState({
    credit: false,
    users: false,
    credit_amount: false,
  });

  const changeReqBody = (item, value) => {
    setReqBody((prevData) => ({ ...prevData, [item]: value }));
  };

  const confirmPayment = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("resource_id", reqBody.resource_id);
      formData.append("method", reqBody.method);
      formData.append("amount", reqBody.amount);
      formData.append("currency", reqBody.currency);
      formData.append("paid_amount", reqBody.paid_amount);
      formData.append("paid_currency", reqBody.paid_currency);
      formData.append("user_id", reqBody.user);
      reqBody.reference && formData.append("references", reqBody.reference);
      reqBody["files['PAYMENTS_DOCUMENTS']"] &&
        formData.append(
          "files['PAYMENTS_DOCUMENTS']",
          reqBody["files['PAYMENTS_DOCUMENTS']"]
        );
      const response = await addPayment(formData);
      if (!response.ok) {
        throw new Error(response.status);
      }
      successSwall(t("payment_succ_added"));
      setTimeout(() => {
        window.location.href = `${process.env.REACT_APP_LOCAL}/numbers_list`;
      }, 2000);
      setLoading(false);
    } catch (error) {
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else {
        InternalErrorSwal();
      }
    }
  };

  const payment_form = useRef();

  const submitForm = async () => {
    if (reqBody.method === 2 || reqBody.method === 8) {
      confirmPayment();
    } else {
      setSpinner(true);
      window.open("_blank", "payment_form", "width=800,height=600");
      payment_form.current.submit();
    }
  };

  const user_country = localStorage.getItem("country");

  return (
    <div>
      <div className={`modal-container pay-order-modal fade-in-down`}>
        <div className={`modal-show`}>
          <div className="modal-header p-6">
            <div className="d-flex flex-column gap-4">
              <h3>{t("payment_form")}</h3>
            </div>
            <div
              className="btn btn-sm btn-icon btn-active-color-primary justify-content-end"
              onClick={() => closeModal()}
            >
              <span className="svg-icon svg-icon-1">
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.5"
                    x={6}
                    y="17.3137"
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  />
                  <rect
                    x="7.41422"
                    y={6}
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
          </div>
          {loading && <SpinnerDiv />}
          {user_country === "TN" ? (
            <div
              className={`modal-body p-6 d-flex align-items-start ${
                loading && "disabled-event"
              }`}
            >
              <div className="left-summary">
                <div className="card-body p-3 m-3 bg-summary fs-6">
                  <h5 className="mb-3">{t("order_details")}</h5>
                  <div className="flex-column d-flex gap-3">
                    <div className="fw-semibold text-gray-600 d-flex align-items-md-center">
                      <span className="fs-8 min-w-xl-60px">
                        {t("quantity")}:
                      </span>
                      <span>{numberDesc.quantity}</span>
                    </div>

                    {numberType && (
                      <div className="fw-semibold text-gray-600 d-flex align-items-md-center">
                        <span className="fs-8 min-w-xl-60px">{t("type")}:</span>
                        <span className="badge badge-neutral me-2">
                          {t(`services.${numberType}`)}
                        </span>
                      </div>
                    )}

                    {numberDesc.country && (
                      <div className="fw-semibold text-gray-600 d-flex align-items-md-center">
                        <span className="fs-8 min-w-xl-60px">
                          {t("country")}:
                        </span>
                        <div className="w-100 d-flex align-items-md-center gap-1">
                          <img
                            className="flag-dim"
                            src={`/assets/media/flags/${numberDesc.country.toLowerCase()}.svg`}
                            alt={numberDesc.country}
                          />
                          <span>{t(`countries.${numberDesc.country}`)}</span>
                        </div>{" "}
                      </div>
                    )}

                    {numberDesc.features.length > 0 && (
                      <div className="fw-semibold text-gray-600 d-flex align-items-start">
                        <span className="fs-8 min-w-xl-60px">
                          {t("features")}:
                        </span>
                        <div className="w-100 d-flex align-items-md-center gap-1 flex-wrap">
                          {numberDesc.features.map((item, key) => (
                            <span
                              key={key}
                              className="badge badge-neutral me-2"
                            >
                              {features
                                .find((feature) => feature.service_id === item)
                                .tagcode.includes("callin")
                                ? t("callin")
                                : features
                                    .find(
                                      (feature) => feature.service_id === item
                                    )
                                    .tagcode.includes("callout")
                                ? t("callout")
                                : features
                                    .find(
                                      (feature) => feature.service_id === item
                                    )
                                    .tagcode.includes("smsin")
                                ? t("smsin")
                                : features
                                    .find(
                                      (feature) => feature.service_id === item
                                    )
                                    .tagcode.includes("smsout")
                                ? t("smsout")
                                : ""}
                            </span>
                          ))}
                        </div>{" "}
                      </div>
                    )}

                    {addons.length > 0 && (
                      <div className="fw-semibold text-gray-600 d-flex align-items-start">
                        <span className="fs-8 min-w-xl-60px">
                          {t("addons")}:
                        </span>
                        <div className="w-100 d-flex align-items-md-center gap-1 flex-wrap">
                          {addons.map((item, key) => (
                            <span
                              key={key}
                              className="badge badge-neutral me-2"
                            >
                              {t(item)}
                            </span>
                          ))}
                        </div>{" "}
                      </div>
                    )}
                  </div>

                  {numberDesc &&
                    numberDesc.outbound_scope &&
                    numberDesc.outbound_scope.length > 0 && (
                      <div>
                        <div className="separator separator-dashed mb-7 mt-7" />{" "}
                        <h5 className="mb-3">{t("usage")}</h5>
                        <div className="flex-column d-flex gap-3">
                          <div className="fw-semibold text-gray-600 d-flex gap-1 flex-column align-items-start ">
                            <span className="fs-8 min-w-xl-80px">
                              {t("outbound_calling_scope")}:
                            </span>
                            <div className="flex-wrap d-flex gap-1">
                              {numberDesc.outbound_scope.length > 0 &&
                                selectedScopeCountries.map((countryScope) => (
                                  <div
                                    className="d-flex align-items-md-center gap-1 country-container"
                                    key={countryScope.iso_code}
                                  >
                                    <img
                                      className="outbound-flag"
                                      src={`/assets/media/flags/${countryScope.iso_code.toLowerCase()}.svg`}
                                      alt={numberDesc.country}
                                    />
                                    <span className="fs-8">
                                      {t(`countries.${countryScope.iso_code}`)}
                                    </span>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>

              <div className="w-100">
                <div className="w-100 mb-10">
                  <div className="pb-5 pb-lg-3">
                    <label className="form-label mb-2">
                      <span className="required">
                        {t("chose_payment_method")}
                      </span>
                    </label>
                  </div>
                  <div className="fv-row">
                    <div className="row">
                      {paymentMethods.map((item) => (
                        <div
                          className={`col-lg-3-5 ${
                            (item.id === 5 || item.id === 7) && "disabled-event"
                          }`}
                          key={item.id}
                        >
                          <input
                            type="radio"
                            className="btn-check"
                            name="allocation_type"
                            defaultValue="users"
                            defaultChecked={reqBody.method === item.id}
                            id={`allocate-number-form-allocation_type-${item.id}`}
                          />
                          <label
                            className="btn btn-outline btn-outline-dashed btn-active-light-primary p-3 d-flex align-items-center"
                            htmlFor={`allocate-number-form-allocation_type-${item.id}`}
                            onClick={() => {
                              changeReqBody("method", item.id);
                              changeReqBody("paid_amount", "");
                              if (item.id === 2) {
                                changeReqBody("paid_currency", "TND");
                                // changeReqBody("currency", "TND");
                              } else {
                                changeReqBody("paid_currency", "");
                                //   changeReqBody("amount", 0);
                                // changeReqBody("user", "");
                              }
                            }}
                          >
                            <span className="d-flex flex-center w-100">
                              <span className="text-dark fw-bold d-block fs-7 mb-0">
                                {item.name}
                              </span>
                            </span>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {reqBody.method === 2 || reqBody.method === 8 ? (
                  <div>
                    <div className={`line-divs-container mb-10`}>
                      <div className="w-50">
                        <div className="pb-5 pb-lg-3">
                          <label className="form-label mb-2">
                            <span className="required">
                              {t("credit_currency")}
                            </span>
                          </label>
                        </div>
                        <div className="fv-row">
                          <div className="row">
                            <div className="d-flex gap-2">
                              <div
                                className={`currency-div position-relative`}
                                onClick={() =>
                                  setMenuOpen((prevData) => ({
                                    ...prevData,
                                    credit: !prevData.credit,
                                  }))
                                }
                                onMouseLeave={() =>
                                  setMenuOpen((prevData) => ({
                                    ...prevData,
                                    credit: false,
                                  }))
                                }
                              >
                                <span>{reqBody.currency}</span>
                                <svg
                                  className="svg-menu-currency"
                                  viewBox="0 0 1024 1024"
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="#000000"
                                >
                                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                  <g
                                    id="SVGRepo_tracerCarrier"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <g id="SVGRepo_iconCarrier">
                                    <path
                                      d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                                      fill="#727272"
                                    />
                                  </g>
                                </svg>

                                {menuOpen.credit && (
                                  <div>
                                    {credit_currencies.map((item, key) => (
                                      <span
                                        key={key}
                                        onClick={() => {
                                          changeReqBody("currency", item);
                                        }}
                                      >
                                        {item}
                                      </span>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-50">
                        <div className="pb-5 pb-lg-3">
                          <label className="form-label mb-2">
                            <span className="required">
                              {t("credit_amount")}
                            </span>
                          </label>
                        </div>
                        <div className="fv-row">
                          <div className="row">
                            <div className="d-flex gap-2">
                              <div className={`currency-div position-relative`}>
                                <span>
                                  {reqBody.amount !== "NaN"
                                    ? reqBody.amount
                                    : 0}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`line-divs-container`}>
                      <div className="w-50">
                        <div className="pb-5 pb-lg-3">
                          <label className="form-label mb-2">
                            <span className="required">
                              {t("paid_currency")}
                            </span>
                          </label>
                        </div>
                        <div className="fv-row">
                          <div className="row">
                            <div className="d-flex gap-2">
                              <div
                                className={`currency-div position-relative`}
                                onClick={() =>
                                  setMenuOpen((prevData) => ({
                                    ...prevData,
                                    paid: !prevData.paid,
                                  }))
                                }
                                onMouseLeave={() =>
                                  setMenuOpen((prevData) => ({
                                    ...prevData,
                                    paid: false,
                                  }))
                                }
                              >
                                <span>TND</span>

                                <svg
                                  className="svg-menu-currency"
                                  viewBox="0 0 1024 1024"
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="#000000"
                                >
                                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                  <g
                                    id="SVGRepo_tracerCarrier"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <g id="SVGRepo_iconCarrier">
                                    <path
                                      d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                                      fill="#727272"
                                    />
                                  </g>
                                </svg>

                                {menuOpen.paid && (
                                  <div>
                                    <span>TND</span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-50">
                        <div className="pb-5 pb-lg-3">
                          <label className="form-label mb-2">
                            <span className="required">{t("paid_amount")}</span>
                          </label>
                        </div>
                        <div className="fv-row">
                          <div className="row">
                            <div className="d-flex gap-2">
                              <input
                                type="text"
                                value={reqBody.paid_amount}
                                className={`form-control form-control-sm form-control-solid pointer-event-none`}
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(
                                    /\D/g,
                                    ""
                                  );
                                }}
                                onChange={(e) =>
                                  changeReqBody("paid_amount", e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="line-divs-container mt-10">
                      <div
                        className={`w-50 ${
                          !reqBody.paid_amount && "disabled-event"
                        }`}
                      >
                        <div className="pb-5 pb-lg-3">
                          <label className="form-label mb-2">
                            <span className="required">{t("reference")}</span>
                          </label>
                        </div>
                        <div className="fv-row">
                          <div className="row">
                            <div className="d-flex">
                              <input
                                value={
                                  reqBody.reference ? reqBody.reference : ""
                                }
                                type="text"
                                className={`form-control form-control-sm form-control-solid`}
                                onChange={(e) =>
                                  changeReqBody("reference", e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`w-50 ${
                          !reqBody.paid_amount && "disabled-event"
                        }`}
                      >
                        <div className={`pb-5 pb-lg-3`}>
                          <label className="form-label mb-2">
                            <span>{t("receipt_doc")}</span>
                          </label>
                        </div>
                        <div className="fv-row">
                          <div className="row">
                            <div className="">
                              <div
                                className={`upload-receipt m-w-50 cursor-pointer position-relative`}
                              >
                                <svg
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g
                                    id="SVGRepo_bgCarrier"
                                    stroke-width="0"
                                  ></g>
                                  <g
                                    id="SVGRepo_tracerCarrier"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></g>
                                  <g id="SVGRepo_iconCarrier">
                                    {" "}
                                    <path
                                      d="M20.5348 3.46447C19.0704 2 16.7133 2 11.9993 2C7.28525 2 4.92823 2 3.46377 3.46447C2.70628 4.22195 2.3406 5.21824 2.16406 6.65598C2.69473 6.06532 3.33236 5.57328 4.04836 5.20846C4.82984 4.81027 5.66664 4.6488 6.59316 4.5731C7.48829 4.49997 8.58971 4.49998 9.93646 4.5H14.0621C15.4089 4.49998 16.5103 4.49997 17.4054 4.5731C18.332 4.6488 19.1688 4.81027 19.9502 5.20846C20.6662 5.57328 21.3039 6.06532 21.8345 6.65598C21.658 5.21824 21.2923 4.22195 20.5348 3.46447Z"
                                      fill="currentColor"
                                    ></path>{" "}
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M2 14C2 11.1997 2 9.79961 2.54497 8.73005C3.02433 7.78924 3.78924 7.02433 4.73005 6.54497C5.79961 6 7.19974 6 10 6H14C16.8003 6 18.2004 6 19.27 6.54497C20.2108 7.02433 20.9757 7.78924 21.455 8.73005C22 9.79961 22 11.1997 22 14C22 16.8003 22 18.2004 21.455 19.27C20.9757 20.2108 20.2108 20.9757 19.27 21.455C18.2004 22 16.8003 22 14 22H10C7.19974 22 5.79961 22 4.73005 21.455C3.78924 20.9757 3.02433 20.2108 2.54497 19.27C2 18.2004 2 16.8003 2 14ZM12.5303 10.4697C12.3897 10.329 12.1989 10.25 12 10.25C11.8011 10.25 11.6103 10.329 11.4697 10.4697L8.96967 12.9697C8.67678 13.2626 8.67678 13.7374 8.96967 14.0303C9.26256 14.3232 9.73744 14.3232 10.0303 14.0303L11.25 12.8107V17C11.25 17.4142 11.5858 17.75 12 17.75C12.4142 17.75 12.75 17.4142 12.75 17V12.8107L13.9697 14.0303C14.2626 14.3232 14.7374 14.3232 15.0303 14.0303C15.3232 13.7374 15.3232 13.2626 15.0303 12.9697L12.5303 10.4697Z"
                                      fill="currentColor"
                                    ></path>{" "}
                                  </g>
                                </svg>
                                <span>
                                  {reqBody["files['PAYMENTS_DOCUMENTS']"]
                                    ? reqBody["files['PAYMENTS_DOCUMENTS']"].name
                                    : t("upload_file")}
                                </span>
                                
                                <input
                                  type="file"
                                  name="avatar"
                                  accept=".png, .jpg, .jpeg"
                                  className=" opacity-0 position-absolute w-100 cursor-pointer"
                                  onChange={(e) => {
                                    changeReqBody(
                                      "files['PAYMENTS_DOCUMENTS']",
                                      e.target.files[0]
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div
                      className={`line-divs-container mb-10 ${
                        !reqBody.method && "disabled-event"
                      }`}
                    >
                      <div className="w-50">
                        <div className="pb-5 pb-lg-3">
                          <label className="form-label mb-2">
                            <span className="required">
                              {t("credit_currency")}
                            </span>
                          </label>
                        </div>
                        <div className="fv-row">
                          <div className="row">
                            <div className="d-flex gap-2">
                              <div
                                className={`currency-div position-relative`}
                                onClick={() =>
                                  setMenuOpen((prevData) => ({
                                    ...prevData,
                                    credit: !prevData.credit,
                                  }))
                                }
                                onMouseLeave={() =>
                                  setMenuOpen((prevData) => ({
                                    ...prevData,
                                    credit: false,
                                  }))
                                }
                              >
                                <span>{credit_currencies[0]}</span>
                                <svg
                                  className="svg-menu-currency"
                                  viewBox="0 0 1024 1024"
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="#000000"
                                >
                                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                  <g
                                    id="SVGRepo_tracerCarrier"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <g id="SVGRepo_iconCarrier">
                                    <path
                                      d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                                      fill="#727272"
                                    />
                                  </g>
                                </svg>

                                {menuOpen.credit && (
                                  <div>
                                    {credit_currencies.map((item, key) => (
                                      <span
                                        key={key}
                                        onClick={() => {
                                          changeReqBody("currency", item);
                                        }}
                                      >
                                        {item}
                                      </span>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-50">
                        <div className="pb-5 pb-lg-3">
                          <label className="form-label mb-2">
                            <span className="required">
                              {t("credit_amount")}
                            </span>
                          </label>
                        </div>
                        <div className="fv-row">
                          <div className="row">
                            <div className="d-flex gap-2">
                              <div
                                className={`currency-div position-relative pointer-event-none`}
                                onClick={() =>
                                  setMenuOpen((prevData) => ({
                                    ...prevData,
                                    credit_amount: !prevData.credit_amount,
                                  }))
                                }
                                onMouseLeave={() =>
                                  setMenuOpen((prevData) => ({
                                    ...prevData,
                                    credit_amount: false,
                                  }))
                                }
                              >
                                <span>
                                  {reqBody.method &&
                                    reqBody.method !== 2 &&
                                    reqBody.amount}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`line-divs-container ${
                        (!reqBody.method || !reqBody.amount) && "disabled-event"
                      }`}
                    >
                      <div className="w-50">
                        <div className="pb-5 pb-lg-3">
                          <label className="form-label mb-2">
                            <span className="required">
                              {t("paid_currency")}
                            </span>
                          </label>
                        </div>
                        <div className="fv-row">
                          <div className="row">
                            <div className="d-flex gap-2">
                              <div
                                className={`currency-div position-relative`}
                                onClick={() =>
                                  setMenuOpen((prevData) => ({
                                    ...prevData,
                                    paid: !prevData.paid,
                                  }))
                                }
                                onMouseLeave={() =>
                                  setMenuOpen((prevData) => ({
                                    ...prevData,
                                    paid: false,
                                  }))
                                }
                              >
                                <span>
                                  {reqBody.method === 2 || reqBody.method === 8
                                    ? "TND"
                                    : reqBody.paid_currency}
                                </span>

                                <svg
                                  className="svg-menu-currency"
                                  viewBox="0 0 1024 1024"
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="#000000"
                                >
                                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                  <g
                                    id="SVGRepo_tracerCarrier"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <g id="SVGRepo_iconCarrier">
                                    <path
                                      d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                                      fill="#727272"
                                    />
                                  </g>
                                </svg>

                                {menuOpen.paid && (
                                  <div>
                                    {reqBody.method === 2 ||
                                    reqBody.method === 8
                                      ? paid_currencies
                                          .filter((el) => el === "TND")
                                          .map((item, key) => (
                                            <span key={key}>{item}</span>
                                          ))
                                      : paid_currencies
                                          .filter((el) => el !== "TND")
                                          .map((item, key) => (
                                            <span
                                              key={key}
                                              onClick={() => {
                                                changeReqBody(
                                                  "paid_currency",
                                                  item
                                                );
                                              }}
                                            >
                                              {item}
                                            </span>
                                          ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-50">
                        <div className="pb-5 pb-lg-3">
                          <label className="form-label mb-2">
                            <span className="required">{t("paid_amount")}</span>
                          </label>
                        </div>
                        <div className="fv-row">
                          <div className="row">
                            <div className="d-flex gap-2">
                              <input
                                type="text"
                                value={reqBody.paid_amount}
                                className={`form-control form-control-sm form-control-solid ${
                                  reqBody.method !== 2 && "pointer-event-none"
                                }`}
                                onChange={(e) =>
                                  changeReqBody("paid_amount", e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* <div
              className={`w-100 mb-7 ${
                !reqBody.paid_amount && "disabled-event"
              }`}
            >
              <label className="form-label pb-5">{t("user")}</label>
              <div className="fv-row">
                <div className="row">
                  <div className="d-flex gap-2">
                    {users.display === 2 ? (
                      <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6 w-100">
                        <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              opacity="0.3"
                              x={2}
                              y={2}
                              width={20}
                              height={20}
                              rx={10}
                              fill="currentColor"
                            />
                            <rect
                              x={11}
                              y={14}
                              width={7}
                              height={2}
                              rx={1}
                              transform="rotate(-90 11 14)"
                              fill="currentColor"
                            />
                            <rect
                              x={11}
                              y={17}
                              width={2}
                              height={2}
                              rx={1}
                              transform="rotate(-90 11 17)"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                        <div className="d-flex flex-stack flex-grow-1">
                          <div className="fw-semibold">
                            <h4 className="text-gray-900 fw-bold">
                              {t("there_is_no_users_yet")}.
                            </h4>
                            <div className="fs-6 text-gray-700">
                              {t("users_list_is_empty")},{" "}
                              {t("create_user_from_here")}{" "}
                              <Link to="/users_create" className="fw-bold ">
                                {t("link")}.
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : users.display === 1 ? (
                      <div className={`fv-row h-44px w-100`}>
                        <div
                          className="currency-div position-relative false"
                          onClick={() =>
                            !menuOpen.users &&
                            setMenuOpen((prevData) => ({
                              ...prevData,
                              users: true,
                            }))
                          }
                          onMouseLeave={() =>
                            setMenuOpen((prevData) => ({
                              ...prevData,
                              users: false,
                            }))
                          }
                        >
                          <span>
                            {reqBody.user &&
                            users.data.find(
                              (item) => item.id === reqBody.user
                            ).name
                              ? reqBody.user &&
                                users.data.find(
                                  (item) => item.id === reqBody.user
                                ).name
                              : reqBody.user &&
                                users.data.find(
                                  (item) => item.id === reqBody.user
                                ).extension}
                          </span>{" "}
                          <svg
                            className="svg-menu-currency"
                            viewBox="0 0 1024 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="#000000"
                          >
                            <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                            <g
                              id="SVGRepo_tracerCarrier"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <g id="SVGRepo_iconCarrier">
                              <path
                                d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                                fill="#727272"
                              />
                            </g>
                          </svg>
                          <div
                            className={`menu-flag-drop-down left-0 top-35 ${
                              !menuOpen.users && "display-none"
                            }`}
                          >
                            {users.data.map((item) => (
                              <div
                                onClick={() => {
                                  changeReqBody("user", item.id);
                                  setMenuOpen((prevData) => ({
                                    ...prevData,
                                    users: false,
                                  }));
                                }}
                                className={`${
                                  item.id === reqBody.user &&
                                  "number-selected"
                                }`}
                              >
                                <div>
                                  {item.name ? item.name : item.extension}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div> */}
              </div>
            </div>
          ) : (
            <div
              className={`modal-body p-6 d-flex align-items-start ${
                loading && "disabled-event"
              }`}
            >
              <div className="left-summary">
                <div className="card-body p-3 m-3 bg-summary fs-6">
                  <h5 className="mb-3">{t("order_details")}</h5>
                  <div className="flex-column d-flex gap-3">
                    <div className="fw-semibold text-gray-600 d-flex align-items-md-center">
                      <span className="fs-8 min-w-xl-60px">
                        {t("quantity")}:
                      </span>
                      <span>{numberDesc.quantity}</span>
                    </div>

                    {numberType && (
                      <div className="fw-semibold text-gray-600 d-flex align-items-md-center">
                        <span className="fs-8 min-w-xl-60px">{t("type")}:</span>
                        <span className="badge badge-neutral me-2">
                          {t(`services.${numberType}`)}
                        </span>
                      </div>
                    )}

                    {numberDesc.country && (
                      <div className="fw-semibold text-gray-600 d-flex align-items-md-center">
                        <span className="fs-8 min-w-xl-60px">
                          {t("country")}:
                        </span>
                        <div className="w-100 d-flex align-items-md-center gap-1">
                          <img
                            className="flag-dim"
                            src={`/assets/media/flags/${numberDesc.country.toLowerCase()}.svg`}
                            alt={numberDesc.country}
                          />
                          <span>{t(`countries.${numberDesc.country}`)}</span>
                        </div>{" "}
                      </div>
                    )}

                    {numberDesc.features.length > 0 && (
                      <div className="fw-semibold text-gray-600 d-flex align-items-start">
                        <span className="fs-8 min-w-xl-60px">
                          {t("features")}:
                        </span>
                        <div className="w-100 d-flex align-items-md-center gap-1 flex-wrap">
                          {numberDesc.features.map((item, key) => (
                            <span
                              key={key}
                              className="badge badge-neutral me-2"
                            >
                              {features
                                .find((feature) => feature.service_id === item)
                                .tagcode.includes("callin")
                                ? t("callin")
                                : features
                                    .find(
                                      (feature) => feature.service_id === item
                                    )
                                    .tagcode.includes("callout")
                                ? t("callout")
                                : features
                                    .find(
                                      (feature) => feature.service_id === item
                                    )
                                    .tagcode.includes("smsin")
                                ? t("smsin")
                                : features
                                    .find(
                                      (feature) => feature.service_id === item
                                    )
                                    .tagcode.includes("smsout")
                                ? t("smsout")
                                : ""}
                            </span>
                          ))}
                        </div>{" "}
                      </div>
                    )}

                    {addons.length > 0 && (
                      <div className="fw-semibold text-gray-600 d-flex align-items-start">
                        <span className="fs-8 min-w-xl-60px">
                          {t("addons")}:
                        </span>
                        <div className="w-100 d-flex align-items-md-center gap-1 flex-wrap">
                          {addons.map((item, key) => (
                            <span
                              key={key}
                              className="badge badge-neutral me-2"
                            >
                              {t(item)}
                            </span>
                          ))}
                        </div>{" "}
                      </div>
                    )}
                  </div>

                  {numberDesc &&
                    numberDesc.outbound_scope &&
                    numberDesc.outbound_scope.length > 0 && (
                      <div>
                        <div className="separator separator-dashed mb-7 mt-7" />{" "}
                        <h5 className="mb-3">{t("usage")}</h5>
                        <div className="flex-column d-flex gap-3">
                          <div className="fw-semibold text-gray-600 d-flex gap-1 flex-column align-items-start ">
                            <span className="fs-8 min-w-xl-80px">
                              {t("outbound_calling_scope")}:
                            </span>
                            <div className="flex-wrap d-flex gap-1">
                              {numberDesc.outbound_scope.length > 0 &&
                                selectedScopeCountries.map((countryScope) => (
                                  <div
                                    className="d-flex align-items-md-center gap-1 country-container"
                                    key={countryScope.iso_code}
                                  >
                                    <img
                                      className="outbound-flag"
                                      src={`/assets/media/flags/${countryScope.iso_code.toLowerCase()}.svg`}
                                      alt={numberDesc.country}
                                    />
                                    <span className="fs-8">
                                      {t(`countries.${countryScope.iso_code}`)}
                                    </span>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
              <div className="w-100">
                <div className="line-divs-container mb-10">
                  <div className="w-50">
                    <div className="pb-5 pb-lg-3">
                      <label className="form-label mb-2">
                        <span className="required">{t("credit_currency")}</span>
                      </label>
                    </div>
                    <div className="fv-row">
                      <div className="row">
                        <div className="d-flex gap-2">
                          <div
                            className={`currency-div position-relative`}
                            onClick={() =>
                              setMenuOpen((prevData) => ({
                                ...prevData,
                                credit: !prevData.credit,
                              }))
                            }
                            onMouseLeave={() =>
                              setMenuOpen((prevData) => ({
                                ...prevData,
                                credit: false,
                              }))
                            }
                          >
                            <span>{credit_currencies[0]}</span>
                            <svg
                              className="svg-menu-currency"
                              viewBox="0 0 1024 1024"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="#000000"
                            >
                              <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                              <g
                                id="SVGRepo_tracerCarrier"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <g id="SVGRepo_iconCarrier">
                                <path
                                  d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                                  fill="#727272"
                                />
                              </g>
                            </svg>

                            {menuOpen.credit && (
                              <div>
                                {credit_currencies.map((item, key) => (
                                  <span
                                    key={key}
                                    onClick={() => {
                                      changeReqBody("currency", item);
                                    }}
                                  >
                                    {item}
                                  </span>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-50">
                    <div className="pb-5 pb-lg-3">
                      <label className="form-label mb-2">
                        <span className="required">{t("credit_amount")}</span>
                      </label>
                    </div>
                    <div className="fv-row">
                      <div className="row">
                        <div className="d-flex gap-2">
                          <div
                            className={`currency-div position-relative`}
                            onClick={() =>
                              setMenuOpen((prevData) => ({
                                ...prevData,
                                credit_amount: !prevData.credit_amount,
                              }))
                            }
                            onMouseLeave={() =>
                              setMenuOpen((prevData) => ({
                                ...prevData,
                                credit_amount: false,
                              }))
                            }
                          >
                            <span>{reqBody.amount}</span>

                            {/* {menuOpen.credit_amount && (
                            <div>
                              {credits.map((item, key) => (
                                <span
                                  key={key}
                                  onClick={() => {
                                    changeReqBody("amount", item);
                                  }}
                                >
                                  {item}
                                </span>
                              ))}
                            </div>
                          )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={`line-divs-container mb-10 ${
                    reqBody.amount === 0 && "disabled-event"
                  }`}
                >
                  <div className="w-50">
                    <div className="pb-5 pb-lg-3">
                      <label className="form-label mb-2">
                        <span className="required">{t("paid_currency")}</span>
                      </label>
                    </div>
                    <div className="fv-row">
                      <div className="row">
                        <div className="d-flex gap-2">
                          <div
                            className={`currency-div position-relative`}
                            onClick={() =>
                              setMenuOpen((prevData) => ({
                                ...prevData,
                                paid: !prevData.paid,
                              }))
                            }
                            onMouseLeave={() =>
                              setMenuOpen((prevData) => ({
                                ...prevData,
                                paid: false,
                              }))
                            }
                          >
                            <span>{reqBody.paid_currency}</span>
                            <svg
                              className="svg-menu-currency"
                              viewBox="0 0 1024 1024"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="#000000"
                            >
                              <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                              <g
                                id="SVGRepo_tracerCarrier"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <g id="SVGRepo_iconCarrier">
                                <path
                                  d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                                  fill="#727272"
                                />
                              </g>
                            </svg>

                            {menuOpen.paid && (
                              <div>
                                {paid_currencies
                                  .filter((el) => el !== "TND")
                                  .map((item, key) => (
                                    <span
                                      key={key}
                                      onClick={() => {
                                        changeReqBody("paid_currency", item);
                                        reqBody.method &&
                                          changeReqBody("method", null);
                                      }}
                                    >
                                      {item}
                                    </span>
                                  ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-50">
                    <div className="pb-5 pb-lg-3">
                      <label className="form-label mb-2">
                        <span className="required">{t("paid_amount")}</span>
                      </label>
                    </div>
                    <div className="fv-row">
                      <div className="row">
                        <div className="d-flex gap-2">
                          <input
                            type="text"
                            value={
                              reqBody.paid_currency ? reqBody.paid_amount : ""
                            }
                            className={`form-control form-control-sm form-control-solid pointer-event-none`}
                            //   onChange={(e) =>
                            //     changeReqBody("amount", e.target.value)
                            //   }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={`w-100 ${
                    !reqBody.paid_amount && "disabled-event"
                  }`}
                >
                  <div className="pb-5 pb-lg-3">
                    <label className="form-label mb-2">
                      <span className="required">
                        {t("chose_payment_method")}
                      </span>
                    </label>
                  </div>
                  <div className="fv-row">
                    <div className="row">
                      {paymentMethods
                        .filter((el) => el.id !== 2)
                        .map((item) => (
                          <div
                            className={`col-lg-3 ${
                              item.id === 5 && "disabled-event"
                            }`}
                            key={item.id}
                          >
                            <input
                              type="radio"
                              className="btn-check"
                              name="allocation_type"
                              defaultValue="users"
                              defaultChecked={reqBody.method === item.id}
                              id={`allocate-number-form-allocation_type-${item.id}`}
                            />
                            <label
                              className="btn btn-outline btn-outline-dashed btn-active-light-primary p-3 d-flex align-items-center"
                              htmlFor={`allocate-number-form-allocation_type-${item.id}`}
                              onClick={() => changeReqBody("method", item.id)}
                            >
                              <span className="d-flex flex-center w-100">
                                <span className="text-dark fw-bold d-block fs-7 mb-0">
                                  {item.name}
                                </span>
                              </span>
                            </label>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className={`d-flex justify-content-end p-6 footer-border-top`}>
            <SecondaryButton title={t("cancel")} onClick={() => closeModal()} />
            <button
              type="submit"
              className={`btn btn-primary-active btn-sm ${
                ((reqBody.method == 2 &&
                  !reqBody["files['PAYMENTS_DOCUMENTS']"]) ||
                  (reqBody.method != 2 && !reqBody.paid_currency)) &&
                "disabled-event"
              }`}
              onClick={() => confirmationSwal(submitForm)}
            >
              <span> {t("pay_now")}</span>
            </button>
          </div>
        </div>
      </div>

      <form
        action="https://secure-payment.pro/checkout/p"
        method="POST"
        target="payment_form"
        ref={payment_form}
      >
        <input
          type="hidden"
          name="id_company"
          value={process.env.REACT_APP_COMPANY_ID}
        />
        <input
          type="hidden"
          name="id_shop"
          value={process.env.REACT_APP_SHOP_ID}
        />
        <input type="hidden" name="amount" value={reqBody.paid_amount} />
        <input type="hidden" name="currency" value={reqBody.paid_currency} />
        <input type="hidden" name="country" value={user_country} />
        <input
          type="hidden"
          name="last_name"
          value={localStorage.getItem("lastname")}
        />
        <input
          type="hidden"
          name="first_name"
          value={localStorage.getItem("firstname")}
        />
        <input
          type="hidden"
          name="email"
          value={localStorage.getItem("email_billing")}
        />
        <input type="hidden" name="product" value={""} />
      </form>

      <div
        className="modal-hide-div"
        // onClick={() => {
        //   closeModal();
        // }}
      ></div>
    </div>
  );
};

export default PaymentFormModal;
