import React, { useEffect, useRef, useState } from "react";

import { Link, useLocation } from "react-router-dom";
import Spinner from "../components/Spinner";
import { useTranslation } from "react-i18next";
import SpinnerSecondary from "../components/SpinnerSecondary";
import ReCAPTCHA from "react-google-recaptcha";
import completeInscriptionService from "./Services/completeInscriptionServices";
import LoginService from "./login.service";

const CompleteInscription = () => {
  const { t } = useTranslation();
  const [load, setLoad] = useState(false);
  const [updateResponse, setUpdatePassword] = useState(0);
  const location = useLocation();

  const url_username = decodeURIComponent(location.pathname.split("/").pop());

  const [reqError, setReqError] = useState(null);
  const [spinnerLoad, setSpinnerLoad] = useState(true);
  const [firstStepState, setFirstStepState] = useState(0);

  useEffect(() => {
    Promise.all([
      import("./Style/Login.css"),
      import("./Style/bootstrap.min.css"),
      import("./Style/all.min.css"),
    ]).then(() => setLoad(true));
  }, []);

  const [data, setData] = useState({ password: "", password_confirmation: "" });
  const handleInputChange = (e) => {
    if (reqError) {
      setReqError(null);
    }
    const { name, value } = e.target;
    name === "password_confirmation" && error && setError(false);
    setData((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };
  const params = window.Object.fromEntries(
    new URLSearchParams(window.location.search).entries()
  );

  const [menuLang, setMenuLang] = useState(false);
  const [selectedLang, setSelectedLang] = useState(
    localStorage.getItem("i18nextLng")
  );

  const { i18n } = useTranslation();

  const handleLangChange = (item) => {
    setSelectedLang(item);
    setMenuLang(false);
    localStorage.setItem("i18nextLng", item);
    i18n.changeLanguage(item);
  };

  const menuRef = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuLang(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [menuRef]);

  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    symbol: false,
  });

  const handleValidationState = (item, value) => {
    setPasswordValidation((prevData) => ({ ...prevData, [item]: value }));
  };

  const pwValidation = (e) => {
    const password = e.target.value;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    password.length > 12
      ? handleValidationState("length", true)
      : handleValidationState("length", false);
    hasUpperCase
      ? handleValidationState("uppercase", true)
      : handleValidationState("uppercase", false);
    hasLowerCase
      ? handleValidationState("lowercase", true)
      : handleValidationState("lowercase", false);
    hasNumber
      ? handleValidationState("number", true)
      : handleValidationState("number", false);
    hasSymbol
      ? handleValidationState("symbol", true)
      : handleValidationState("symbol", false);
  };

  const [error, setError] = useState(false);

  function onChange() {
    setCaptchaChecked(!captchaChecked);
  }

  const [captchaChecked, setCaptchaChecked] = useState(false);

  const [accountUserId, setAccountUserId] = useState();

  const inscFirstStep = async () => {
    try {
      setSpinnerLoad(true);
      const response = await completeInscriptionService.checkUser(url_username);
      setSpinnerLoad(false);

      if (!response.ok) {
        throw new Error(response.status);
      }
      setUpdatePassword(1);
      const result = await response.json();
      setAccountUserId(result.id);
    } catch (error) {
      if (error.message == 404) {
        setFirstStepState(1);
      } else {
        setFirstStepState(2);
      }
    }
  };

  useEffect(() => {
    inscFirstStep();
  }, []);

  const fetchPreferences = async () => {
    try {
      const response = await LoginService.getPreferences();
      if (!response.ok) {
        throw new Error(response.status);
      }
      const fetchedData = await response.json();

      localStorage.setItem(
        "data",
        JSON.stringify({
          id: fetchedData.data[0].id,
          language: fetchedData.data[0].language
            ? fetchedData.data[0].language
            : "null",
          timezone: fetchedData.data[0].timezone
            ? fetchedData.data[0].timezone
            : "null",
          date_format: fetchedData.data[0].date_format
            ? fetchedData.data[0].date_format
            : "null",
          time_format: fetchedData.data[0].time_format
            ? fetchedData.data[0].time_format
            : "null",
          currency_format: fetchedData.data[0].currency_format
            ? fetchedData.data[0].currency_format
            : "null",
          country_format: fetchedData.data[0].country_format
            ? fetchedData.data[0].country_format
            : "null",
          default_view: fetchedData.data[0].default_view
            ? fetchedData.data[0].default_view
            : "null",
        })
      );
      if (fetchedData) {
        localStorage.setItem(
          "i18nextLng",
          fetchedData.data[0].language === "EN" ? "en-EN" : "fr-FR"
        );
        handleLangChange(
          fetchedData.data[0].language === "EN" ? "en-EN" : "fr-FR"
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const inscLastStep = async () => {
    try {
      setSpinnerLoad(true);
      const response = await completeInscriptionService.completeInscription(
        data.password,
        url_username,
        accountUserId
      );
      setSpinnerLoad(false);
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.code);
      }
      setUpdatePassword(1);
      const result = await response.json();

      const token = await result.data[0].access_token;
      const username = await result.data[0].username;
      const firstname = await result.data[0].firstname;
      const lastname = await result.data[0].lastname;
      const avatar = await result.data[0].avatar;
      const email = await result.data[0].login;
      const role = await result.data[0].role;
      const customer_id = await result.data[0].customer_id;
      const total_numbers = await result.data[0].totale_numbers;
      const total_extensions = await result.data[0].totale_extensions;
      const country = await result.data[0].country;
      const have_sms = await result.data[0].have_sms;
      const email_billing = await result.data[0].email_billing;
      const numbers_list = await result.data[0].numbers_phone;

      localStorage.setItem("token", token);
      localStorage.setItem("username", username);
      localStorage.setItem("firstname", firstname ? firstname : "");
      localStorage.setItem("lastname", lastname ? lastname : "");
      localStorage.setItem("avatar", avatar);
      localStorage.setItem("email", email ? email : "");
      localStorage.setItem("role", role ? role : "");
      localStorage.setItem("customer_id", customer_id);
      localStorage.setItem("total_numbers", total_numbers);
      localStorage.setItem("total_users", total_extensions);
      localStorage.setItem("active_2fa", false);
      localStorage.setItem("country", country);
      localStorage.setItem("numbers_list", JSON.stringify(numbers_list));
      localStorage.setItem("have_sms", have_sms);
      localStorage.setItem("email_billing", email_billing);
      localStorage.setItem("authenticated", true);
      await fetchPreferences(token);
      window.location.href = `${process.env.REACT_APP_LOCAL}/dashboard`;
    } catch (error) {
      console.log(error);
      setReqError("errors.500");
    }
  };

  const [showPasswords, setShowPasswords] = useState({
    pw: false,
    confirm_pw: false,
  });

  return (
    <div>
      {!load ? (
        <Spinner />
      ) : (
        <div id="main-wrapper" className="oxyy-login-register">
          {spinnerLoad && <SpinnerSecondary />}
          <div className="container-fluid px-0">
            <div className="row g-0 min-vh-100">
              <div className="col-md-7 d-flex flex-column order-2 order-md-1">
                <div className="lang-switcher" ref={menuRef}>
                  <span
                    className="login-selected-lang p-1 px-3"
                    onClick={() => setMenuLang(!menuLang)}
                  >
                    {selectedLang.includes("fr")
                      ? t("french")
                      : selectedLang.includes("en")
                      ? t("english")
                      : ""}
                    <img
                      className="login-flags"
                      src={`/assets/media/flags/${
                        selectedLang.includes("fr")
                          ? "fr"
                          : selectedLang.includes("en")
                          ? "gb"
                          : ""
                      }.svg`}
                      alt=""
                    />{" "}
                  </span>
                  <div
                    className={`login-lang-container ${
                      menuLang && "container-show"
                    }`}
                  >
                    <span
                      className="login-selected-lang-opt"
                      onClick={() => handleLangChange("fr")}
                    >
                      <span>{t("french")}</span>
                      <span>
                        <img
                          className="login-flags"
                          src="/assets/media/flags/fr.svg"
                          alt=""
                        />
                      </span>
                    </span>
                    <span
                      className="login-selected-lang-opt"
                      onClick={() => handleLangChange("en")}
                    >
                      <span> {t("english")}</span>
                      <span>
                        <img
                          className="login-flags"
                          src="/assets/media/flags/gb.svg"
                          alt=""
                        />
                      </span>
                    </span>
                  </div>
                </div>
                <div className="container my-auto py-5">
                  <div className="row mx-0">
                    <div className="col-11 col-lg-9 col-xl-8 mx-auto text-center">
                      <div className="logo mb-5 text-center">
                        <img
                          src="/assets/media/myxtel-dark.png"
                          alt="MyXtel"
                          width={180}
                        />
                      </div>

                      {firstStepState == 0 ? (
                        <div>
                          {" "}
                          <h4 className="mb-5">{t("complete_inscription")}</h4>
                          {reqError && (
                            <div className="alert alert-danger d-flex align-items-center pad-5 mb-10">
                              <span className="svg-icon svg-icon-2hx svg-icon-danger me-3">
                                <span className="svg-icon svg-icon-2hx svg-icon-danger me-4">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      opacity="0.3"
                                      x="2"
                                      y="2"
                                      width="20"
                                      height="20"
                                      rx="5"
                                      fill="currentColor"
                                    />
                                    <rect
                                      x="7"
                                      y="15.3137"
                                      width="12"
                                      height="2"
                                      rx="1"
                                      transform="rotate(-45 7 15.3137)"
                                      fill="currentColor"
                                    />
                                    <rect
                                      x="8.41422"
                                      y="7"
                                      width="12"
                                      height="2"
                                      rx="1"
                                      transform="rotate(45 8.41422 7)"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                              </span>
                              <div className="d-flex flex-column login-error-div">
                                <h5 className="mb-1 text-left">{t("error")}</h5>
                                <span className="text-left">
                                  {t(reqError)}.
                                </span>
                              </div>
                            </div>
                          )}
                          <div className="text-start">
                            <form id="" method="post">
                              <div className="mb-4">
                                <label htmlFor="" className="form-label">
                                  {t("password")}
                                </label>
                                <div className="icon-group icon-group-end">
                                  <input
                                    type={
                                      showPasswords.pw ? "text" : "password"
                                    }
                                    className={`form-control ${
                                      (reqError === "errors.pw_not_match" ||
                                        reqError === "errors.pw_three_char") &&
                                      "border-red"
                                    }`}
                                    id=""
                                    required=""
                                    placeholder={t(
                                      "auth.update_password.placeholders.password"
                                    )}
                                    name="password"
                                    value={data.password}
                                    onChange={(e) => {
                                      pwValidation(e);
                                      handleInputChange(e);
                                    }}
                                  />

                                  {data.password ? (
                                    <span
                                      className="icon-inside text-muted"
                                      onClick={() =>
                                        setShowPasswords((prev) => ({
                                          ...prev,
                                          pw: !showPasswords.pw,
                                        }))
                                      }
                                    >
                                      <svg
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="svg-login cursor-pointer"
                                      >
                                        <g
                                          id="SVGRepo_bgCarrier"
                                          strokeWidth={0}
                                        />
                                        <g
                                          id="SVGRepo_tracerCarrier"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <g id="SVGRepo_iconCarrier">
                                          {" "}
                                          <path
                                            opacity="0.5"
                                            d="M2 12C2 13.6394 2.42496 14.1915 3.27489 15.2957C4.97196 17.5004 7.81811 20 12 20C16.1819 20 19.028 17.5004 20.7251 15.2957C21.575 14.1915 22 13.6394 22 12C22 10.3606 21.575 9.80853 20.7251 8.70433C19.028 6.49956 16.1819 4 12 4C7.81811 4 4.97196 6.49956 3.27489 8.70433C2.42496 9.80853 2 10.3606 2 12Z"
                                            fill="#1C274C"
                                          />{" "}
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M8.25 12C8.25 9.92893 9.92893 8.25 12 8.25C14.0711 8.25 15.75 9.92893 15.75 12C15.75 14.0711 14.0711 15.75 12 15.75C9.92893 15.75 8.25 14.0711 8.25 12ZM9.75 12C9.75 10.7574 10.7574 9.75 12 9.75C13.2426 9.75 14.25 10.7574 14.25 12C14.25 13.2426 13.2426 14.25 12 14.25C10.7574 14.25 9.75 13.2426 9.75 12Z"
                                            fill="#1C274C"
                                          />{" "}
                                        </g>
                                      </svg>
                                    </span>
                                  ) : (
                                    <span className="icon-inside text-muted">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 64 64"
                                        xmlSpace="preserve"
                                        fill="#000"
                                        className="svg-login"
                                      >
                                        <path
                                          fill={"#787878"}
                                          d="M52 24h-4v-8c0-8.836-7.164-16-16-16S16 7.164 16 16v8h-4c-2.211 0-4 1.789-4 4v32c0 2.211 1.789 4 4 4h40c2.211 0 4-1.789 4-4V28c0-2.211-1.789-4-4-4zM32 48c-2.211 0-4-1.789-4-4s1.789-4 4-4 4 1.789 4 4-1.789 4-4 4zm8-24H24v-8a8 8 0 0116 0v8z"
                                        />
                                      </svg>
                                    </span>
                                  )}
                                </div>
                                {data.password.length > 0 && (
                                  <div
                                    className={`flex-column mt-3 ${
                                      passwordValidation.length &&
                                      passwordValidation.number &&
                                      passwordValidation.uppercase &&
                                      passwordValidation.lowercase &&
                                      passwordValidation.symbol
                                        ? "display-none"
                                        : "d-flex"
                                    }`}
                                  >
                                    <div className="d-flex align-items-center gap-2">
                                      <svg
                                        height={14}
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="#000000"
                                      >
                                        <g
                                          id="SVGRepo_bgCarrier"
                                          stroke-width="0"
                                        ></g>
                                        <g
                                          id="SVGRepo_tracerCarrier"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        ></g>
                                        <g id="SVGRepo_iconCarrier">
                                          {" "}
                                          <path
                                            fill="none"
                                            stroke={
                                              !passwordValidation.length
                                                ? "#a1a5b7"
                                                : "green"
                                            }
                                            stroke-width="2"
                                            d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M7,12 L11,15 L16,8"
                                          ></path>{" "}
                                        </g>
                                      </svg>

                                      <span
                                        className={`fs-8 fw-semibold ${
                                          passwordValidation.length
                                            ? "color-green"
                                            : "text-gray-600"
                                        }`}
                                      >
                                        {t("min_12_char")}
                                      </span>
                                    </div>

                                    <div className="d-flex align-items-center gap-2">
                                      <svg
                                        height={14}
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="#000000"
                                      >
                                        <g
                                          id="SVGRepo_bgCarrier"
                                          stroke-width="0"
                                        ></g>
                                        <g
                                          id="SVGRepo_tracerCarrier"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        ></g>
                                        <g id="SVGRepo_iconCarrier">
                                          {" "}
                                          <path
                                            fill="none"
                                            stroke={
                                              !passwordValidation.uppercase
                                                ? "#a1a5b7"
                                                : "green"
                                            }
                                            stroke-width="2"
                                            d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M7,12 L11,15 L16,8"
                                          ></path>{" "}
                                        </g>
                                      </svg>

                                      <span
                                        className={`fs-8 fw-semibold ${
                                          passwordValidation.uppercase
                                            ? "color-green"
                                            : "text-gray-600"
                                        }`}
                                      >
                                        {t("uppercase")}
                                      </span>
                                    </div>

                                    <div className="d-flex align-items-center gap-2">
                                      <svg
                                        height={14}
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="#000000"
                                      >
                                        <g
                                          id="SVGRepo_bgCarrier"
                                          stroke-width="0"
                                        ></g>
                                        <g
                                          id="SVGRepo_tracerCarrier"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        ></g>
                                        <g id="SVGRepo_iconCarrier">
                                          {" "}
                                          <path
                                            fill="none"
                                            stroke={
                                              !passwordValidation.lowercase
                                                ? "#a1a5b7"
                                                : "green"
                                            }
                                            stroke-width="2"
                                            d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M7,12 L11,15 L16,8"
                                          ></path>{" "}
                                        </g>
                                      </svg>

                                      <span
                                        className={`fs-8 fw-semibold ${
                                          passwordValidation.lowercase
                                            ? "color-green"
                                            : "text-gray-600"
                                        }`}
                                      >
                                        {t("lowercase")}
                                      </span>
                                    </div>

                                    <div className="d-flex align-items-center gap-2">
                                      <svg
                                        height={14}
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="#000000"
                                      >
                                        <g
                                          id="SVGRepo_bgCarrier"
                                          stroke-width="0"
                                        ></g>
                                        <g
                                          id="SVGRepo_tracerCarrier"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        ></g>
                                        <g id="SVGRepo_iconCarrier">
                                          {" "}
                                          <path
                                            fill="none"
                                            stroke={
                                              !passwordValidation.number
                                                ? "#a1a5b7"
                                                : "green"
                                            }
                                            stroke-width="2"
                                            d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M7,12 L11,15 L16,8"
                                          ></path>{" "}
                                        </g>
                                      </svg>

                                      <span
                                        className={`fs-8 fw-semibold ${
                                          passwordValidation.number
                                            ? "color-green"
                                            : "text-gray-600"
                                        }`}
                                      >
                                        {t("valid_number")}
                                      </span>
                                    </div>

                                    <div className="d-flex align-items-center gap-2">
                                      <svg
                                        height={14}
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="#000000"
                                      >
                                        <g
                                          id="SVGRepo_bgCarrier"
                                          stroke-width="0"
                                        ></g>
                                        <g
                                          id="SVGRepo_tracerCarrier"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        ></g>
                                        <g id="SVGRepo_iconCarrier">
                                          {" "}
                                          <path
                                            fill="none"
                                            stroke={
                                              !passwordValidation.symbol
                                                ? "#a1a5b7"
                                                : "green"
                                            }
                                            stroke-width="2"
                                            d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M7,12 L11,15 L16,8"
                                          ></path>{" "}
                                        </g>
                                      </svg>

                                      <span
                                        className={`fs-8 fw-semibold ${
                                          passwordValidation.symbol
                                            ? "color-green"
                                            : "text-gray-600"
                                        }`}
                                      >
                                        {t("symbol")}
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div
                                className={`mb-4 ${
                                  (!passwordValidation.length ||
                                    !passwordValidation.uppercase ||
                                    !passwordValidation.lowercase ||
                                    !passwordValidation.symbol ||
                                    !passwordValidation.number) &&
                                  "disabled-event"
                                }`}
                              >
                                <label htmlFor="" className="form-label">
                                  {t("confirm_password")}
                                </label>
                                <div className="icon-group icon-group-end">
                                  <input
                                    type={
                                      showPasswords.confirm_pw
                                        ? "text"
                                        : "password"
                                    }
                                    className={`form-control mb-3 ${
                                      (reqError === "errors.pw_not_match" ||
                                        reqError === "errors.pw_three_char") &&
                                      "border-red"
                                    }`}
                                    id=""
                                    required=""
                                    placeholder={t(
                                      "auth.update_password.placeholders.confirm_password"
                                    )}
                                    name="password_confirmation"
                                    value={data.password_confirmation}
                                    onChange={handleInputChange}
                                    onBlur={() => {
                                      if (
                                        data.password !==
                                        data.password_confirmation
                                      ) {
                                        setError(true);
                                      }
                                    }}
                                  />
                                  {data.password_confirmation ? (
                                    <span
                                      className="icon-inside text-muted"
                                      onClick={() =>
                                        setShowPasswords((prev) => ({
                                          ...prev,
                                          confirm_pw: !showPasswords.confirm_pw,
                                        }))
                                      }
                                    >
                                      <svg
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="svg-login cursor-pointer"
                                      >
                                        <g
                                          id="SVGRepo_bgCarrier"
                                          strokeWidth={0}
                                        />
                                        <g
                                          id="SVGRepo_tracerCarrier"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <g id="SVGRepo_iconCarrier">
                                          {" "}
                                          <path
                                            opacity="0.5"
                                            d="M2 12C2 13.6394 2.42496 14.1915 3.27489 15.2957C4.97196 17.5004 7.81811 20 12 20C16.1819 20 19.028 17.5004 20.7251 15.2957C21.575 14.1915 22 13.6394 22 12C22 10.3606 21.575 9.80853 20.7251 8.70433C19.028 6.49956 16.1819 4 12 4C7.81811 4 4.97196 6.49956 3.27489 8.70433C2.42496 9.80853 2 10.3606 2 12Z"
                                            fill="#1C274C"
                                          />{" "}
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M8.25 12C8.25 9.92893 9.92893 8.25 12 8.25C14.0711 8.25 15.75 9.92893 15.75 12C15.75 14.0711 14.0711 15.75 12 15.75C9.92893 15.75 8.25 14.0711 8.25 12ZM9.75 12C9.75 10.7574 10.7574 9.75 12 9.75C13.2426 9.75 14.25 10.7574 14.25 12C14.25 13.2426 13.2426 14.25 12 14.25C10.7574 14.25 9.75 13.2426 9.75 12Z"
                                            fill="#1C274C"
                                          />{" "}
                                        </g>
                                      </svg>
                                    </span>
                                  ) : (
                                    <span className="icon-inside text-muted">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 64 64"
                                        xmlSpace="preserve"
                                        fill="#000"
                                        className="svg-login"
                                      >
                                        <path
                                          fill={"#787878"}
                                          d="M52 24h-4v-8c0-8.836-7.164-16-16-16S16 7.164 16 16v8h-4c-2.211 0-4 1.789-4 4v32c0 2.211 1.789 4 4 4h40c2.211 0 4-1.789 4-4V28c0-2.211-1.789-4-4-4zM32 48c-2.211 0-4-1.789-4-4s1.789-4 4-4 4 1.789 4 4-1.789 4-4 4zm8-24H24v-8a8 8 0 0116 0v8z"
                                        />
                                      </svg>
                                    </span>
                                  )}
                                </div>
                                <ReCAPTCHA
                                  sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                                  onChange={onChange}
                                />

                                {error && (
                                  <div className="d-flex mt-6">
                                    {" "}
                                    <div className="fw-bold danger-drag-and-drop mt-3">
                                      <svg
                                        height={20}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <g
                                          id="SVGRepo_bgCarrier"
                                          stroke-width="0"
                                        ></g>
                                        <g
                                          id="SVGRepo_tracerCarrier"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        ></g>
                                        <g id="SVGRepo_iconCarrier">
                                          {" "}
                                          <path
                                            opacity="0.5"
                                            d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z"
                                            fill="#e73e3e"
                                          ></path>{" "}
                                          <path
                                            d="M12 6.25C12.4142 6.25 12.75 6.58579 12.75 7V13C12.75 13.4142 12.4142 13.75 12 13.75C11.5858 13.75 11.25 13.4142 11.25 13V7C11.25 6.58579 11.5858 6.25 12 6.25Z"
                                            fill="#e73e3e"
                                          ></path>{" "}
                                          <path
                                            d="M12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
                                            fill="#e73e3e"
                                          ></path>{" "}
                                        </g>
                                      </svg>
                                      <span className="fs-7 fw-semibold text-gray-800">
                                        {t("errors.pw_not_match")}.
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div
                                className={`d-grid my-4 ${
                                  (!data.password_confirmation ||
                                    error ||
                                    !captchaChecked) &&
                                  "disabled-event"
                                }`}
                              >
                                <span
                                  className="btn btn-primary"
                                  onClick={inscLastStep}
                                >
                                  {t("access_to_your_account")}
                                </span>
                              </div>
                            </form>
                          </div>{" "}
                        </div>
                      ) : firstStepState === 1 ? (
                        <div className="alert alert-danger d-flex align-items-center pad-5 mb-10">
                        <span className="svg-icon svg-icon-2hx svg-icon-danger me-3">
                          <span className="svg-icon svg-icon-2hx svg-icon-danger me-4">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                opacity="0.3"
                                x="2"
                                y="2"
                                width="20"
                                height="20"
                                rx="5"
                                fill="currentColor"
                              />
                              <rect
                                x="7"
                                y="15.3137"
                                width="12"
                                height="2"
                                rx="1"
                                transform="rotate(-45 7 15.3137)"
                                fill="currentColor"
                              />
                              <rect
                                x="8.41422"
                                y="7"
                                width="12"
                                height="2"
                                rx="1"
                                transform="rotate(45 8.41422 7)"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </span>
                        <div className="d-flex flex-column login-error-div align-items-start">
                          <h5 className="mb-1">{t("auth.login.error.code.404.title")}</h5>
                          <span>{t("auth.login.error.code.404.message")}</span>
                        </div>
                      </div>
                      ) : (
                        <div className="alert alert-danger d-flex align-items-center pad-5 mb-10">
                        <span className="svg-icon svg-icon-2hx svg-icon-danger me-3">
                          <span className="svg-icon svg-icon-2hx svg-icon-danger me-4">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                opacity="0.3"
                                x="2"
                                y="2"
                                width="20"
                                height="20"
                                rx="5"
                                fill="currentColor"
                              />
                              <rect
                                x="7"
                                y="15.3137"
                                width="12"
                                height="2"
                                rx="1"
                                transform="rotate(-45 7 15.3137)"
                                fill="currentColor"
                              />
                              <rect
                                x="8.41422"
                                y="7"
                                width="12"
                                height="2"
                                rx="1"
                                transform="rotate(45 8.41422 7)"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </span>
                        <div className="d-flex flex-column login-error-div">
                          <h5 className="mb-1">{t("auth.login.error.code.500.title")}</h5>
                          <span>{t("auth.login.error.code.500.message")}</span>
                        </div>
                      </div>
                      )}

                      <p
                        className={`text-muted text-2 mb-0 ${
                          updateResponse != 0 && "extra-margin-div"
                        }`}
                      >
                        {t("auth.return_to")}
                        <Link to="/login">{t("login")}</Link>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="container py-2">
                  <p className="text-2 text-muted text-center mb-0">
                    <a href="#">MYXTEL</a>, 2025.{" "}
                    {t("auth.login.footer.copyright")}.
                  </p>
                </div>
              </div>
              <div className="col-md-5 order-1 order-md-2">
                <div className="hero-wrap d-flex align-items-center h-100">
                  <div className="hero-mask opacity-8 bg-secondary" />
                  <div
                    className="hero-bg hero-bg-scroll"
                    style={{ backgroundImage: 'url("/assets/img/bg2.jpg")' }}
                  ></div>
                  <div className="hero-content mx-auto w-100 h-100 d-flex flex-column">
                    <div className="row g-0 my-auto py-5">
                      <div className="col-10 col-lg-9 mx-auto">
                        <p className="text-4 text-white">{t("welcome_x")} {url_username},</p>
                        <h1 className="text-10 text-white mb-4">
                          {t("last_step")}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompleteInscription;
