// Css

import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Components

import Main from "./Layouts/Main";
import LoginRoute from "./routes/LoginRoute";
import { useEffect } from "react";

import "./App.css"

function App() {

  useEffect(() => {
    document.title = process.env.REACT_APP_TITLE;
  }, []);

  i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: require("./Data/Languages/en.json") },
      fr: { translation: require("./Data/Languages/fr.json") },
    },
    fallbackLng: "fr",
    debug: true,
  });

  const auth = localStorage.getItem("authenticated")

  if (auth==="true") {
  
    return (
      <div>
        <Main />
      </div>
    );
  } else {
    
    return (
      <div>
        <LoginRoute />
      </div>
    );
  }
  
}

export default App;
