import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SpinnerDiv from "../../../../Layouts/SpinnerDiv";

const NumberDetails = ({
  countries,
  changeReqBody,
  setScope,
  quantity,
  country,
  changeCountry,
  updateFeatures,
  features,
  numberType,
  scopeButton,
  consecutive,
  loading,
}) => {
  const { t } = useTranslation();

  const amounts = [
    { value: 0 },
    { value: 10 },
    { value: 25 },
    { value: 50 },
    { value: 100 },
    { value: 250 },
    { value: 500 },
  ];

  const [menuOpen, setMenuOpen] = useState(false);

  const [numTypes, setNumTypes] = useState([
    { id: null, tagcode: "phonelocal" },
    { id: null, tagcode: "phonenational" },
    { id: null, tagcode: "phonemobile" },
    { id: null, tagcode: "tollfree" },
  ]);

  const [numFeatures, setNumFeatures] = useState([
    { id: null, tagcode: "callin" },
    { id: null, tagcode: "smsin" },
  ]);

  const changeServices = (countryData) => {
    setNumFeatures([
      { id: null, tagcode: "callin" },
      { id: null, tagcode: "smsin" },
    ]);
    const selectedCountryNumberTypes = Object.keys(
      countryData.number_types
    ).map((type) => type);

    setNumTypes((prevNumTypes) =>
      prevNumTypes.map((numType) => ({
        ...numType,
        id: selectedCountryNumberTypes.includes(numType.tagcode)
          ? countries.services.number_types.find(
              (service) => service.tagcode === numType.tagcode
            ).service_id
          : null,
      }))
    );
  };

  const changeFeatures = (serviceCode) => {
    const selectedNumberTypesFeatures = Object.keys(
      countries.countries[country].number_types[serviceCode].features
    ).map((type) => type);

    const type =
      serviceCode === "phonemobile"
        ? "mobile"
        : serviceCode === "phonenational"
        ? "national"
        : serviceCode === "phonelocal"
        ? "local"
        : "";

    setNumFeatures((prevData) =>
      prevData.map((feature) => ({
        ...feature,
        id: selectedNumberTypesFeatures.includes(`${type}${feature.tagcode}`)
          ? countries.services.features.find(
              (service) => service.tagcode === `${type}${feature.tagcode}`
            ).service_id
          : null,
      }))
    );
  };

  return (
    <div
      className={`card card-flush pt-3 mb-5 mb-lg-10 ${
        loading && "disabled-event"
      }`}
    >
      {loading && <SpinnerDiv />}
      <div className="card-header">
        <div className="card-title">
          <h2 className="fw-bold">{t("number_details")}</h2>
        </div>
      </div>
      <div className="card-body pt-0">
        {/* Country */}
        <div className="fv-row mb-6">
          <label className="d-flex align-items-center form-label mb-3 required">
            <span>{t("country")}</span>
          </label>
          <div
            className={`form-floating border rounded position-relative cursor-pointer`}
            onClick={() => setMenuOpen(!menuOpen)}
            onMouseLeave={() => setMenuOpen(false)}
          >
            <div className="form-select form-select-transparent">
              {country ? (
                <div className="selected-country">
                  <img
                    className="flag-style height-1-2 width-1-6 obj-fit-cover"
                    src={`/assets/media/flags/${country.toLowerCase()}.svg`}
                    alt={country}
                  />
                  <span>{t(`countries.${country}`)}</span>
                </div>
              ) : (
                t("select_country")
              )}
            </div>

            <div className={`menu-drop-down ${!menuOpen && "display-none"}`}>
              {Object.keys(countries).includes("countries") ? (
                Object.entries(countries.countries).map(([key, value]) => (
                  <div
                    onClick={() => {
                      changeServices(value);
                      changeCountry(key);
                    }}
                  >
                    <img
                      className="flag-style height-1-2 width-1-6 obj-fit-cover"
                      src={`/assets/media/flags/${key.toLowerCase()}.svg`}
                      alt={key}
                    />
                    <span>{t(`countries.${key}`)}</span>
                  </div>
                ))
              ) : (
                <span className="p-1 px-3">{t("no_available_countries")}</span>
              )}
            </div>
          </div>
        </div>

        {/* Number type */}
        <div className="fv-row mb-6">
          <label className="d-flex align-items-center form-label mb-3 required">
            <span>{t("type")}</span>
          </label>
          <div className="btn-group w-100 w-lg-50 mb-5">
            {numTypes.map((item, key) => (
              <label
                className={`btn btn-outline btn-sm btn-active-success fw-bold ${
                  !item.id && "disabled-event"
                } ${
                  numberType === item.id &&
                  numberType !== null &&
                  "type-selected"
                }`}
                data-kt-button="true"
                {...(!item.id && {
                  "data-tooltip-id": "my-tooltip",
                  "data-tooltip-content": t("header.icons.notification"),
                })}
              >
                <input
                  onClick={() => {
                    changeReqBody("number_type_id", item.id);
                    changeReqBody("outbound_scope", []);
                    changeFeatures(item.tagcode);
                    if (numberType !== item.id) {
                      changeReqBody("features", []);
                    }
                  }}
                  className="btn-check"
                  type="radio"
                  name="method"
                />
                {t(`services.${item.tagcode}`)}
              </label>
            ))}
          </div>
          {/* {country &&
            (!numTypes[0].id || !numTypes[1].id || !numTypes[2].id) && (
              <div className="d-flex">
                {" "}
                <div className="fw-semibold danger-drag-and-drop">
                  <svg
                    height={20}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        opacity="0.5"
                        d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z"
                        fill="#e73e3e"
                      ></path>{" "}
                      <path
                        d="M12 6.25C12.4142 6.25 12.75 6.58579 12.75 7V13C12.75 13.4142 12.4142 13.75 12 13.75C11.5858 13.75 11.25 13.4142 11.25 13V7C11.25 6.58579 11.5858 6.25 12 6.25Z"
                        fill="#e73e3e"
                      ></path>{" "}
                      <path
                        d="M12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
                        fill="#e73e3e"
                      ></path>{" "}
                    </g>
                  </svg>
                  <span>
                    {numTypes.filter((types) => !types.id).length !== 4 &&
                      numTypes
                        .filter((types) => !types.id)
                        .map(
                          (item, index) =>
                            !item.id && (
                              <span key={index}>
                                {t(`services.${item.tagcode}`)}
                                {index !==
                                numTypes.filter((types) => !types.id).length - 1
                                  ? " and "
                                  : " "}
                              </span>
                            )
                        )}
                    {numTypes.filter((types) => !types.id).length === 4
                      ? t("country_do_not_support_types")
                      : numTypes.filter((types) => !types.id).length === 1
                      ? t("type_is_not_available")
                      : t("types_are_not_available")}
                    .{" "}
                  </span>
                </div>
              </div>
            )} */}
        </div>

        {/* Features */}
        <div className="fv-row mb-6">
          <label className="d-flex align-items-center form-label mb-3 required">
            <span>{t("features")}</span>
          </label>
          <div className="mb-10 d-flex">
            {numFeatures.map((item) => (
              <div
                className={`form-check form-check-custom form-check-solid me-10 ${
                  !item.id && "disabled-event"
                }`}
              >
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  id="flexCheckbox30"
                  checked={features.includes(item.id)}
                  onClick={() => updateFeatures(item.id)}
                />
                <label className="form-check-label">
                  {t(`outbounds.${item.tagcode}`)}
                </label>
              </div>
            ))}
          </div>
        </div>

        {/* Quantity */}
        <div className="mb-5 fv-row">
          <label className="form-label mb-3 required">{t("quantity")}</label>
          <input
            type="number"
            className={`form-control form-control-sm form-control-solid w-13em${
              !numberType ? " disabled-event" : ""
            }`}
            name="address"
            value={quantity}
            min={1}
            onChange={(e) =>
              changeReqBody("quantity", parseInt(e.target.value))
            }
          />
        </div>

        {/* Consecutive numbers */}
        {quantity > 1 && (
          <div className="mb-5 fv-row">
            <label className="form-label mb-3">
              {t("consecutive_numbers")}
            </label>
            <div className="form-check form-switch form-check-custom form-check-solid me-10">
              <input
                className="form-check-input cursor-pointer"
                type="checkbox"
                defaultValue={consecutive}
                id="flexSwitch30x50"
                onChange={() => changeReqBody("consecutive", !consecutive)}
              />
              <label className="form-check-label" htmlFor="flexSwitch30x50">
                {t("yes")}
              </label>
            </div>
          </div>
        )}

        {/* Outbound button */}
        {features.length > 0 &&
          countries &&
          numberType &&
          ((Object.keys(
            countries.countries[country].number_types[
              countries.services.number_types.find(
                (item) => item.service_id === numberType
              ).tagcode
            ].features
          ).some((item) => item.endsWith("callout")) &&
            features
              .map(
                (item) =>
                  countries.services.features.find(
                    (el) => el.service_id === item
                  ).tagcode
              )
              .some((ft) => ft.endsWith("callin"))) ||
            (Object.keys(
              countries.countries[country].number_types[
                countries.services.number_types.find(
                  (item) => item.service_id === numberType
                ).tagcode
              ].features
            ).some((item) => item.endsWith("smsout")) &&
              features
                .map(
                  (item) =>
                    countries.services.features.find(
                      (el) => el.service_id === item
                    ).tagcode
                )
                .some((ft) => ft.endsWith("smsin")))) && (
            <div className="fv-row">
              <label className="form-label mb-3">
                {`${t("outbound")} ${
                  features
                    .map(
                      (item) =>
                        countries.services.features.find(
                          (el) => el.service_id === item
                        ).tagcode
                    )
                    .some((ft) => ft.endsWith("callin")) &&
                  features
                    .map(
                      (item) =>
                        countries.services.features.find(
                          (el) => el.service_id === item
                        ).tagcode
                    )
                    .some((ft) => ft.endsWith("smsin"))
                    ? t("services.call_sms")
                    : features
                        .map(
                          (item) =>
                            countries.services.features.find(
                              (el) => el.service_id === item
                            ).tagcode
                        )
                        .some((ft) => ft.endsWith("callin")) &&
                      !features
                        .map(
                          (item) =>
                            countries.services.features.find(
                              (el) => el.service_id === item
                            ).tagcode
                        )
                        .some((ft) => ft.endsWith("smsin"))
                    ? t("services.call")
                    : !features
                        .map(
                          (item) =>
                            countries.services.features.find(
                              (el) => el.service_id === item
                            ).tagcode
                        )
                        .some((ft) => ft.endsWith("callin")) &&
                      features
                        .map(
                          (item) =>
                            countries.services.features.find(
                              (el) => el.service_id === item
                            ).tagcode
                        )
                        .some((ft) => ft.endsWith("smsin"))
                    ? t("services.sms")
                    : ""
                } ${t("needed")}`}
                ?
              </label>
              <div className="form-check form-switch form-check-custom form-check-solid me-10 mb-3">
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  checked={scopeButton}
                  id="flexSwitch30x50"
                  onChange={() => setScope()}
                />
                <label className="form-check-label" htmlFor="flexSwitch30x50">
                  {t("yes")}
                </label>
              </div>
            </div>
          )}

        {/* Warning - feature(s) do not support outbound scope*/}

        {features.length > 0 &&
          countries &&
          numberType &&
          (!Object.keys(
            countries.countries[country].number_types[
              countries.services.number_types.find(
                (item) => item.service_id === numberType
              ).tagcode
            ].features
          ).some((item) => item.endsWith("smsout")) &&
          features
            .map(
              (item) =>
                countries.services.features.find((el) => el.service_id === item)
                  .tagcode
            )
            .some((ft) => ft.endsWith("smsin")) &&
          !Object.keys(
            countries.countries[country].number_types[
              countries.services.number_types.find(
                (item) => item.service_id === numberType
              ).tagcode
            ].features
          ).some((item) => item.endsWith("callout")) &&
          features
            .map(
              (item) =>
                countries.services.features.find((el) => el.service_id === item)
                  .tagcode
            )
            .some((ft) => ft.endsWith("callin")) ? (
            <div className="d-flex">
              {" "}
              <div className="fw-semibold danger-drag-and-drop">
                <svg
                  height={20}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      opacity="0.5"
                      d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z"
                      fill="#e73e3e"
                    ></path>{" "}
                    <path
                      d="M12 6.25C12.4142 6.25 12.75 6.58579 12.75 7V13C12.75 13.4142 12.4142 13.75 12 13.75C11.5858 13.75 11.25 13.4142 11.25 13V7C11.25 6.58579 11.5858 6.25 12 6.25Z"
                      fill="#e73e3e"
                    ></path>{" "}
                    <path
                      d="M12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
                      fill="#e73e3e"
                    ></path>{" "}
                  </g>
                </svg>
                <span>{t("phone_number_support_only_in")}</span>
              </div>
            </div>
          ) : !Object.keys(
              countries.countries[country].number_types[
                countries.services.number_types.find(
                  (item) => item.service_id === numberType
                ).tagcode
              ].features
            ).some((item) => item.endsWith("callout")) &&
            features
              .map(
                (item) =>
                  countries.services.features.find(
                    (el) => el.service_id === item
                  ).tagcode
              )
              .some((ft) => ft.endsWith("callin")) ? (
            <div className="d-flex">
              {" "}
              <div className="fw-semibold danger-drag-and-drop">
                <svg
                  height={20}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      opacity="0.5"
                      d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z"
                      fill="#e73e3e"
                    ></path>{" "}
                    <path
                      d="M12 6.25C12.4142 6.25 12.75 6.58579 12.75 7V13C12.75 13.4142 12.4142 13.75 12 13.75C11.5858 13.75 11.25 13.4142 11.25 13V7C11.25 6.58579 11.5858 6.25 12 6.25Z"
                      fill="#e73e3e"
                    ></path>{" "}
                    <path
                      d="M12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
                      fill="#e73e3e"
                    ></path>{" "}
                  </g>
                </svg>
                <span>{t("phone_number_support_only_in_calls")}</span>
              </div>
            </div>
          ) : !Object.keys(
              countries.countries[country].number_types[
                countries.services.number_types.find(
                  (item) => item.service_id === numberType
                ).tagcode
              ].features
            ).some((item) => item.endsWith("smsout")) &&
            features
              .map(
                (item) =>
                  countries.services.features.find(
                    (el) => el.service_id === item
                  ).tagcode
              )
              .some((ft) => ft.endsWith("smsin")) ? (
            <div className="d-flex">
              {" "}
              <div className="fw-semibold danger-drag-and-drop">
                <svg
                  height={20}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      opacity="0.5"
                      d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z"
                      fill="#e73e3e"
                    ></path>{" "}
                    <path
                      d="M12 6.25C12.4142 6.25 12.75 6.58579 12.75 7V13C12.75 13.4142 12.4142 13.75 12 13.75C11.5858 13.75 11.25 13.4142 11.25 13V7C11.25 6.58579 11.5858 6.25 12 6.25Z"
                      fill="#e73e3e"
                    ></path>{" "}
                    <path
                      d="M12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
                      fill="#e73e3e"
                    ></path>{" "}
                  </g>
                </svg>
                <span>{t("phone_number_support_only_in_sms")}</span>
              </div>
            </div>
          ) : (
            ""
          ))}

        {/* {features.length > 0 &&
          countries &&
          numberType &&
          (!Object.keys(
            countries.countries[country].number_types[
              countries.services.number_types.find(
                (item) => item.service_id === numberType
              ).tagcode
            ].features
          ).find((item) => item.endsWith("callout")) ||
            !Object.keys(
              countries.countries[country].number_types[
                countries.services.number_types.find(
                  (item) => item.service_id === numberType
                ).tagcode
              ].features
            ).find((item) => item.endsWith("smsout"))) && (
            <div className="position-relative pointer-event-none height-2">
              {" "}
              <div className="position-absolute fw-semibold danger-drag-and-drop">
                <svg
                  className="warning-svg"
                  fill="#e73e3e"
                  viewBox="0 0 1920 1920"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      d="M960 0c530.193 0 960 429.807 960 960s-429.807 960-960 960S0 1490.193 0 960 429.807 0 960 0Zm-9.838 1342.685c-84.47 0-153.19 68.721-153.19 153.19 0 84.47 68.72 153.192 153.19 153.192s153.19-68.721 153.19-153.191-68.72-153.19-153.19-153.19ZM1153.658 320H746.667l99.118 898.623h208.755L1153.658 320Z"
                      fill-rule="evenodd"
                    ></path>{" "}
                  </g>
                </svg>
                <span>{t("phone_number_support_only_in")}</span>
              </div>
            </div>
          )} */}

        {/* <div className="fv-row mb-6">
          <label className="form-label">
            {t("add_outbound_calling_credit")}
          </label> 
          <select
            name="business_activity"
            className="form-select form-select-sm form-select-solid"
            onChange={(e) => changeReqBody("outbound_credit", e.target.value)}
          >
          

            {amounts.map((value) => (
              <option value={value.value}>{value.value} EUR</option>
            ))}
          </select>
        </div> 
         <div className="d-flex flex-column mb-5 fv-row rounded-3 p-7 border border-dashed border-gray-300">
          <div className="fs-5 fw-bold form-label mb-3">
            {t("usage_treshold")}
          
          </div>
          <label className="form-check form-check-custom form-check-solid">
            <input
              className="form-check-input"
              type="checkbox"
              defaultChecked="checked"
              defaultValue={1}
            />
            <span className="form-check-label text-gray-600">
              {t("bill_reachs")} 0%.
            </span>
          </label>
        </div> */}
      </div>
    </div>
  );
};

export default NumberDetails;
