const checkUser = async (username) => {
  return await fetch(
    `${process.env.REACT_APP_BASE_URL}/resources/users/complete_inscription`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${process.env.REACT_APP_PUBLIC_TOKEN}`,
      },
      body: JSON.stringify({
        action: "check",
        username
      }),
    }
  );
};



const completeInscription = async (password, username, account_user_id) => {
  return await fetch(
    `${process.env.REACT_APP_BASE_URL}/resources/users/complete_inscription`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${process.env.REACT_APP_PUBLIC_TOKEN}`,
      },
      body: JSON.stringify({
        action: "validate",
        password,
        username,
        account_user_id,
      }),
    }
  );
};

const completeInscriptionService = {
  checkUser,
  completeInscription,
};
export default completeInscriptionService;
