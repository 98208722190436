import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Card from "../Components/Card";
import DataTableUsers from "../Components/DataTable";
import PrimaryBtn from "../../../components/Buttons/PrimaryBtn";
import { useTranslation } from "react-i18next";
import usersData from "../Services/users.service";

import errorSwal from "../../../components/Alerts/errorAlert";
import errorExpiredSession from "../../../components/Alerts/errorExpiredSession";
import reconnectionSwal from "../../../components/Alerts/reconnectionAlert";

import Spinner from "../../../components/Spinner";

import "../Styles/Users.css";

import EmptyUsers from "./EmptyUsers";
import { FloatingLabel, Form } from "react-bootstrap";
import AddNew from "../../../components/Cards/AddNew";
import { initialDateFormat } from "../../../constants/Utils";
import InternalErrorSwal from "../../../components/Alerts/internalErrorAlert";

const ListUsers = () => {
  const { t } = useTranslation();

  const [data, setData] = useState([]);

  const [usersExists, setUsersExists] = useState(0);

  const [pagination, setPagination] = useState({
    total: 0,
    from: 1,
    to: 6,
    limit: 6,
    status: null,
    extension: null,
  });

  const [allFilteredUsers, setAllFilteredUsers] = useState(0);

  const fetchData = async () => {
    try {
      const response = await usersData();

      if (!response.ok) {
        throw new Error(response.status);
      }
      const fetchedData = await response.json();
      setPagination((prevData) => ({ ...prevData, total: fetchedData.total }));
      if (fetchedData.total != 0) {
        setUsersExists(1);
        setData(fetchedData.data);
      } else {
        setUsersExists(2);
      }
    } catch (error) {
      if (error.message == 401) {
        errorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        errorSwal(t("errors.403"));
      } else if (error.message == 404) {
        errorSwal(t("errors.404"));
      } else if (error.message == 405) {
        errorSwal(t("errors.405"));
      } else if (error.message == 406) {
        errorSwal(t("errors.406"));
      } else if (error.message == 422) {
        errorSwal(t("errors.422"));
      } else if (error.message == 423) {
        errorSwal(t("errors.423"));
      } else {
        // reconnectionSwal();
        fetchDataSecondTry();
      }
    }
  };

  const fetchDataSecondTry = async () => {
    try {
      const response = await usersData();

      if (!response.ok) {
        throw new Error(response.status);
      }
      const fetchedData = await response.json();
      setPagination((prevData) => ({ ...prevData, total: fetchedData.total }));
      if (fetchedData.total != 0) {
        setUsersExists(1);
        setData(fetchedData.data);
      } else {
        setUsersExists(2);
      }
    } catch (error) {
      InternalErrorSwal();
    }
  };

  const changeData = (item, id, value) => {
    setData((prevData) => {
      const index = prevData.findIndex((element) => element.id === id);
      if (index !== -1) {
        const updatedItem = { ...prevData[index], [item]: value };
        const newData = [
          ...prevData.slice(0, index),
          updatedItem,
          ...prevData.slice(index + 1),
        ];
        return newData;
      }
      return prevData;
    });
  };

  const [activeButton, setActiveButton] = useState(1);

  useEffect(() => {
    fetchData();
  }, []);

  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    const totalRows = data.filter(
      (item) =>
        (pagination.status ? item.status == pagination.status : true) &&
        (pagination.extension
          ? item.extension
              .toUpperCase()
              .includes(pagination.extension.toUpperCase())
          : true) &&
        (pagination.date
          ? initialDateFormat(item.starting_date) ==
            initialDateFormat(pagination.date)
          : true) &&
        (pagination.features
          ? item.features?.includes(pagination.features)
          : true)
    );

    const newtotalRows = data
      .filter(
        (item) =>
          (pagination.status ? item.status == pagination.status : true) &&
          (pagination.extension
            ? item.extension
                .toUpperCase()
                .includes(pagination.extension.toUpperCase())
            : true)
      )
      .slice(pagination.from - 1, pagination.to);

    setFilteredUsers(newtotalRows);
    setAllFilteredUsers(totalRows.length);
  }, [
    pagination.total,
    pagination.limit,
    pagination.from,
    pagination.to,
    pagination.status,
    pagination.extension,
    pagination.features,
    data,
    pagination.date,
  ]);

  const handleNextPage = () => {
    if (pagination.to < allFilteredUsers) {
      setPagination((prevData) => ({
        ...prevData,

        from: prevData.from + prevData.limit,
        to: prevData.to + prevData.limit,
      }));
    }
  };

  const handlePrevioustPage = () => {
    if (pagination.from != 0 && pagination.from != 1) {
      setPagination((prevData) => ({
        ...prevData,
        from: prevData.from - prevData.limit,
        to: prevData.to - prevData.limit,
      }));
    }
  };

  return (
    <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
      {(usersExists === 1 && data.length > 0) ? (
        <div className="app-container container-fluid d-flex flex-row flex-column-fluid">
          <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
            <div className="d-flex flex-column flex-column-fluid">
              <div
                id="kt_app_content"
                className="app-content flex-column-fluid"
              >
                <div className="d-flex flex-wrap flex-stack pb-7">
                  <span className="d-flex flex-wrap align-items-center my-1">
                    <h3 className="fw-bold me-5 my-1 flex-divs">
                      {t("users")}
                      <div className="showing-line">
                        <span>{t("total")}</span>
                        {allFilteredUsers != pagination.total ? (
                          <div className="showing-line">
                            <span>{t("showing")}</span>
                            <span>
                              ({allFilteredUsers}/
                              <span className="colored-total">
                                {pagination.total}
                              </span>
                              )
                            </span>
                          </div>
                        ) : (
                          <div className="showing-line">
                            <span>{t("total")}</span>
                            <span>({pagination.total})</span>
                          </div>
                        )}
                      </div>
                    </h3>
                  </span>
                  <div className="d-flex flex-wrap my-1 flex-filters">
                    <ul className="nav nav-pills mb-2 mb-sm-0 me-3">
                      <li className="">
                        <Link
                          to="/users_create"
                          className="btn btn-sm btn-primary-active flex-space-evenly me-3 disabled-event"
                        >
                          <svg
                            width="13px"
                            height="13px"
                            viewBox="0 0 32 32"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M390 1049h-8v-8a4 4 0 10-8 0v8h-8a4 4 0 100 8h8v8a4 4 0 108 0v-8h8a4 4 0 100-8"
                              transform="translate(-362 -1037)"
                              fill="#fff"
                              stroke="none"
                              strokeWidth={1}
                              fillRule="evenodd"
                            />
                          </svg>
                          <span>{t("buy_new_user")}</span>
                        </Link>

                        {/* <PrimaryBtn title={t("create_new")} target={"/users_create"} /> */}
                      </li>
                      <li className="nav-item m-0">
                        <a
                          href="javascript:"
                          onClick={() => setActiveButton(1)}
                          className={`btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary  me-3 ${
                            activeButton === 1 && " active"
                          }`}
                          data-bs-toggle="tab"
                        >
                          <span className="svg-icon svg-icon-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                            >
                              <g
                                stroke="none"
                                strokeWidth={1}
                                fill="none"
                                fillRule="evenodd"
                              >
                                <rect
                                  x={5}
                                  y={5}
                                  width={5}
                                  height={5}
                                  rx={1}
                                  fill="currentColor"
                                />
                                <rect
                                  x={14}
                                  y={5}
                                  width={5}
                                  height={5}
                                  rx={1}
                                  fill="currentColor"
                                  opacity="0.3"
                                />
                                <rect
                                  x={5}
                                  y={14}
                                  width={5}
                                  height={5}
                                  rx={1}
                                  fill="currentColor"
                                  opacity="0.3"
                                />
                                <rect
                                  x={14}
                                  y={14}
                                  width={5}
                                  height={5}
                                  rx={1}
                                  fill="currentColor"
                                  opacity="0.3"
                                />
                              </g>
                            </svg>
                          </span>
                        </a>
                      </li>
                      <li className="nav-item m-0">
                        <a
                          onClick={() => setActiveButton(2)}
                          href="javascript:"
                          className={`btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary ${
                            activeButton === 2 && "  active"
                          }`}
                          data-bs-toggle="tab"
                        >
                          <span className="svg-icon svg-icon-2">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                fill="currentColor"
                              />
                              <path
                                opacity="0.3"
                                d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </a>
                      </li>
                    </ul>

                    <FloatingLabel
                      controlId="floatingSelect"
                      label={t("titles.status")}
                      value={pagination.status}
                      onChange={(e) => {
                        setPagination((prevData) => ({
                          ...prevData,
                          status: e.target.value,
                        }));
                      }}
                    >
                      <Form.Select
                        aria-label="Floating label select example"
                        className="form-dimentiens cursor-pointer"
                      >
                        <option value="">{t("all")}</option>
                        <option value={1}>{t("active")}</option>
                        <option value={2}>{t("pending")}</option>
                        <option value={3}>{t("disabled")}</option>
                        <option value={4}>{t("draft")}</option>
                      </Form.Select>
                    </FloatingLabel>

                    <div className="d-flex align-items-center position-relative">
                      <label className="label-float">{t("user")}</label>
                      {/* <span className="svg-icon svg-icon-3 position-absolute ms-3">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                opacity="0.5"
                                x="17.0365"
                                y="15.1223"
                                width="8.15546"
                                height={2}
                                rx={1}
                                transform="rotate(45 17.0365 15.1223)"
                                fill="currentColor"
                              />
                              <path
                                d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span> */}
                      <input
                        type="text"
                        className="form-control form-control-sm border-body bg-body w-150px heiht-100 num-input"
                        placeholder={t("search")}
                        onChange={(e) =>
                          setPagination((prevData) => ({
                            ...prevData,
                            extension: e.target.value,
                          }))
                        }
                      />
                    </div>
                    {/* <div className="d-flex my-0 me-6">
                      <select
                        name="status"
                        data-control="select2"
                        data-hide-search="true"
                        data-placeholder="Filter"
                        className="form-select form-select-sm border-body bg-body w-150px me-5"
                      >
                        <option value={1}>{t("active")}</option>
                        <option value={2}>{t("deleted")}</option>
                        <option value={3}>{t("in_progress")}</option>
                        <option value={4}>{t("all")}</option>
                      </select>
                      <select
                        name="status"
                        data-control="select2"
                        data-hide-search="true"
                        data-placeholder="Export"
                        className="form-select form-select-sm border-body bg-body w-100px"
                      >
                        <option value="">Export</option>
                        <option value={1}>Excel</option>
                        <option value={1}>PDF</option>
                        <option value={2}>Print</option>
                      </select>
                    </div> */}
                    {/* <div className="d-flex align-items-center">
                      <span className="svg-icon svg-icon-3 position-absolute ms-3">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            opacity="0.5"
                            x="17.0365"
                            y="15.1223"
                            width="8.15546"
                            height={2}
                            rx={1}
                            transform="rotate(45 17.0365 15.1223)"
                            fill="currentColor"
                          />
                          <path
                            d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                      <input
                        type="text"
                        id="kt_filter_search"
                        className="form-control form-control-sm border-body bg-body w-150px ps-10"
                        placeholder="Search"
                      />
                    </div> */}
                  </div>
                </div>
                <div className="tab-content">
                  <div
                    id="kt_project_users_card_pane"
                    className={`tab-pane fade ${
                      activeButton === 1 && "show active"
                    }`}
                  >
                    {data.length > 0 ? (
                      <div className="row g-6 g-xl-9">
                        {filteredUsers.map((item, index) => (
                          <Card
                            key={index}
                            data={item}
                            changeData={changeData}
                          />
                        ))}
                        {(pagination.total < pagination.to ||
                          filteredUsers.length < 6) && (
                          <AddNew
                            title={"add_new_user"}
                            path={"/numbers_buy"}
                            disabled={true}
                          />
                        )}
                      </div>
                    ) : (
                      <div className="empty-numbers">
                        <img src="/assets/media/empty-icon.png" alt="" />
                        <span>{t("no_records")}</span>
                      </div>
                    )}

                    <div className="d-flex flex-stack flex-wrap pt-10">
                      {/* {filteredUsers.length !== 0 && (
                      <div className="numbers-dt-pagination">
                        <div className="rows-per-page">
                          {t("rows_per_page")}{" "}
                          <select
                            className="rows-per-page-select"
                            onChange={(e) =>
                              setPagination((prevData) => ({
                                ...prevData,
                                from: 1,
                                limit: parseInt(e.target.value),
                                to: parseInt(e.target.value),
                              }))
                            }
                          >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                          </select>
                        </div>
                        <span className="rows-per-page">
                          {pagination.from}-
                          {pagination.to < allFilteredUsers
                            ? pagination.to
                            : allFilteredUsers}{" "}
                          {t("of")} {allFilteredUsers}
                        </span>
                        <div className="dt-filter-buttons">
                          <div
                            class="datatable-button"
                            onClick={handlePrevioustPage}
                          >
                            <svg
                              className="svg-filters"
                              fill={`${
                                pagination.from != 0 && pagination.from != 1
                                  ? "#000000"
                                  : "#aaaaaa"
                              }`}
                              height="200px"
                              width="200px"
                              version="1.1"
                              id="XMLID_54_"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              viewBox="0 0 24 24"
                              xmlSpace="preserve"
                            >
                              <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                              <g
                                id="SVGRepo_tracerCarrier"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <g id="SVGRepo_iconCarrier">
                                {" "}
                                <g id="previous">
                                  {" "}
                                  <g>
                                    {" "}
                                    <polygon points="17.2,23.7 5.4,12 17.2,0.3 18.5,1.7 8.4,12 18.5,22.3 " />{" "}
                                  </g>{" "}
                                </g>{" "}
                              </g>
                            </svg>
                          </div>
                          <div
                            class="datatable-button"
                            onClick={handleNextPage}
                          >
                            <svg
                              className="svg-filters"
                              fill={
                                pagination.to < pagination.total
                                  ? "#000000"
                                  : "#aaaaaa"
                              }
                              height="200px"
                              width="200px"
                              version="1.1"
                              id="XMLID_287_"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              viewBox="0 0 24 24"
                              xmlSpace="preserve"
                            >
                              <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                              <g
                                id="SVGRepo_tracerCarrier"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <g id="SVGRepo_iconCarrier">
                                {" "}
                                <g id="next">
                                  {" "}
                                  <g>
                                    {" "}
                                    <polygon points="6.8,23.7 5.4,22.3 15.7,12 5.4,1.7 6.8,0.3 18.5,12 " />{" "}
                                  </g>{" "}
                                </g>{" "}
                              </g>
                            </svg>
                          </div>
                        </div>
                      </div>
                    )} */}
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade ${
                      activeButton === 2 && "show active"
                    }`}
                  >
                    <div className="card">
                      <div className="card-header">
                        <div className="card-title">
                          <h2> {t("users_list")}</h2>
                        </div>
                        <div className="card-toolbar m-0"></div>
                      </div>
                      <DataTableUsers
                        users={filteredUsers}
                        changeData={changeData}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-stack flex-wrap">
                  {filteredUsers.length !== 0 && pagination.total > 6 && (
                    <div className="numbers-dt-pagination">
                      <div className="rows-per-page">
                        {t("rows_per_page")}{" "}
                        <select
                          className="rows-per-page-select"
                          onChange={(e) =>
                            setPagination((prevData) => ({
                              ...prevData,
                              from: 1,
                              limit: parseInt(e.target.value),
                              to: parseInt(e.target.value),
                            }))
                          }
                        >
                          <option value="6">6</option>
                          <option value="9">9</option>
                          <option value="12">12</option>
                        </select>
                      </div>
                      <span className="rows-per-page">
                        {pagination.from}-
                        {pagination.to < allFilteredUsers
                          ? pagination.to
                          : allFilteredUsers}{" "}
                        {t("of")} {allFilteredUsers}
                      </span>
                      <div className="dt-filter-buttons">
                        <div
                          className="datatable-button"
                          onClick={handlePrevioustPage}
                        >
                          <svg
                            className="svg-filters"
                            fill={`${
                              pagination.from != 0 && pagination.from != 1
                                ? "#000000"
                                : "#aaaaaa"
                            }`}
                            height="200px"
                            width="200px"
                            version="1.1"
                            id="XMLID_54_"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 24 24"
                            xmlSpace="preserve"
                          >
                            <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                            <g
                              id="SVGRepo_tracerCarrier"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <g id="SVGRepo_iconCarrier">
                              {" "}
                              <g id="previous">
                                {" "}
                                <g>
                                  {" "}
                                  <polygon points="17.2,23.7 5.4,12 17.2,0.3 18.5,1.7 8.4,12 18.5,22.3 " />{" "}
                                </g>{" "}
                              </g>{" "}
                            </g>
                          </svg>
                        </div>
                        <div
                          className="datatable-button"
                          onClick={handleNextPage}
                        >
                          <svg
                            className="svg-filters"
                            fill={
                              pagination.to < pagination.total
                                ? "#000000"
                                : "#aaaaaa"
                            }
                            height="200px"
                            width="200px"
                            version="1.1"
                            id="XMLID_287_"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 24 24"
                            xmlSpace="preserve"
                          >
                            <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                            <g
                              id="SVGRepo_tracerCarrier"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <g id="SVGRepo_iconCarrier">
                              {" "}
                              <g id="next">
                                {" "}
                                <g>
                                  {" "}
                                  <polygon points="6.8,23.7 5.4,22.3 15.7,12 5.4,1.7 6.8,0.3 18.5,12 " />{" "}
                                </g>{" "}
                              </g>{" "}
                            </g>
                          </svg>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : usersExists === 2 ? (
        <EmptyUsers />
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default ListUsers;
