import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const NumberSummary = ({
  reqBody,
  createNumber,
  numberType,
  features,
  addons,
  prices,
  selectedScopeCountries,
  setSpinnerLoad,
  changeResourceId,
  openModal
}) => {
  const { t } = useTranslation();


  const [scrollPage, setScrollPage] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPage(true);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScrollTop = () => {
      if (window.scrollY === 0) {
        setScrollPage(false);
      }
    };
    window.addEventListener("scroll", handleScrollTop);
    return () => {
      window.removeEventListener("scroll", handleScrollTop);
    };
  }, []);

  const proceedToPayment = () => {
    openModal()
    createNumber()
  };

  return (
    <div
      className="flex-column flex-lg-row-auto w-100 w-lg-250px w-xl-300px mb-10 order-1 order-lg-2"
      id="summary_id"
    >
      <div
        className={`card card-flush pt-3 mb-0 ${scrollPage && "fixed-summary"}`}
        data-kt-sticky="true"
        data-kt-sticky-name="subscription-summary"
        data-kt-sticky-offset="{default: false, lg: '200px'}"
        data-kt-sticky-width="{lg: '250px', xl: '300px'}"
        data-kt-sticky-left="auto"
        data-kt-sticky-top="150px"
        data-kt-sticky-animation="false"
        data-kt-sticky-zindex={95}
        style={{}}
      >
        <div className="card-header">
          <div className="card-title">
            <h2>{t("summary")}</h2>
          </div>
        </div>
        <div className="separator separator-dashed mb-7" />

        {reqBody.country ? (
          <div className="card-body pt-0 fs-6">
            <h5 className="mb-3">{t("order_details")}</h5>
            <div className="flex-column d-flex gap-3">
              <div className="fw-semibold text-gray-600 d-flex align-items-md-center">
                <span className="fs-8 min-w-xl-60px">{t("quantity")}:</span>

                <span>{reqBody.quantity}</span>
              </div>

              {numberType && (
                <div className="fw-semibold text-gray-600 d-flex align-items-md-center">
                  <span className="fs-8 min-w-xl-60px">{t("type")}:</span>
                  <span className="badge badge-neutral me-2">
                    {t(`services.${numberType}`)}
                  </span>
                </div>
              )}

              {reqBody.country && (
                <div className="fw-semibold text-gray-600 d-flex align-items-md-center">
                  <span className="fs-8 min-w-xl-60px">{t("country")}:</span>
                  <div className="w-100 d-flex align-items-md-center gap-1">
                    <img
                      className="flag-dim"
                      src={`/assets/media/flags/${reqBody.country.toLowerCase()}.svg`}
                      alt={reqBody.country}
                    />
                    <span>{t(`countries.${reqBody.country}`)}</span>
                  </div>{" "}
                </div>
              )}

              {reqBody.features.length > 0 && (
                <div className="fw-semibold text-gray-600 d-flex align-items-start">
                  <span className="fs-8 min-w-xl-60px">{t("features")}:</span>
                  <div className="w-100 d-flex align-items-md-center gap-1 flex-wrap">
                    {reqBody.features.map((item, key) => (
                      <span key={key} className="badge badge-neutral me-2">
                        {features
                          .find((feature) => feature.service_id === item)
                          .tagcode.includes("callin")
                          ? t("callin")
                          : features
                              .find((feature) => feature.service_id === item)
                              .tagcode.includes("callout")
                          ? t("callout")
                          : features
                              .find((feature) => feature.service_id === item)
                              .tagcode.includes("smsin")
                          ? t("smsin")
                          : features
                              .find((feature) => feature.service_id === item)
                              .tagcode.includes("smsout")
                          ? t("smsout")
                          : ""}
                      </span>
                    ))}
                  </div>{" "}
                </div>
              )}

              {addons.length > 0 && (
                <div className="fw-semibold text-gray-600 d-flex align-items-start">
                  <span className="fs-8 min-w-xl-60px">{t("addons")}:</span>
                  <div className="w-100 d-flex align-items-md-center gap-1 flex-wrap">
                    {addons.map((item, key) => (
                      <span key={key} className="badge badge-neutral me-2">
                        {t(item)}
                      </span>
                    ))}
                  </div>{" "}
                </div>
              )}
            </div>

            {reqBody &&
              reqBody.outbound_scope &&
              reqBody.outbound_scope.length > 0 && (
                <div>
                  <div className="separator separator-dashed mb-7 mt-7" />{" "}
                  <h5 className="mb-3">{t("usage")}</h5>
                  <div className="flex-column d-flex gap-3">
                    <div className="fw-semibold text-gray-600 d-flex gap-1 flex-column align-items-start ">
                      <span className="fs-8 min-w-xl-80px">
                        {t("outbound_calling_scope")}:
                      </span>
                      <div className="flex-wrap d-flex gap-1">
                        {reqBody.outbound_scope.length > 0 &&
                          selectedScopeCountries.map((countryScope) => (
                            <div
                              className="d-flex align-items-md-center gap-1 country-container"
                              key={countryScope.iso_code}
                            >
                              <img
                                className="outbound-flag"
                                src={`/assets/media/flags/${countryScope.iso_code.toLowerCase()}.svg`}
                                alt={reqBody.country}
                              />
                              <span className="fs-8">
                                {t(`countries.${countryScope.iso_code}`)}
                              </span>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}

            {numberType && (
              <div>
                <div className="separator separator-dashed mb-7 mt-7" />
                <h5 className="mb-3">{t("tariff")}</h5>
                <div className="flex-column d-flex gap-3">
                  <div className="fw-semibold text-gray-600 d-flex align-items-md-center">
                    <span className="fs-8 min-w-xl-80px me-1">
                      {t("setup_price")}:
                    </span>

                    {prices.setup.price == null && prices.fees.price == null ? (
                      <span className="badge badge-light-danger">
                        {t("currently_unavailable")}
                      </span>
                    ) : prices.setup.price == 0 ||
                      (prices.setup.price == null && prices.fees.price > 0) ? (
                      <span className="free-price">{t("free")}</span>
                    ) : (
                      <div>
                        <span
                          className={`badge pointer-event-none ${
                            prices.setup.discount > 0
                              ? "badge-light-danger old-price"
                              : "badge-light"
                          }`}
                        >
                          {parseFloat(prices.setup.price).toFixed(2)}$
                        </span>

                        {prices.setup.discount > 0 && (
                          <span
                            className={`badge pointer-event-none badge-light`}
                          >
                            {parseFloat(
                              prices.setup.price - prices.setup.discount
                            ).toFixed(2)}
                            $
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="fw-semibold text-gray-600 d-flex align-items-md-center">
                    <span className="fs-8 min-w-xl-80px me-1">
                      {t("monthly_fee")}:
                    </span>

                    {prices.setup.price == null && prices.fees.price == null ? (
                      <span className="badge badge-light-danger">
                        {t("currently_unavailable")}
                      </span>
                    ) : prices.fees.price == 0 ||
                      (prices.fees.price == null && prices.setup.price > 0) ? (
                      <span className="free-price">{t("free")}</span>
                    ) : (
                      <div>
                        <span
                          className={`badge pointer-event-none ${
                            prices.fees.discount > 0
                              ? "badge-light-danger old-price"
                              : "badge-light"
                          }`}
                        >
                          {parseFloat(prices.fees.price).toFixed(2)}$
                        </span>

                        {prices.fees.discount > 0 && (
                          <span
                            className={`badge pointer-event-none badge-light`}
                          >
                            {parseFloat(
                              prices.fees.price - prices.fees.discount
                            ).toFixed(2)}
                            $
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            <div className="width-100 d-flex flex-center">
              {" "}
              {reqBody.number_type_id && (
                <span
                  className={`btn btn-sm btn-primary-active mt-9 ${
                    ((prices.setup.price == null &&
                      prices.fees.price == null) ||
                      reqBody.features.length === 0) &&
                    "display-none"
                  }`}
                  onClick={() => proceedToPayment()}
                >
                  {t("buy")}
                </span>
              )}
            </div>
          </div>
        ) : (
          <div className="card-body pt-0 fs-6 d-flex align-items-center justify-content-center flex-column gap-1">
            <svg
              height={80}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M22 8.16162L20.8676 15.3333C20.6618 16.7475 19.5294 17.6566 18.0882 17.6566H8.20588C6.86765 17.6566 5.63235 16.6465 5.42647 15.3333L4.19118 6.94949L3.88235 4.62626C3.77941 4.0202 3.26471 3.61616 2.54412 3.61616H1.82353C1.41176 3.61616 1 3.21212 1 2.80808C1 2.40404 1.41176 2 1.82353 2H2.54412C3.98529 2 5.22059 3.0101 5.32353 4.42424L5.52941 6.0404H20.1471C20.6618 6.0404 21.1765 6.24242 21.4853 6.64647C21.8971 7.05051 22 7.65657 22 8.16162Z"
                  fill="#e4e6ef"
                ></path>{" "}
                <path
                  d="M18.2941 22C18.8626 22 19.3235 21.5478 19.3235 20.9899C19.3235 20.432 18.8626 19.9798 18.2941 19.9798C17.7256 19.9798 17.2647 20.432 17.2647 20.9899C17.2647 21.5478 17.7256 22 18.2941 22Z"
                  fill="#e4e6ef"
                ></path>{" "}
                <path
                  d="M8 22C8.56853 22 9.02941 21.5478 9.02941 20.9899C9.02941 20.432 8.56853 19.9798 8 19.9798C7.43147 19.9798 6.97059 20.432 6.97059 20.9899C6.97059 21.5478 7.43147 22 8 22Z"
                  fill="#e4e6ef"
                ></path>{" "}
              </g>
            </svg>
            <span className="fs-7 fw-semibold text-gray-300 ">
              {t("empty")}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default NumberSummary;
