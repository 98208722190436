import React from "react";
import Flatpickr from "react-flatpickr";

function DataTableDateFilter({
  onChange,
  placeholder,
  isRange = false,
  onClearClick,
  date,
}) {
  const isDateInvalid = !date || (Array.isArray(date) && date.some((d) => !d));

  return (
    <div className="w-16em height-27 flex-inline">
      <Flatpickr
        className="search-input-dt search-input-dt-date "
        options={{
          maxDate: new Date(),
          closeOnSelect: true,
          mode: isRange ? "range" : "single",
        }}
        placeholder={placeholder}
        onChange={onChange}
        value={date}
      />
      {!isDateInvalid && (
        <span onClick={onClearClick} className="cancel-x">
          x 
        </span>
      )}
    </div>
  );

  
}

export default DataTableDateFilter;
