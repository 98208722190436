import React from "react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const EmptyUsers = () => {
  const { t } = useTranslation();
  return (
    <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
      <div className="app-container container-fluid d-flex flex-row flex-column-fluid">
        <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
          <div className="d-flex flex-column flex-column-fluid">
            <div id="kt_app_content" className="app-content flex-column-fluid">
              <div className="card">
                <div className="card-body p-0">
                  <div className="card-px text-center py-20 my-10">
                    <img src="assets/img/users.svg" width={400} />
                    <h2 className="fs-2x fw-bold mb-10">
                      {" "}
                      {t("empty_users_title")}
                    </h2>
                    <p className="text-gray-400 fs-4 fw-semibold mb-10">
                      {t("empty_users_desc1")}
                      <br />
                      {t("empty_users_desc2")}
                    </p>

                    <Link to={`${process.env.REACT_APP_BASENAME}numbers_buy`} className="btn btn-primary-active">
                      {t("purchase")}
                    
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyUsers;
