import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Flatpickr from "react-flatpickr";

function ExportDateTimePicker({
  dateStartChange,
  dateEndChange,
  isOpen,
  setIsOpen,
  dateStart,
  dateEnd,
}) {
  const { t } = useTranslation();
  const handleOpen = () => {
    setIsOpen(true);
  };

  console.log(
    !dateStart.includes(" ") ? new Date(`${dateStart} 00:00`) : dateStart
  );

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div className="dateTimePickerFlex container-flat">
      <Flatpickr
      className="export-flatpicker"
        options={{
          enableTime: true,
          dateFormat: "d/m/Y H:i",
          locale: "default",
          onClose: handleClose,
          onOpen: handleOpen,
        }}
        placeholder={t("start")}
        onChange={dateStartChange}
        value={
          dateStart && dateStart.includes(" ")
            ? new Date(dateStart)
            : new Date(`${dateStart} 00:00`)
        }
      />

      <Flatpickr
      className="export-flatpicker"
        options={{
          enableTime: true,
          dateFormat: "d/m/Y H:i",
          locale: "default",
          onClose: handleClose,
          onOpen: handleOpen,
        }}
        placeholder={t("end")}
        onChange={dateEndChange}
        value={
          dateEnd && dateEnd.includes(" ")
            ? new Date(dateEnd)
            : new Date(`${dateEnd} 23:59`)
        }
      />
    </div>
  );
}

export default ExportDateTimePicker;
