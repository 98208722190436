import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import getNumbers from "../../Services/getNumbers";
import ErrorExpiredSession from "../../../../components/Alerts/errorExpiredSession";
import ErrorSwal from "../../../../components/Alerts/errorAlert";
import unallocateNumbers from "../../Services/unallocateNumbers";
import SpinnerDiv from "../../../../Layouts/SpinnerDiv";
import { Link } from "react-router-dom";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import { confirmationSwal } from "../../../../components/Alerts/confirmationAlert";
import { successSwall } from "../../../../components/Alerts/successAlert";
import InternalErrorSwal from "../../../../components/Alerts/internalErrorAlert";

const UnallocateNumbers = ({
  closeModal,
  changeData,
  extension_id,
  user,
  user_id,
  allocated_numbers,
}) => {

  const { t } = useTranslation();

  const [reqBody, setReqBody] = useState({
    extension_id: extension_id,
    numbers: [],
  });

  const [menuOpen, setMenuOpen] = useState(false);

  const [numbers, setNumbers] = useState({ data: [], display: 0 });

  const [loading, setLoading] = useState(false);

  const fetchNumbers = async () => {
    try {
      setLoading(true);
      const response = await getNumbers({ status: 1 });
      setLoading(false);
      if (!response.ok) {
        throw new Error(response.status);
      }
      const result = await response.json();
      if (result.total > 0) {
        result.data.forEach(
          (element) =>
            allocated_numbers.includes(element.id) &&
            numbers.data.push({
              id: element.id,
              number: element.number,
              country: element.country,
            })
        );
        setNumbers((prevData) => ({ ...prevData, display: 1 }));
      } else {
        setNumbers((prevData) => ({ ...prevData, display: 0 }));
      }
    } catch (error) {
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else {
        refetchNumbers();
      }
    }
  };

  const refetchNumbers = async () => {
    try {
      setLoading(true);
      const response = await getNumbers({ status: 1 });
      setLoading(false);

      if (!response.ok) {
        throw new Error(response.status);
      }

      const result = await response.json();

      if (result.total > 0) {
        result.data.forEach((element) =>
          numbers.data.push({ id: element.id, number: element.number })
        );
        setNumbers((prevData) => ({ ...prevData, display: 1 }));
      } else {
        setNumbers((prevData) => ({ ...prevData, display: 0 }));
      }
    } catch (error) {
      InternalErrorSwal();
    }
  };

  const submitNumbersAllocation = async () => {
    try {
      setLoading(true);
      const response = await unallocateNumbers(reqBody);
      setLoading(false);
      if (!response.ok) {
        throw new Error(response.status);
      }

      successSwall(t("numbers_allocation_succeeded"));
      closeModal();
      let newData = [];
      numbers.data.forEach((element) => {
        !reqBody.numbers.includes(element.id) && newData.push(element);
      });

      changeData("numbers", user_id, newData);
    } catch (error) {
      if (error.message == 401) {
        ErrorExpiredSession(t("session_expired"), t("errors.401"), t("login"));
      } else if (error.message == 403) {
        ErrorSwal(t("errors.403"));
      } else if (error.message == 404) {
        ErrorSwal(t("errors.404"));
      } else if (error.message == 405) {
        ErrorSwal(t("errors.405"));
      } else if (error.message == 406) {
        ErrorSwal(t("errors.406"));
      } else if (error.message == 422) {
        ErrorSwal(t("errors.422"));
      } else if (error.message == 423) {
        ErrorSwal(t("errors.423"));
      } else {
        InternalErrorSwal();
      }
    }
  };

  useEffect(() => {
    fetchNumbers();
  }, []);

  const handleSelectNumber = (item) => {
    if (!reqBody.numbers.find((user) => user === item)) {
      setReqBody((prevData) => ({
        ...prevData,
        numbers: [item],
      }));
    } else {
      setReqBody((prevData) => ({
        ...prevData,
        numbers: prevData.numbers.filter((user) => user !== item),
      }));
    }
  };

  return (
    <div>
      <div className={`modal-container allocate-user-modal fade-in-down`}>

        {loading && <SpinnerDiv />}

        <div className={`modal-show ${loading && "disabled-event"}`}>
          
          <div className="modal-header p-6">
            <div className="d-flex flex-column gap-4">
              <h3>{t("unallocate_phone_numbers")}</h3>
              <div className="d-flex flex-wrap fw-semibold fs-6 gap-2">
                <div className="d-flex align-items-center text-gray-900 header-badge">
                  <span className="svg-icon svg-icon-4 me-1">
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 21C6 21.6 6.4 22 7 22H17C17.6 22 18 21.6 18 21V20H6V21Z"
                        fill="currentColor"
                      />
                      <path
                        opacity="0.3"
                        d="M17 2H7C6.4 2 6 2.4 6 3V20H18V3C18 2.4 17.6 2 17 2Z"
                        fill="currentColor"
                      />
                      <path
                        d="M12 4C11.4 4 11 3.6 11 3V2H13V3C13 3.6 12.6 4 12 4Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  {user}
                </div>

                <div className="d-flex align-items-center text-gray-900 header-badge">
                  <span className="svg-icon svg-icon-4 me-1">
                    <svg
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12 21C16.9706 21 21 16.9706 21 12C21 10.1666 20.4518 8.46124 19.5103 7.03891L12.355 14.9893C11.6624 15.7589 10.4968 15.8726 9.66844 15.2513L6.4 12.8C5.95817 12.4686 5.86863 11.8418 6.2 11.4C6.53137 10.9582 7.15817 10.8686 7.6 11.2L10.8684 13.6513L18.214 5.48955C16.5986 3.94717 14.4099 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                          fill="#3fa21a"
                        ></path>{" "}
                      </g>
                    </svg>
                  </span>
                  {t("allocated")}
                </div>
              </div>
            </div>
            <div
              className="btn btn-sm btn-icon btn-active-color-primary justify-content-end"
              onClick={() => closeModal()}
            >
              <span className="svg-icon svg-icon-1">
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.5"
                    x={6}
                    y="17.3137"
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  />
                  <rect
                    x="7.41422"
                    y={6}
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div className="modal-body p-6">
            <div>
              <div className="pb-5 pb-lg-3">
                <label
                  className="form-label mb-2"
                  htmlFor="allocate-number-form-users"
                >
                  <span>{t("numbers")}</span>
                </label>
              </div>
              {numbers.display === 2 ? (
                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">
                  <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="0.3"
                        x={2}
                        y={2}
                        width={20}
                        height={20}
                        rx={10}
                        fill="currentColor"
                      />
                      <rect
                        x={11}
                        y={14}
                        width={7}
                        height={2}
                        rx={1}
                        transform="rotate(-90 11 14)"
                        fill="currentColor"
                      />
                      <rect
                        x={11}
                        y={17}
                        width={2}
                        height={2}
                        rx={1}
                        transform="rotate(-90 11 17)"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  <div className="d-flex flex-stack flex-grow-1">
                    <div className="fw-semibold">
                      <h4 className="text-gray-900 fw-bold">
                        {t("there_is_no_users_yet")}.
                      </h4>
                      <div className="fs-6 text-gray-700">
                        {t("users_list_is_empty")}, {t("create_user_from_here")}{" "}
                        <Link to={`${process.env.REACT_APP_BASENAME}numbers_buy`} className="fw-bold ">
                          {t("link")}.
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ) : numbers.display === 1 ? (
                numbers.data.length > 0 ? (
                  <div className="fv-row h-44px">
                    <div
                      className="form-select form-select-transparent cursor-pointer position-relative users-inp selected-numbers h-100"
                      onClick={() => setMenuOpen(!menuOpen)}
                      onMouseLeave={() => setMenuOpen(false)}
                    >
                      {reqBody.numbers.map((item) => {
                        return numbers.data
                          .filter((element) => element.id === item)
                          .map((element) => (
                            <span key={element.id}>
                              <span>
                                {
                                  numbers.data.find(
                                    (number) => number.id === element.id
                                  ).number
                                }
                              </span>
                            </span>
                          ));
                      })}

                      <div
                        className={`menu-flag-drop-down left-0 top-35 ${
                          !menuOpen && "display-none"
                        }`}
                      >
                        {numbers.data.map((item) => (
                          <div
                            onClick={() => handleSelectNumber(item.id)}
                            className={`${
                              reqBody.numbers.find(
                                (user) => user === item.id
                              ) && "number-selected"
                            }`}
                          >
                            <div>{item.number}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="d-flex justify-content-end p-6 footer-border-top">
            <SecondaryButton title={t("cancel")} onClick={() => closeModal()} />

            <div
              onClick={() => confirmationSwal(submitNumbersAllocation)}
              className="btn btn-primary-active btn-sm"
            >
              <span> {t("submit")}</span>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal-hide-div"
        // onClick={() => {
        //   closeModal();
        // }}
      ></div>
    </div>
  );
};

export default UnallocateNumbers;
