import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const OutboundCallingScope = ({
  changeReqBody,
  scope,
  scopeCountries,
  updateScopeCountries,
}) => {
  const { t } = useTranslation();

  const [continentsOpen, setContinentsOpen] = useState({
    europe: true,
    oceane: true,
    america: true,
    africa: true,
  });

  const handleContinentDisplay = (continent) => {
    setContinentsOpen((prevData) => ({
      ...prevData,
      [continent]: !prevData[continent],
    }));
  };

  const [draggedItem, setDraggedItem] = useState(null);

  const [countries, setCountries] = useState([]);

  useEffect(() => {
    setCountries(scopeCountries);
  }, [scopeCountries]);

  const [availableCountries, setAvailableCountries] = useState([]);

  useEffect(() => {
    updateScopeCountries(availableCountries);
  }, [availableCountries]);

  const unselectCountry = (event) => {
    const [id, code, continent] = event.currentTarget.id.split(":");
    setCountries([
      ...countries,
      { id: JSON.parse(id), iso_code: code, continent: continent },
    ]);
    setAvailableCountries(
      availableCountries.filter((countries) => countries.iso_code !== code)
    );
  };

  const handleDragStart = (index) => {
    setDraggedItem(index);
  };
  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleDrop = () => {
    if (draggedItem === null) return;
    const newCountries = [...countries];
    const draggedItemContent = newCountries[draggedItem];
    newCountries.splice(draggedItem, 1);
    setCountries(newCountries);
    setAvailableCountries((prevAvailableCountries) => [
      ...prevAvailableCountries,
      draggedItemContent,
    ]);
    setDraggedItem(null);
  };

  useEffect(() => {
    changeReqBody(
      "outbound_scope",
      availableCountries.map((country) => country.id).flat()
    );
  }, [availableCountries]);

  useEffect(() => {
    if (!scope) {
      setCountries(scopeCountries);
      setAvailableCountries([]);
    }
  }, [scope]);

  return (
    <div>
      {scope && (
        <div className="card card-flush pt-3 mb-5 mb-lg-10">
          <div className="card-header">
            <div className="card-title">
              <h2 className="fw-bold">{t("outbound_scope")}</h2>
            </div>
          </div>
          <div className="card-body pt-0">
            <div className="mb-5 d-flex">
              {" "}
              <div className="fw-bold warning-drag-and-drop p-2">
                <svg
                  height={16}
                  version="1.1"
                  id="_x32_"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 512 512"
                  xmlSpace="preserve"
                  fill="#000000"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <style
                      type="text/css"
                      dangerouslySetInnerHTML={{
                        __html: " .st0{fill:#9d9d9d;} ",
                      }}
                    />{" "}
                    <g>
                      {" "}
                      <path
                        className="st0"
                        d="M479.548,265.753c-2.408-6.276-2.408-13.224,0-19.522l19.468-51.108c4.6-12.065,0.162-25.666-10.63-32.714 l-45.797-29.904c-5.635-3.682-9.719-9.317-11.471-15.801l-14.296-52.799c-3.366-12.443-14.96-20.865-27.828-20.224l-54.636,2.732 c-6.715,0.324-13.324-1.814-18.564-6.036L273.2,6.073c-10.035-8.097-24.361-8.097-34.404,0l-42.594,34.304 c-5.241,4.222-11.848,6.36-18.572,6.036l-54.629-2.732c-12.868-0.64-24.462,7.781-27.827,20.224l-14.288,52.799 c-1.76,6.484-5.843,12.119-11.478,15.801L23.61,162.408c-10.791,7.048-15.222,20.664-10.629,32.714l19.476,51.116 c2.401,6.291,2.401,13.238,0,19.521l-19.476,51.116c-4.593,12.042-0.162,25.666,10.629,32.714l45.798,29.912 c5.635,3.666,9.718,9.286,11.478,15.785l14.288,52.799c3.366,12.451,14.96,20.865,27.827,20.224l54.629-2.717 c6.724-0.348,13.331,1.806,18.572,6.021l42.594,34.319c10.043,8.09,24.369,8.09,34.396,0l42.602-34.319 c5.234-4.214,11.849-6.368,18.564-6.021l54.636,2.717c12.868,0.641,24.462-7.773,27.828-20.224l14.296-52.799 c1.752-6.499,5.836-12.119,11.471-15.785l45.797-29.912c10.792-7.048,15.23-20.672,10.622-32.714L479.548,265.753z M288.778,369.127c0,10.451-8.476,18.943-18.927,18.943h-27.704c-10.452,0-18.935-8.492-18.935-18.943v-28.916 c0-10.452,8.483-18.943,18.935-18.943h27.704c10.451,0,18.927,8.491,18.927,18.943V369.127z M288.778,268.948 c0,10.452-8.476,18.928-18.927,18.928h-27.704c-10.452,0-18.935-8.476-18.935-18.928V142.879c0-10.467,8.483-18.943,18.935-18.943 h27.704c10.451,0,18.927,8.476,18.927,18.943V268.948z"
                      />{" "}
                    </g>{" "}
                  </g>
                </svg>

                <span>{t("drag_and_drop_countries")}.</span>
              </div>
            </div>
            <div className="row row-cols-lg-2 g-10">
              <div className="col">
                <div className="card card-bordered">
                  <div className="card-header min-h-50px">
                    <div className="card-title">
                      <h4 className="card-label fs-5">
                        {t("available_countries")}
                      </h4>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div
                      className={`card${
                        countries.map((item) => item.continent == "eu") == "true"
                          ? ""
                          : " d-none"
                      }`}
                    >
                      <div
                        className="card-header collapsible cursor-pointer rotate min-h-50px"
                        onClick={() => handleContinentDisplay("europe")}
                        data-bs-toggle="collapse"
                        data-bs-target="#kt_collapsible_eu"
                      >
                        <h3 className="card-title">{t("europe")}</h3>
                        <div
                          className={`card-toolbar ${
                            continentsOpen.europe && "rotate-180"
                          }`}
                        >
                          <span className="svg-icon svg-icon-1">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                opacity="0.5"
                                x={11}
                                y={18}
                                width={13}
                                height={2}
                                rx={1}
                                transform="rotate(-90 11 18)"
                                fill="currentColor"
                              />
                              <path
                                d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                      <div
                        id="kt_collapsible_eu"
                        className={`collapse ${
                          continentsOpen.europe && "show"
                        }`}
                      >
                        <div className="card-body scope-container">
                          {countries.map(
                            (item, index) =>
                              item.continent == "eu" && (
                                <div
                                  className="d-flex flex-stack drag_countries position-relative"
                                  onDragStart={() => handleDragStart(index)}
                                >
                                  <img
                                    src={`assets/media/flags/${item.iso_code.toLowerCase()}.svg`}
                                    className="ms-4 me-4 height-1-2 width-1-6 obj-fit-cover"
                                    style={{ borderRadius: 4 }}
                                    alt=""
                                  />
                                  <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                                    <div className="me-5">
                                      <a
                                        href="#"
                                        className="text-gray-800 fw-bold text-hover-primary fs-6"
                                      >
                                        {t(`countries.${item.iso_code}`)}
                                      </a>
                                    </div>

                                    {/* <div className="badge-feature">
                                      {selectedFeatures &&
                                        selectedFeatures.includes(
                                          features[
                                            features.findIndex(
                                              (item) =>
                                                item.tagcode === (numberType === "phonelocal"
                                              ? "local"
                                              : numberType === "phonemobile"
                                              ? "mobile"
                                              : numberType === "phonenational"
                                              ? "national"
                                              : ""
                                              )+"callout"
                                              
                                            )
                                          ].service_id
                                        ) &&
                                        (data &&
                                        data[selectedCountry].number_types[
                                          numberType
                                        ].features[
                                          `${
                                            numberType === "phonelocal"
                                              ? "local"
                                              : numberType === "phonemobile"
                                              ? "mobile"
                                              : numberType === "phonenational"
                                              ? "national"
                                              : ""
                                          }callout`
                                        ] &&
                                        data[selectedCountry].number_types[
                                          numberType
                                        ].features[
                                          `${
                                            numberType === "phonelocal"
                                              ? "local"
                                              : numberType === "phonemobile"
                                              ? "mobile"
                                              : numberType === "phonenational"
                                              ? "national"
                                              : ""
                                          }callout`
                                        ].scope.find(
                                          (element) =>
                                            element.iso_code === item.iso_code
                                        ) ? (
                                          <span className="badge badge-light-success me-2 ">
                                            {t("call_out")}
                                          </span>
                                        ) : data[selectedCountry].number_types[
                                            numberType
                                          ].features["localcallout"].scope.find(
                                            (element) =>
                                              element.iso_code === item.iso_code
                                          ) ? (
                                          <span className="badge badge-light-info me-2 ">
                                            {t("call_out")}
                                          </span>
                                        ) : (
                                          <span className="badge badge-light-danger me-2 ">
                                            {t("call_out")}
                                          </span>
                                        ))}

                                      {selectedFeatures &&
                                        selectedFeatures.includes(
                                          features[
                                            features.findIndex(
                                              (item) =>
                                                item.tagcode ===  `${
                                                  numberType === "phonelocal"
                                                    ? "local"
                                                    : numberType === "phonemobile"
                                                    ? "mobile"
                                                    : numberType === "phonenational"
                                                    ? "national"
                                                    : ""
                                                }smsout`
                                            )
                                          ].service_id
                                        ) &&
                                        (data &&
                                        data[selectedCountry].number_types[
                                          numberType
                                        ].features[
                                          `${
                                            numberType === "phonelocal"
                                              ? "local"
                                              : numberType === "phonemobile"
                                              ? "mobile"
                                              : numberType === "phonenational"
                                              ? "national"
                                              : ""
                                          }smsout`
                                        ] &&
                                        data[selectedCountry].number_types[
                                          numberType
                                        ].features[
                                          `${
                                            numberType === "phonelocal"
                                              ? "local"
                                              : numberType === "phonemobile"
                                              ? "mobile"
                                              : numberType === "phonenational"
                                              ? "national"
                                              : ""
                                          }smsout`
                                        ].scope.find(
                                          (element) =>
                                            element.iso_code === item.iso_code
                                        ) ? (
                                          <span className="badge badge-light-success me-2 ">
                                            {t("sms_out")}
                                          </span>
                                        ) : (
                                          <span className="badge badge-light-danger me-2 ">
                                            {t("sms_out")}
                                          </span>
                                        ))}

                              
                                    </div> */}

                                    <div className="d-flex align-items-center">
                                      <div className="m-0">
                                        <span className="badge fs-base">
                                          <a
                                            href="javascript:"
                                            className="btn btn-icon btn-hover-light-primary  draggable-item draggable-handle"
                                          >
                                            <span className="svg-icon svg-icon-2x svg-right">
                                              <svg
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                                  fill="currentColor"
                                                />
                                                <path
                                                  opacity="0.3"
                                                  d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                                  fill="currentColor"
                                                />
                                              </svg>
                                            </span>
                                          </a>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`card${
                        countries.map((item) => item.continent == "oc") == "true"
                          ? ""
                          : " d-none"
                      }`}
                    >
                      <div
                        className="card-header collapsible cursor-pointer rotate min-h-50px"
                        onClick={() => handleContinentDisplay("oceane")}
                        data-bs-toggle="collapse"
                        data-bs-target="#kt_collapsible_oc"
                      >
                        <h3 className="card-title">{t("ocean")}</h3>
                        <div
                          className={`card-toolbar ${
                            continentsOpen.oceane && "rotate-180"
                          }`}
                        >
                          <span className="svg-icon svg-icon-1">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                opacity="0.5"
                                x={11}
                                y={18}
                                width={13}
                                height={2}
                                rx={1}
                                transform="rotate(-90 11 18)"
                                fill="currentColor"
                              />
                              <path
                                d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </div>
                      </div>

                      <div
                        id="kt_collapsible_oc"
                        className={`collapse ${
                          continentsOpen.oceane && "show"
                        }`}
                      >
                        <div className="card-body scope-container">
                          {countries.map(
                            (item, index) =>
                              item.continent == "oc" && (
                                <div
                                  className="d-flex flex-stack drag_countries position-relative"
                                  onDragStart={() => handleDragStart(index)}
                                >
                                  <img
                                    src={`assets/media/flags/${item.iso_code.toLowerCase()}.svg`}
                                    className="ms-4 me-4 height-1-2 width-1-6 obj-fit-cover"
                                    style={{ borderRadius: 4 }}
                                    alt=""
                                  />
                                  <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                                    <div className="me-5">
                                      <a
                                        href="#"
                                        className="text-gray-800 fw-bold text-hover-primary fs-6"
                                      >
                                        {t(`countries.${item.iso_code}`)}
                                      </a>
                                    </div>
                                    {/* <div className="badge-feature">
                                      {selectedFeatures &&
                                        selectedFeatures.includes(
                                          features[
                                            features.findIndex(
                                              (item) =>
                                                item.tagcode === (numberType === "phonelocal"
                                              ? "local"
                                              : numberType === "phonemobile"
                                              ? "mobile"
                                              : numberType === "phonenational"
                                              ? "national"
                                              : ""
                                              )+"callout"
                                              
                                            )
                                          ].service_id
                                        ) &&
                                        (data &&
                                        data[selectedCountry].number_types[
                                          numberType
                                        ].features[
                                          `${
                                            numberType === "phonelocal"
                                              ? "local"
                                              : numberType === "phonemobile"
                                              ? "mobile"
                                              : numberType === "phonenational"
                                              ? "national"
                                              : ""
                                          }callout`
                                        ] &&
                                        data[selectedCountry].number_types[
                                          numberType
                                        ].features[
                                          `${
                                            numberType === "phonelocal"
                                              ? "local"
                                              : numberType === "phonemobile"
                                              ? "mobile"
                                              : numberType === "phonenational"
                                              ? "national"
                                              : ""
                                          }callout`
                                        ].scope.find(
                                          (element) =>
                                            element.iso_code === item.iso_code
                                        ) ? (
                                          <span className="badge badge-light-success me-2 ">
                                            {t("call_out")}
                                          </span>
                                        ) : data[selectedCountry].number_types[
                                            numberType
                                          ].features["localcallout"].scope.find(
                                            (element) =>
                                              element.iso_code === item.iso_code
                                          ) ? (
                                          <span className="badge badge-light-info me-2 ">
                                            {t("call_out")}
                                          </span>
                                        ) : (
                                          <span className="badge badge-light-danger me-2 ">
                                            {t("call_out")}
                                          </span>
                                        ))}

                                      {selectedFeatures &&
                                        selectedFeatures.includes(
                                          features[
                                            features.findIndex(
                                              (item) =>
                                                item.tagcode ===  `${
                                                  numberType === "phonelocal"
                                                    ? "local"
                                                    : numberType === "phonemobile"
                                                    ? "mobile"
                                                    : numberType === "phonenational"
                                                    ? "national"
                                                    : ""
                                                }smsout`
                                            )
                                          ].service_id
                                        ) &&
                                        (data &&
                                        data[selectedCountry].number_types[
                                          numberType
                                        ].features[
                                          `${
                                            numberType === "phonelocal"
                                              ? "local"
                                              : numberType === "phonemobile"
                                              ? "mobile"
                                              : numberType === "phonenational"
                                              ? "national"
                                              : ""
                                          }smsout`
                                        ] &&
                                        data[selectedCountry].number_types[
                                          numberType
                                        ].features[
                                          `${
                                            numberType === "phonelocal"
                                              ? "local"
                                              : numberType === "phonemobile"
                                              ? "mobile"
                                              : numberType === "phonenational"
                                              ? "national"
                                              : ""
                                          }smsout`
                                        ].scope.find(
                                          (element) =>
                                            element.iso_code === item.iso_code
                                        ) ? (
                                          <span className="badge badge-light-success me-2 ">
                                            {t("sms_out")}
                                          </span>
                                        ) : (
                                          <span className="badge badge-light-danger me-2 ">
                                            {t("sms_out")}
                                          </span>
                                        ))}

                              
                                    </div> */}
                                    <div className="d-flex align-items-center">
                                      <div className="m-0">
                                        <span className="badge fs-base">
                                          <a
                                            href="#"
                                            className="btn btn-icon btn-hover-light-primary  draggable-item draggable-handle"
                                          >
                                            <span className="svg-icon svg-icon-2x svg-right">
                                              <svg
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                                  fill="currentColor"
                                                />
                                                <path
                                                  opacity="0.3"
                                                  d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                                  fill="currentColor"
                                                />
                                              </svg>
                                            </span>
                                          </a>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`card${
                        countries.map((item) => item.continent == "am") == "true"
                          ? ""
                          : " d-none"
                      }`}
                    >
                      <div
                        className="card-header collapsible cursor-pointer rotate min-h-50px"
                        onClick={() => handleContinentDisplay("america")}
                        data-bs-toggle="collapse"
                        data-bs-target="#kt_collapsible_am"
                      >
                        <h3 className="card-title">{t("america")}</h3>
                        <div
                          className={`card-toolbar ${
                            continentsOpen.america && "rotate-180"
                          }`}
                        >
                          <span className="svg-icon svg-icon-1">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                opacity="0.5"
                                x={11}
                                y={18}
                                width={13}
                                height={2}
                                rx={1}
                                transform="rotate(-90 11 18)"
                                fill="currentColor"
                              />
                              <path
                                d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                      <div
                        id="kt_collapsible_am"
                        className={`collapse ${
                          continentsOpen.america && "show"
                        }`}
                      >
                        <div className="card-body scope-container">
                          {countries.map(
                            (item, index) =>
                              item.continent == "am" && (
                                <div
                                  className="d-flex flex-stack drag_countries position-relative"
                                  onDragStart={() => handleDragStart(index)}
                                >
                                  <img
                                    src={`assets/media/flags/${item.iso_code.toLowerCase()}.svg`}
                                    className="ms-4 me-4 height-1-2 width-1-6 obj-fit-cover"
                                    style={{ borderRadius: 4 }}
                                    alt=""
                                  />
                                  <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                                    <div className="me-5">
                                      <a
                                        href="#"
                                        className="text-gray-800 fw-bold text-hover-primary fs-6"
                                      >
                                        {t(`countries.${item.iso_code}`)}
                                      </a>
                                    </div>
                                    {/* <div className="badge-feature">
                                      {selectedFeatures &&
                                        selectedFeatures.includes(
                                          features[
                                            features.findIndex(
                                              (item) =>
                                                item.tagcode === (numberType === "phonelocal"
                                              ? "local"
                                              : numberType === "phonemobile"
                                              ? "mobile"
                                              : numberType === "phonenational"
                                              ? "national"
                                              : ""
                                              )+"callout"
                                              
                                            )
                                          ].service_id
                                        ) &&
                                        (data &&
                                        data[selectedCountry].number_types[
                                          numberType
                                        ].features[
                                          `${
                                            numberType === "phonelocal"
                                              ? "local"
                                              : numberType === "phonemobile"
                                              ? "mobile"
                                              : numberType === "phonenational"
                                              ? "national"
                                              : ""
                                          }callout`
                                        ] &&
                                        data[selectedCountry].number_types[
                                          numberType
                                        ].features[
                                          `${
                                            numberType === "phonelocal"
                                              ? "local"
                                              : numberType === "phonemobile"
                                              ? "mobile"
                                              : numberType === "phonenational"
                                              ? "national"
                                              : ""
                                          }callout`
                                        ].scope.find(
                                          (element) =>
                                            element.iso_code === item.iso_code
                                        ) ? (
                                          <span className="badge badge-light-success me-2 ">
                                            {t("call_out")}
                                          </span>
                                        ) : data[selectedCountry].number_types[
                                            numberType
                                          ].features["localcallout"].scope.find(
                                            (element) =>
                                              element.iso_code === item.iso_code
                                          ) ? (
                                          <span className="badge badge-light-info me-2 ">
                                            {t("call_out")}
                                          </span>
                                        ) : (
                                          <span className="badge badge-light-danger me-2 ">
                                            {t("call_out")}
                                          </span>
                                        ))}

                                      {selectedFeatures &&
                                        selectedFeatures.includes(
                                          features[
                                            features.findIndex(
                                              (item) =>
                                                item.tagcode ===  `${
                                                  numberType === "phonelocal"
                                                    ? "local"
                                                    : numberType === "phonemobile"
                                                    ? "mobile"
                                                    : numberType === "phonenational"
                                                    ? "national"
                                                    : ""
                                                }smsout`
                                            )
                                          ].service_id
                                        ) &&
                                        (data &&
                                        data[selectedCountry].number_types[
                                          numberType
                                        ].features[
                                          `${
                                            numberType === "phonelocal"
                                              ? "local"
                                              : numberType === "phonemobile"
                                              ? "mobile"
                                              : numberType === "phonenational"
                                              ? "national"
                                              : ""
                                          }smsout`
                                        ] &&
                                        data[selectedCountry].number_types[
                                          numberType
                                        ].features[
                                          `${
                                            numberType === "phonelocal"
                                              ? "local"
                                              : numberType === "phonemobile"
                                              ? "mobile"
                                              : numberType === "phonenational"
                                              ? "national"
                                              : ""
                                          }smsout`
                                        ].scope.find(
                                          (element) =>
                                            element.iso_code === item.iso_code
                                        ) ? (
                                          <span className="badge badge-light-success me-2 ">
                                            {t("sms_out")}
                                          </span>
                                        ) : (
                                          <span className="badge badge-light-danger me-2 ">
                                            {t("sms_out")}
                                          </span>
                                        ))}

                              
                                    </div> */}
                                    <div className="d-flex align-items-center">
                                      <div className="m-0">
                                        <span className="badge fs-base">
                                          <a
                                            href="#"
                                            className="btn btn-icon btn-hover-light-primary  draggable-item draggable-handle"
                                          >
                                            <span className="svg-icon svg-icon-2x svg-right">
                                              <svg
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                                  fill="currentColor"
                                                />
                                                <path
                                                  opacity="0.3"
                                                  d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                                  fill="currentColor"
                                                />
                                              </svg>
                                            </span>
                                          </a>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`card${
                        countries.map((item) => item.continent == "af") == "true"
                          ? ""
                          : " d-none"
                      }`}
                    >
                      <div
                        className="card-header collapsible cursor-pointer rotate min-h-50px"
                        onClick={() => handleContinentDisplay("africa")}
                        data-bs-toggle="collapse"
                        data-bs-target="#kt_collapsible_af"
                      >
                        <h3 className="card-title">{t("africa")}</h3>
                        <div
                          className={`card-toolbar ${
                            continentsOpen.africa && "rotate-180"
                          }`}
                        >
                          <span className="svg-icon svg-icon-1">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                opacity="0.5"
                                x={11}
                                y={18}
                                width={13}
                                height={2}
                                rx={1}
                                transform="rotate(-90 11 18)"
                                fill="currentColor"
                              />
                              <path
                                d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                      <div
                        id="kt_collapsible_af"
                        className={`collapse ${
                          continentsOpen.africa && "show"
                        }`}
                      >
                        <div className="card-body scope-container">
                          {countries.map(
                            (item, index) =>
                              item.continent == "af" && (
                                <div
                                  className="d-flex flex-stack drag_countries position-relative"
                                  onDragStart={() => handleDragStart(index)}
                                >
                                  <img
                                    src={`assets/media/flags/${item.iso_code.toLowerCase()}.svg`}
                                    className="ms-4 me-4 height-1-2 width-1-6 obj-fit-cover"
                                    style={{ borderRadius: 4 }}
                                    alt=""
                                  />
                                  <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                                    <div className="me-5">
                                      <a
                                        href="javascript:"
                                        className="text-gray-800 fw-bold text-hover-primary fs-6"
                                      >
                                        {t(`countries.${item.iso_code}`)}
                                      </a>
                                    </div>
                                    {/* <div className="badge-feature">
                                      {selectedFeatures &&
                                        selectedFeatures.includes(
                                          features[
                                            features.findIndex(
                                              (item) =>
                                                item.tagcode === (numberType === "phonelocal"
                                              ? "local"
                                              : numberType === "phonemobile"
                                              ? "mobile"
                                              : numberType === "phonenational"
                                              ? "national"
                                              : ""
                                              )+"callout"
                                              
                                            )
                                          ].service_id
                                        ) &&
                                        (data &&
                                        data[selectedCountry].number_types[
                                          numberType
                                        ].features[
                                          `${
                                            numberType === "phonelocal"
                                              ? "local"
                                              : numberType === "phonemobile"
                                              ? "mobile"
                                              : numberType === "phonenational"
                                              ? "national"
                                              : ""
                                          }callout`
                                        ] &&
                                        data[selectedCountry].number_types[
                                          numberType
                                        ].features[
                                          `${
                                            numberType === "phonelocal"
                                              ? "local"
                                              : numberType === "phonemobile"
                                              ? "mobile"
                                              : numberType === "phonenational"
                                              ? "national"
                                              : ""
                                          }callout`
                                        ].scope.find(
                                          (element) =>
                                            element.iso_code === item.iso_code
                                        ) ? (
                                          <span className="badge badge-light-success me-2 ">
                                            {t("call_out")}
                                          </span>
                                        ) : data[selectedCountry].number_types[
                                            numberType
                                          ].features["localcallout"].scope.find(
                                            (element) =>
                                              element.iso_code === item.iso_code
                                          ) ? (
                                          <span className="badge badge-light-info me-2 ">
                                            {t("call_out")}
                                          </span>
                                        ) : (
                                          <span className="badge badge-light-danger me-2 ">
                                            {t("call_out")}
                                          </span>
                                        ))}

                                      {selectedFeatures &&
                                        selectedFeatures.includes(
                                          features[
                                            features.findIndex(
                                              (item) =>
                                                item.tagcode ===  `${
                                                  numberType === "phonelocal"
                                                    ? "local"
                                                    : numberType === "phonemobile"
                                                    ? "mobile"
                                                    : numberType === "phonenational"
                                                    ? "national"
                                                    : ""
                                                }smsout`
                                            )
                                          ].service_id
                                        ) &&
                                        (data &&
                                        data[selectedCountry].number_types[
                                          numberType
                                        ].features[
                                          `${
                                            numberType === "phonelocal"
                                              ? "local"
                                              : numberType === "phonemobile"
                                              ? "mobile"
                                              : numberType === "phonenational"
                                              ? "national"
                                              : ""
                                          }smsout`
                                        ] &&
                                        data[selectedCountry].number_types[
                                          numberType
                                        ].features[
                                          `${
                                            numberType === "phonelocal"
                                              ? "local"
                                              : numberType === "phonemobile"
                                              ? "mobile"
                                              : numberType === "phonenational"
                                              ? "national"
                                              : ""
                                          }smsout`
                                        ].scope.find(
                                          (element) =>
                                            element.iso_code === item.iso_code
                                        ) ? (
                                          <span className="badge badge-light-success me-2 ">
                                            {t("sms_out")}
                                          </span>
                                        ) : (
                                          <span className="badge badge-light-danger me-2 ">
                                            {t("sms_out")}
                                          </span>
                                        ))}

                              
                                    </div> */}
                                    <div className="d-flex align-items-center">
                                      <div className="m-0">
                                        <span className="badge fs-base">
                                          <a
                                            href="javascript:"
                                            className="btn btn-icon btn-hover-light-primary draggable-item draggable-handle"
                                          >
                                            <span className="svg-icon svg-icon-2x svg-right">
                                              <svg
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                                  fill="currentColor"
                                                />
                                                <path
                                                  opacity="0.3"
                                                  d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                                  fill="currentColor"
                                                />
                                              </svg>
                                            </span>
                                          </a>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card card-bordered h-100">
                  <div className="card-header min-h-50px">
                    <div className="card-title">
                      <h4 className="card-label fs-5">
                        {t("selected_coutries")}
                      </h4>
                    </div>
                  </div>
                  <div
                    className="card-body h-100"
                    onDragOver={(event) => handleDragOver(event)}
                    onDrop={() => handleDrop()}
                  >
                    {availableCountries.map((item, index) => (
                      <div
                        className="d-flex flex-stack drag_countries position-relative"
                        onDragStart={() => handleDragStart(index)}
                      >
                        <img
                          src={`assets/media/flags/${item.iso_code.toLowerCase()}.svg`}
                          className="ms-4 me-4 height-1-2 width-1-6 obj-fit-cover"
                          style={{ borderRadius: 4 }}
                          alt=""
                        />
                        <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                          <div className="me-5">
                            <a
                              href="#"
                              className="text-gray-800 fw-bold text-hover-primary fs-6"
                            >
                              {t(`countries.${item.iso_code}`)}
                            </a>
                          </div>
                          {/* <div className="badge-feature">
                                      {selectedFeatures &&
                                        selectedFeatures.includes(
                                          features[
                                            features.findIndex(
                                              (item) =>
                                                item.tagcode === (numberType === "phonelocal"
                                              ? "local"
                                              : numberType === "phonemobile"
                                              ? "mobile"
                                              : numberType === "phonenational"
                                              ? "national"
                                              : ""
                                              )+"callout"
                                              
                                            )
                                          ].service_id
                                        ) &&
                                        (data &&
                                        data[selectedCountry].number_types[
                                          numberType
                                        ].features[
                                          `${
                                            numberType === "phonelocal"
                                              ? "local"
                                              : numberType === "phonemobile"
                                              ? "mobile"
                                              : numberType === "phonenational"
                                              ? "national"
                                              : ""
                                          }callout`
                                        ] &&
                                        data[selectedCountry].number_types[
                                          numberType
                                        ].features[
                                          `${
                                            numberType === "phonelocal"
                                              ? "local"
                                              : numberType === "phonemobile"
                                              ? "mobile"
                                              : numberType === "phonenational"
                                              ? "national"
                                              : ""
                                          }callout`
                                        ].scope.find(
                                          (element) =>
                                            element.iso_code === item.iso_code
                                        ) ? (
                                          <span className="badge badge-light-success me-2 ">
                                            {t("call_out")}
                                          </span>
                                        ) : data[selectedCountry].number_types[
                                            numberType
                                          ].features["localcallout"].scope.find(
                                            (element) =>
                                              element.iso_code === item.iso_code
                                          ) ? (
                                          <span className="badge badge-light-info me-2 ">
                                            {t("call_out")}
                                          </span>
                                        ) : (
                                          <span className="badge badge-light-danger me-2 ">
                                            {t("call_out")}
                                          </span>
                                        ))}

                                      {selectedFeatures &&
                                        selectedFeatures.includes(
                                          features[
                                            features.findIndex(
                                              (item) =>
                                                item.tagcode ===  `${
                                                  numberType === "phonelocal"
                                                    ? "local"
                                                    : numberType === "phonemobile"
                                                    ? "mobile"
                                                    : numberType === "phonenational"
                                                    ? "national"
                                                    : ""
                                                }smsout`
                                            )
                                          ].service_id
                                        ) &&
                                        (data &&
                                        data[selectedCountry].number_types[
                                          numberType
                                        ].features[
                                          `${
                                            numberType === "phonelocal"
                                              ? "local"
                                              : numberType === "phonemobile"
                                              ? "mobile"
                                              : numberType === "phonenational"
                                              ? "national"
                                              : ""
                                          }smsout`
                                        ] &&
                                        data[selectedCountry].number_types[
                                          numberType
                                        ].features[
                                          `${
                                            numberType === "phonelocal"
                                              ? "local"
                                              : numberType === "phonemobile"
                                              ? "mobile"
                                              : numberType === "phonenational"
                                              ? "national"
                                              : ""
                                          }smsout`
                                        ].scope.find(
                                          (element) =>
                                            element.iso_code === item.iso_code
                                        ) ? (
                                          <span className="badge badge-light-success me-2 ">
                                            {t("sms_out")}
                                          </span>
                                        ) : (
                                          <span className="badge badge-light-danger me-2 ">
                                            {t("sms_out")}
                                          </span>
                                        ))}

                              
                                    </div> */}
                          <div className="d-flex align-items-center">
                            <div className="">
                              <div
                                className="delete_country"
                                id={`[${item.id}]:${item.iso_code}:${item.continent}`}
                                onClick={unselectCountry}
                              >
                                <svg
                                  className="delete_country_svg"
                                  fill="#ff0000"
                                  height="64px"
                                  width="64px"
                                  version="1.1"
                                  id="Capa_1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  viewBox="0 0 460.77 460.77"
                                  xmlSpace="preserve"
                                  stroke="#ff0000"
                                  strokeWidth="0.00460775"
                                >
                                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                  <g
                                    id="SVGRepo_tracerCarrier"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <g id="SVGRepo_iconCarrier">
                                    {" "}
                                    <path d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55 c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55 c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505 c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55 l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719 c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z" />{" "}
                                  </g>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="row row-cols-1 g-10 min-h-200px draggable-zone"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OutboundCallingScope;
