import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../views/Login";
import UpdatePassword from "../views/UpdatePassword";
import RecoverPassword from "../views/RecoverPassword";
import Verification from "../views/Verification";
import Register from "../views/Register";
import AccountVerification from "../views/AccountVerification";
import CompleteInscription from "../views/CompleteInscription";

const LoginRoute = () => {
  return (
    <Routes>
      <Route path={`${process.env.REACT_APP_BASENAME}login`} element={<Login />} />
      <Route path={`${process.env.REACT_APP_BASENAME}update_password`} element={<UpdatePassword />} />
      <Route path={`${process.env.REACT_APP_BASENAME}recover_password`} element={<RecoverPassword />} />
      <Route path={`${process.env.REACT_APP_BASENAME}2fa_auth`} element={<Verification />} />
      <Route path={`${process.env.REACT_APP_BASENAME}register`} element={<Register />} />
      <Route path={`${process.env.REACT_APP_BASENAME}complete_inscription/:name`} element={<CompleteInscription />} />
      <Route path={`${process.env.REACT_APP_BASENAME}verification`} element={<AccountVerification />} />
      <Route path="*" element={<Navigate to={`${process.env.REACT_APP_BASENAME}login`} />} />
    </Routes>
  );
};

export default LoginRoute;
